<template>
    <div></div>
</template>

<script>
import { toastController } from '@ionic/vue'

export default {
    name: 'ToastComponent',
    components: {},
}

const openToast = async (message, options, level) => {
    const toast = await toastController.create({
        message: message ?? '',
        header: options.title ?? '',
        position: options.position ?? 'bottom',
        duration: options.duration ? options.duration * 1000 : 2000,
        dismissOnPageChange: options.dismissOnPageChange ?? true,
        cssClass: options.customClass ?? `${level}-toast ${options.button ?? 'close'}-toast`,
        buttons: [
            {
                text: ' ',
                side: 'end',
                role: 'cancel',
                // handler: () => {
                //     console.log('Cancel clicked')
                // },
            },
        ],
    })

    return toast.present()
}

const showOnceToast = async (message, options = {}, level = 'success') => {
    const active = await toastController.getTop()
    if (active) {
        await active.dismiss()
    }
    return openToast(message, options, level)
}

const success = async (message, options) => {
    return showOnceToast(message, options, 'success')
}

const warning = async (message, options) => {
    return showOnceToast(message, options, 'warning')
}

export const Toast = {
    success,
    warning,
}
</script>
