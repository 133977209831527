import { computed, ref, reactive } from 'vue'

import useHeartrate from './heartrate'
import useSerial from './serial'
import useBluetooth from './bluetooth'

export function createSensor(options) {
    return new Sensor(options)
}

class Sensor {
    constructor(options = {}) {
        // console.log('options', options)

        this.language = options.language || 'en'

        this.triggerEvent = (...args) => {
            // onProcessLocked
            // onGetSessionsListFailed
            // onGetSessionFailed
            // onUploadFailed
            if (typeof options[args[0]] === 'function') {
                options[args[0]](...args.slice(1))
            } else {
                console.log('class Sensor error args', args)
            }
        }
    }

    install(app) {
        const { language, triggerEvent } = this

        const sensorHeartrate = useHeartrate({ triggerEvent, language })
        const sensorSerial = useSerial({ triggerEvent, language })
        const sensorBluetooth = useBluetooth({
            triggerEvent,
            language,
            heartrate: sensorHeartrate,
        })

        app.config.globalProperties.$heartrate = sensorHeartrate
        //by default sensor will be bluetooth
        const sensor = reactive({
            current: sensorBluetooth,
            bluetooth: sensorBluetooth,
            serial: sensorSerial,
        })
        app.config.globalProperties.$sensor = sensor
        app.provide('heartrate', app.config.globalProperties.$heartrate)
        app.provide('sensor', sensor)
        app.provide('setSensorDriver', (driver) => {
            if (driver === 'serial') {
                sensor.current = sensor.serial
            } else {
                sensor.current = sensor.bluetooth
            }
        })
    }
}
