import { Preferences } from '@capacitor/preferences'
import * as Sentry from '@sentry/vue'

/**
 * Memory class is used for
 * setting or getting data from
 * the phone's memory.
 */
export default class Memory {
    /**
     * Clear keys and values from storage.
     *
     */
    static async clear() {
        return await Preferences.clear()
    }
    /**
     * Return the list of known keys in storage.
     *
     */
    static async keys() {
        return (await Preferences.keys())?.keys
    }

    /**
     * Gets an item from the phone's
     * memory. This uses Capacitor's
     * Preferences API.
     */
    static async get(key, { decode = true, otherwise = undefined } = {}) {
        let value = (await Preferences.get({ key: key })).value
        if (value && value !== 'undefined' && decode) {
            try {
                value = JSON.parse(value)
            } catch (err) {
                Sentry.captureMessage('Impossible to parse JSON for ' + key)
            }
        }
        return value ?? otherwise
    }

    /**
     * Sets an item to the phone's
     * memory. This uses Capacitor's
     * Preferences API.
     */
    static async set(key, value, { encode = true } = {}) {
        if (value && value !== 'undefined' && encode) {
            try {
                value = JSON.stringify(value)
            } catch (err) {
                Sentry.captureMessage('Impossible to stringify JSON for ' + key)
            }
        }
        await Preferences.set({ key, value })
    }

    /**
     * Removes a key / value pair from the
     * phone's memory.
     */
    static remove(key) {
        return Preferences.remove({ key })
    }

    /**
     * Gets an item from the phone's
     * memory if it exists and equals
     * the given value or sets a new
     * value on the phone's memory and
     * returns the new one.
     */
    static async sear(key, value, { enAndDecode = true } = {}) {
        const found = this.get(key, { decode: enAndDecode })

        if (value && found !== value) {
            await this.set(key, value, { encode: enAndDecode })
            return value
        }

        return found
    }
}
