<template>
    <div v-if="editing" class="flex w-full space-x-1">
        <!-- Bind v-model to inputValue to track changes -->
        <Input v-bind="$attrs" v-model="inputValue" @input:leave="() => setEditing(false)" autofocus />
        <!-- <XLightIcon class="h-4 w-4 cursor-pointer" @click="editing = false" /> -->
    </div>
    <div v-else class="flex space-x-1">
        <div @dblclick="() => setEditing(true)">
            <slot />
        </div>

        <div v-if="$slots.icon">
            <slot name="icon" />
        </div>
        <div v-else-if="!iconless && editable">
            <PencilSquareIcon class="h-4 w-4 cursor-pointer text-brand-base" @click="() => setEditing(true)" />
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue'
import Input from '@/components/form/Input.vue'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
// import XLightIcon from '@/components/icons/XLightIcon.vue'

const props = defineProps({
    edit: { type: Boolean, default: false },
    iconless: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    modelValue: { type: String, default: '' }, // Assuming v-model is a String
})

const emit = defineEmits(['input:leave', 'input:start'])
const editing = ref(false)
const inputValue = ref(props.modelValue) // Tracks the current input value
const initialInputValue = ref(props.modelValue) // Store the initial value for comparison

// Function to handle switching between editing modes
const setEditing = async (isEditing) => {
    if (isEditing) {
        inputValue.value = props.modelValue
        initialInputValue.value = props.modelValue
        emit('input:start')
        await nextTick() // Ensure DOM is updated before focusing
    }

    // When editing ends
    if (!isEditing && editing.value) {
        const valueChanged = inputValue.value !== initialInputValue.value // Compare new value with initial
        console.log('aaa', inputValue.value, initialInputValue.value, valueChanged)
        emit('input:leave', { valueChanged }) // Emit input:leave with valueChanged boolean
    }

    editing.value = isEditing
}

onMounted(() => {
    setEditing(props.edit)
})
</script>

<script>
// Using normal <script> to declare options
export default {
    inheritAttrs: false,
}
</script>
