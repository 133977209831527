<template>
    <!-- <div class="fixed inset-0 flex items-center justify-center">
        <button
            type="button"
            @click="openModal"
            class="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
            Open dialog
        </button>
    </div> -->
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" :initialFocus="buttonRef" @close="closeModal" class="relative z-30">
            <!-- The backdrop, rendered as a fixed sibling to the panel container -->
            <div class="fixed inset-0 bg-black/80" aria-hidden="true" />
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-md transform overflow-hidden rounded-xl bg-dark-light p-6 text-left align-middle shadow-xl transition-all border border-brand-base"
                        >
                            <div class="absolute -top-3 -right-3 pt-4 pr-4 block z-50">
                                <button
                                    ref="buttonRef"
                                    type="button"
                                    class="rounded-full border border-dark-lightest hover:border-dark-lighter bg-dark-light text-dark-lightest hover:text-dark-lighter focus:outline-none"
                                    @click="() => setOpen(false)"
                                >
                                    <span class="sr-only">{{ $t('nav.close') }}</span>
                                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <slot />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update:isOpen'])
const buttonRef = ref(null)

const setOpen = (state) => {
    // do nothing if already open or close
    if (state === props.isOpen) return
    emit('update:isOpen', state)
}

onBeforeUnmount(() => {
    setOpen(false)
})

function closeModal() {
    setOpen(false)
}
function openModal() {
    setOpen(true)
}
</script>
