/**
 * Limits the chars of the given string and
 * appends "..." at the end if that was needed.
 */
export const limit = (str, length) => {
    if (!str) return ''
    if (str.length <= length) return str
    return `${str.substr(0, length)}...`
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
