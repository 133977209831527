<template>
    <div class="shadow-sm grid grid-cols-1 gap-3 sm:grid-cols-2 p-3 rounded-xl">
        <Input v-model="value" type="text" :label="labelInput" :placeholder="placeholderInput" :keepfocus="true" />

        <div class="self-center sm:max-w-xs">
            <Button @click="$emit('click:submit', value)"> {{ labelButton }} </Button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

import Button from '@/components/buttons/Primary.vue'
import Input from '@/components/form/Input.vue'

const props = defineProps({
    placeholderInput: { type: String, default: '' },
    labelInput: { type: String, default: '' },
    labelButton: { type: String, default: '' },
})

const value = ref('')

const emit = defineEmits(['click:submit'])
</script>
