<template>
    <svg
        v-if="checked"
        :stroke="checkedColor"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    </svg>
    <svg
        v-else
        :stroke="uncheckedColor"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
</template>

<script>
import tailwind from '@/lib/tailwind'

export default {
    props: {
        uncheckedColor: {
            type: String,
            default: tailwind.theme.colors.brand.red,
        },
        checkedColor: {
            type: String,
            default: tailwind.theme.colors.brand.base,
        },
        checked: { type: Boolean, default: false },
    },
}
</script>
