<template>
    <ion-modal
        ref="modal"
        :is-open="isOpenRef"
        :css-class="classCss"
        :keyboard-close="keyboardClose"
        :backdrop-dismiss="backdropDismiss"
        :mode="mode"
        :can-dismiss="true"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        @didDismiss="setOpen(false)"
    >
        <ion-content class="flex flex-col h-full">
            <div class="flex flex-col h-full">
                <RawHeader
                    :back-text="backText ? backText : $t('nav.cancel')"
                    :back-action="() => setOpen(false)"
                    :action-text="actionText === 'Action' ? $t('form.action') : actionText"
                    :action-action="actionAction"
                    :primary="primary"
                    :withIcons="false"
                    :addPadding="false"
                    class="sticky top-0 z-30 w-full rounded-t-xl"
                >
                    {{ title }}
                </RawHeader>

                <div class="overflow-y-auto">
                    <div class="m-5">
                        <slot />
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-modal>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount, inject } from 'vue'
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage } from '@ionic/vue'
import { sleep } from '@/lib/helpers/time'

import RawHeader from '@/components/RawHeader.vue'

const props = defineProps({
    actionAction: { type: Function, default: undefined },
    backText: { type: String, default: undefined },
    actionText: { type: String, default: 'Action' },
    primary: { type: Boolean, default: false },
    title: {
        type: String,
        required: true,
    },
    keyboardClose: {
        type: Boolean,
        default: false,
    },
    backdropDismiss: {
        type: Boolean,
        default: true,
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
    mode: {
        type: String,
        default: 'ios',
    },
    classCss: {
        type: String,
        default: 'full-modal',
    },
    scrollTo: {
        type: String,
        default: '',
    },
})

const emits = defineEmits(['update:isOpen'])
const isMobile = inject('isMobile')
const isOpenRef = ref(props.isOpen)

let scrollToInterval
const restartScrollToInterval = () => {
    // console.log('restartScrollToInterval')
    clearInterval(scrollToInterval) //remove the scroll listener
    if (props.isOpen === true) {
        scrollToInterval = setInterval(async () => {
            if (props.scrollTo) {
                let access = document.getElementById(props.scrollTo)
                if (access) {
                    clearInterval(scrollToInterval) //remove the scroll listener
                    access.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
        }, 200)
    }
}

const setOpen = (state) => {
    isOpenRef.value = state
    emits('update:isOpen', state)
}

watch(
    () => props.isOpen,
    (newIsOpen) => {
        setOpen(newIsOpen)
        restartScrollToInterval()
    }
)

watch(
    () => props.scrollTo,
    () => {
        restartScrollToInterval()
    }
)
onBeforeUnmount(() => {
    setOpen(false)
})
</script>
