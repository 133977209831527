import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import axios from '@/lib/API/client'
import fetcher from '@/lib/API/fetcher'
import useSWRV from 'swrv'
import { mutate } from 'swrv'
import { format } from '@/lib/helpers/time'

export default () => {
    const route = useRoute()

    const currentDate = new Date()
    const horseId = ref('')
    const year = ref(currentDate.getFullYear())
    const month = ref(currentDate.getMonth() + 1) //month start a 0
    const byInterval = ref('daily') //month start a 0

    const { data: horses, error } = useSWRV('/user/horses?all=1', fetcher)

    const { data: horse } = useSWRV(
        () => horseId.value && `/horses/${horseId.value}`,
        () => fetchHorse(horseId.value),
        { revalidateOnFocus: false }
    )

    const { data: horseStatistics } = useSWRV(
        () =>
            horseId.value &&
            `/horses/${horseId.value}/reporting?month=${month.value}&year=${year.value}&by=${byInterval.value}`,
        fetcher
    )

    /**
     *
     * mutate* methods
     *
     **/

    const mutateHorses = (data) => {
        mutate('/user/horses?all=1', data ? data : fetcher('/user/horses?all=1'))
    }

    const mutateHorse = (horseId, data) => {
        mutate(`/horses/${horseId}`, data ? setHorse(data) : fetchHorse(horseId))
    }

    /**
     *
     * fetch* methods
     *
     **/
    const fetchHorse = (horseId) => {
        return fetcher(`/horses/${horseId}`, setHorse)
    }

    /**
     *
     * internal methods
     *
     **/
    const setHorse = (newHorse = {}) => {
        Object.keys(newHorse).forEach((key) => {
            switch (key) {
                case 'born_at':
                    newHorse[key] = newHorse[key] ? format(newHorse[key].replace('Z', ''), 'YYYY-MM-DD') : ''
                    break
                default:
                    newHorse[key] = newHorse[key] ?? ''
            }
        })
        return newHorse
    }

    /**
     *
     * API methods
     *
     **/
    const updateHorse = (horseId, data) => {
        return axios.put(`/horses/${horseId}`, data).then((response) => {
            mutateHorse(horseId, response.data)
            mutateHorses()
            return response.data
        })
    }

    const removeHorse = (horseId) => {
        return axios.delete(`/horses/${horseId}`).then((response) => {
            mutateHorse(horseId, {})
            mutateHorses()
            return response.data
        })
    }

    const updateHorseAvatar = (horseId, file) => {
        let formData = new FormData()
        formData.append('avatar', file)
        return axios
            .post(`/horses/${horseId}/avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                mutateHorse(horseId, response.data)
                mutateHorses()
                return response.data
            })
    }

    const createHorse = (data) => {
        return axios.post('/horses', data).then((response) => {
            mutateHorse(response.data.id, response.data)
            mutateHorses()
            return response.data
        })
    }

    const shareHorse = (horseId, email, job = null) => {
        return axios.post(`/horses/${horseId}/sharing/invite`, { email, job }).then((response) => {
            return response.data
        })
    }

    const unshareHorse = (horseId, userId) => {
        return axios.delete(`/horses/${horseId}/sharing/${userId}`).then((response) => {
            console.log('response', response)
            // mutateHorse(response.data.id, response.data)
            mutateHorses()
            return response.data
        })
    }

    const transferOwnershipHorse = (horseId, newOwnerId) => {
        return axios.put(`/horses/${horseId}/owners`, { newOwnerId }).then((response) => {
            mutateHorses()
            return response.data
        })
    }

    watch(
        [() => route.params.horse],
        async ([newHorseId], [oldHorseId]) => {
            horseId.value = newHorseId ?? oldHorseId ?? ''
        },
        { immediate: true }
    )

    return {
        //data
        horses,
        error,
        horse,
        horseStatistics,
        year,
        month,
        byInterval,
        //methods
        setHorse,
        updateHorse,
        removeHorse,
        updateHorseAvatar,
        createHorse,
        shareHorse,
        unshareHorse,
        mutateHorse,
        transferOwnershipHorse,
    }
}
