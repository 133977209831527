// ['cross', 'dressage', 'outriding', 'jumping', 'longe', 'foot-work', 'racing', 'reining', 'barrel-racing', 'flat-work']
const disciplinesAvailable = ['cross', 'dressage', 'outriding', 'jumping']

const ordoredGaits = ['canter', 'trot', 'walk', 'standing']

const defaultHorseWeight = 500
const defaultRiderWeight = 60

const healthSubcategoryNames = [
    'hrmax',
    'weight',
    'lactate',
    'vaccin',
    'temperature',
    'state',
    'osteo',
    'dentist',
    'blacksmith',
    'vet',
]

export { disciplinesAvailable, ordoredGaits, defaultHorseWeight, defaultRiderWeight, healthSubcategoryNames }
