import { createI18n } from 'vue-i18n'
import axios from 'axios'
import Memory from '@/lib/memory'
import dayjs from 'dayjs'
import('dayjs/locale/de')
import('dayjs/locale/en')
import('dayjs/locale/fr')
import('dayjs/locale/it')

import { Device } from '@capacitor/device'
import * as Sentry from '@sentry/vue'

const warningEnabled = import.meta.env.VITE_APP_I18N_WARNING || !import.meta.env.PROD

export const i18n = createI18n({
    locale: undefined,
    fallbackLocale: 'en-US',
    legacy: false,
    globalInjection: true,
    warnHtmlInMessage: !warningEnabled ? 'off' : 'warn', //Legacy API mode
    warnHtmlMessage: !warningEnabled ? false : true, //Composition API mode
})
import locales from './supported-locales.json'
export const supportedLocales = locales

const loadedLocales = []

const getLocaleFromLanguage = (language) => {
    // Search the first locale which correspond to the phone's language
    for (const supportedLocale of supportedLocales) {
        if (supportedLocale.language === language || supportedLocale.locale === language) {
            return supportedLocale.locale
        }
    }
    return i18n.global.fallbackLocale.value
}

const getLanguageFromLocale = (locale) => {
    for (const supportedLocale of supportedLocales) {
        if (supportedLocale.language === locale || supportedLocale.locale === locale) {
            return supportedLocale.language
        }
    }

    return i18n.global.locale.value.substr(0, i18n.global.locale.value.indexOf('-'))
}

const setLanguage = async (locale) => {
    i18n.global.locale.value = locale
    await Memory.set('locale-i18n', locale)

    let language = getLanguageFromLocale(locale)

    document.querySelector('html').setAttribute('lang', language)
    axios.defaults.headers.common['Accept-Language'] = language
}

const loadMessagesOfTheLanguage = async (locale) => {
    if (!loadedLocales.includes(locale)) {
        const messages = await import(`../locales/${locale}.json`)

        loadedLocales.push(locale)
        await i18n.global.setLocaleMessage(locale, messages)
    }
}

export const loadTranslations = async (locale = undefined) => {
    // Always load fallback language if not already loaded
    if (!loadedLocales.length) {
        await loadMessagesOfTheLanguage(i18n.global.fallbackLocale.value)
    }

    // If there is still no language, get the device language
    if (!locale) locale = await getLocaleDevice()

    // If no language, get the language in the phone's memory
    if (!locale) locale = await Memory.get('locale-i18n')

    if (locale) {
        dayjs.locale(locale.substring(0, 2)) // use loaded locale globally
        // Load message of language if not already loaded
        await loadMessagesOfTheLanguage(locale)

        setLanguage(locale)
    } else {
        dayjs.locale(i18n.global.fallbackLocale.value.substring(0, 2)) // use loaded locale globally
        setLanguage(i18n.global.fallbackLocale.value)
    }
    Sentry.setTag('page_locale', locale)

    return true
}

export const getTimeFormat = () => {
    const supportedLanguage = supportedLocales.find((language) => language.locale === i18n.global.locale.value)

    return supportedLanguage ? supportedLanguage.timeFormat : supportedLanguage
}

export const getLocaleDevice = async () => {
    let languageDevice = await Device.getLanguageCode()
    return getLocaleFromLanguage(languageDevice.value)
}
