import { Geolocation } from '@capacitor/geolocation'

export default {
    namespaced: true,
    state: () => {
        return {
            live: {
                longitude: undefined,
                latitude: undefined,
                timestamp: undefined,
            },
        }
    },
    mutations: {
        start(state, { watchId }) {
            state.watchId = watchId
        },

        SET_DATA({ live }, { coords, timestamp }) {
            live.latitude = coords.latitude
            live.longitude = coords.longitude
            live.timestamp = timestamp
        },

        stop(state) {
            if (state.watchId) {
                Geolocation.clearWatch({ id: state.watchId })
            }
            state.live.latitude = undefined
            state.live.longitude = undefined
            state.live.timestamp = undefined
        },
    },

    getters: {
        longitude({ live }) {
            return live.longitude
        },
        latitude({ live }) {
            return live.latitude
        },
        isGPSLive({ live }) {
            return live.timestamp !== undefined
        },
    },

    actions: {
        async start({ commit }) {
            try {
                const permissions = await Geolocation.checkPermissions()
                // console.log('permissions', permissions)
                if (permissions['location'] === 'denied' && permissions['coarseLocation'] === 'denied') {
                    await Geolocation.requestPermissions(['location'])
                    return
                }
                const watchId = await Geolocation.watchPosition({}, (position, err) => {
                    if (err) {
                        console.log('Geolocation failed', err)
                        return
                    }
                    if (position) {
                        commit('SET_DATA', position)
                    }
                })
                // console.log('watchId', watchId)
                commit('start', { watchId })
            } catch (error) {
                console.log('WTF', error)
            }
        },

        async stop({ commit }) {
            commit('stop')
        },
    },
}
