export default {
    namespaced: true,
    state: () => {
        return {
            /**
             * Indicates if the application
             * is currently performing an HTTP
             * request. This is used to prevent
             * actions from running twice and show
             * loading animations.
             */
            isOnRequest: false,
        }
    },

    mutations: {
        /**
         * Sets the is on request
         * property to the given value.
         */
        request(state, value) {
            state.isOnRequest = value
        },
    },
}
