export const PbDataType = {
    UNDEFINED: 0,
    INHERITED: 1,
    ENUM: 2,
    MILLIS: 3,
    SECOND: 4,
    MINUTE: 5,
    HOUR: 6,
    HOURS: 7,
    DAY: 8,
    MONTH: 9,
    YEAR: 10,
    HEARTRATE: 20,
    TIME_ZONE_OFFSET: 66,
}

export const PbSampleType = {
    SAMPLE_TYPE_HEART_RATE: 1,
    SAMPLE_TYPE_RR_INTERVAL: 16,
}

// google.protobuf.FieldOptions.extensions[PbDataType.type] = 50000

export const PbDate = {
    fields: {
        year: {
            type: 'uint32',
            id: 1,
            options: {
                '(type)': PbDataType.YEAR,
            },
        },
        month: {
            type: 'uint32',
            id: 2,
            options: {
                '(type)': PbDataType.MONTH,
            },
        },
        day: {
            type: 'uint32',
            id: 3,
            options: {
                '(type)': PbDataType.DAY,
            },
        },
    },
}

export const PbTime = {
    fields: {
        hour: {
            type: 'uint32',
            id: 1,
            options: {
                '(type)': PbDataType.HOUR,
            },
        },
        minute: {
            type: 'uint32',
            id: 2,
            options: {
                '(type)': PbDataType.MINUTE,
            },
        },
        seconds: {
            type: 'uint32',
            id: 3,
            options: {
                '(type)': PbDataType.SECOND,
            },
        },
        millis: {
            type: 'uint32',
            id: 4,
            options: {
                '(type)': PbDataType.MILLIS,
                'default': 0,
            },
        },
    },
}

export const PbSystemDateTime = {
    fields: {
        date: {
            type: 'PbDate',
            id: 1,
        },
        time: {
            type: 'PbTime',
            id: 2,
        },
        trusted: {
            type: 'bool',
            id: 3,
        },
    },
}

export const PbDuration = {
    fields: {
        hours: {
            type: 'uint32',
            id: 1,
            options: {
                '(type)': PbDataType.HOURS,
                'default': 0,
            },
        },
        minutes: {
            type: 'uint32',
            id: 2,
            options: {
                '(type)': PbDataType.MINUTE,
                'default': 0,
            },
        },
        seconds: {
            type: 'uint32',
            id: 3,
            options: {
                '(type)': PbDataType.SECOND,
                'default': 0,
            },
        },
        millis: {
            type: 'uint32',
            id: 4,
            options: {
                '(type)': PbDataType.MILLIS,
                'default': 0,
            },
        },
    },
}
