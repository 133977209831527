<template>
    <Link v-bind="$attrs">
        <div>{{ $t('form.add') }}</div>
        <PlusCircleIcon class="w-5 h-5" />
    </Link>
</template>

<script>
// use normal <script> to declare options
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import Link from '@/components/buttons/Link.vue'
import { PlusCircleIcon } from '@heroicons/vue/24/outline'
</script>
