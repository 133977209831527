import Storage from './Storage'

export default class Bootloader {
    constructor(folderName = 'Bootloader') {
        this.arrayBuffer_Firmware = new ArrayBuffer()
        this.byteCnt = 0
        this.isStarted = false
        this.isDone = false
        this.storage = Storage.make().documentsDirectory()

        this.folderName = folderName

        this.storage
            .directoryExists(folderName)
            .then(() => this.storage.exists(folderName).then((result) => console.log(result)))
            .catch(() => this.createDirectory(folderName))
    }

    createDirectory(folderName) {
        this.storage
            .mkdir(folderName)
            .then((response) => {
                console.log('Bootloader Directory: ' + this.folderName, response)
            })
            .catch((err) => {
                console.log('Error in creating Bootloader directory', err)
            })
    }

    start(name) {
        const fileName = name + '.bin'
        return this.storage.readAsArrayBufferView(this.folderName, fileName).then(
            (firmwareDataView) => {
                this.arrayBuffer_Firmware = firmwareDataView.buffer
                if (
                    new TextDecoder('utf-8').decode(new Uint8Array(this.arrayBuffer_Firmware.slice(0, 16))) !=
                    'SdraBootValidKey'
                ) {
                    alert('Error: Wrong "Alogo.bin" file!')
                    return false
                } else {
                    this.isStarted = true
                    this.byteCnt = 16
                    return true
                }
            },
            (err) => {
                alert(err)
                return false
            }
        )
    }
    continue_() {
        let LEN = 240 // 244 is max value
        var offset = this.byteCnt
        var len = 0
        if (this.byteCnt + LEN <= this.arrayBuffer_Firmware.byteLength) len = LEN
        else len = this.arrayBuffer_Firmware.byteLength - this.byteCnt

        this.byteCnt += len
        if (this.byteCnt == this.arrayBuffer_Firmware.byteLength) {
            this.isStarted = false
            this.isDone = true
        }
        return this.arrayBuffer_Firmware.slice(offset, offset + len)
    }
}
