import { h, render } from 'vue'

import BannerComponent from '@/plugins/banner/components/index.vue'

export const useBanner = (globalProps = {}) => {
    // * Tip: Use instance.ctx to acces props, data, functions ... of the component

    const show = (id, banner) => {
        if (document.querySelector(`#banner-${id}`)) return

        const propsData = Object.assign({}, globalProps, banner)
        createComponent(
            BannerComponent,
            propsData,
            document.querySelector('#header'),
            ['custom-banner', 'relative', 'z-10'],
            `banner-${id}`
        )
    }

    const hide = (id) => {
        const bannerElement = document.querySelector(`#banner-${id}`)
        if (bannerElement) removeComponent(bannerElement)
    }

    return { show, hide }
}

const removeComponent = (component) => {
    render(null, component)
    component.remove()
}

const createComponent = (component, props, parentContainer, classes = '', id = null, slots = {}) => {
    const vNode = h(component, props, slots)
    const container = document.createElement('div')
    container.classList.add(...classes)
    container.setAttribute('id', id)
    parentContainer.prepend(container) // append -> insert in last position, prepend in first
    render(vNode, container)
}
