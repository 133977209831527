import fetcher from '@/lib/API/fetcher'
import useSWRV from 'swrv'

export default () => {
    const { data: metas, error } = useSWRV('/metas', fetcher, {
        revalidateOnFocus: false,
        dedupingInterval: 60000,
    })

    // eventType => where name / id are from the subcategory
    const getEventTypeByKey = (value, key = 'id') => {
        let res = null
        if (metas.value?.['event_category']?.length > 0) {
            for (let index = 0; index < metas.value['event_category'].length; index++) {
                const eventCategory = metas.value['event_category'][index]
                const eventType = eventCategory['subcategories']?.find((ev) => ev?.[key] === value)
                if (eventType !== undefined) {
                    res = { ...eventType, category_name: eventCategory['name'], category_id: eventCategory['id'] }
                    break
                }
            }
        }
        return res
    }

    return { metas, error, getEventTypeByKey }
}
