<template>
    <!-- Events type event modal -->
    <CrudEventModal :event="crudEvent" v-model:is-open="openEventModal" />

    <!-- Events type protocol modal -->
    <ProtocolInformationModal :protocol="protocol ?? {}" :horse="horse ?? {}" v-model:is-open="openProtocolModal" />

    <!-- Events type program modal -->
    <ProgramInformationModal :program="program ?? {}" :horse="horse ?? {}" v-model:is-open="openProgramModal" />

    <!-- Ask to sync sensor modal -->
    <Modal classCss="half-modal" :title="$t('form.sync-session-from-sensor-title')" v-model:is-open="openSyncModal">
        <Information>
            {{ $t('form.sync-session-from-sensor-explanation') }}
            <i>{{ $t('form.sync-session-from-sensor-explanation-italic') }}</i>
        </Information>
        <div class="mt-5 rounded-md shadow-sm">
            <Button
                :action="
                    () => {
                        openSyncModal = false
                        $router.push({ name: 'sensors.index' })
                    }
                "
            >
                {{ $t('sensor.sync-session-from-sensor') }}
            </Button>
            <!-- <Button :to="{ name: 'sensors.index' }"> {{ $t('sensor.sync-session-from-sensor') }} </Button> -->
        </div>
    </Modal>

    <!-- Ask to clean sensor memory modal -->
    <Modal classCss="half-modal" :title="$t('sensor.clear-memory')" v-model:is-open="openEraseModal">
        <Information>
            {{ $t('sensor.all-sessions-synced') }}
        </Information>
        <div class="mt-5 rounded-md shadow-sm">
            <Button
                danger
                :action="
                    () => {
                        openEraseModal = false
                        $sensor.current.eraseMemory()
                    }
                "
            >
                {{ $t('sensor.clear-memory') }}
            </Button>
        </div>
    </Modal>

    <UnvalidatedSessionModal v-model:session="session" v-model:is-open="openUnvalidatedSessionModal" />
</template>

<script setup>
import { ref, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import useEvents from '@/lib/hooks/use-events'
import useSessions from '@/lib/hooks/use-sessions'

import { format } from '@/lib/helpers/time'

import CrudEventModal from '@/components/modals/CrudEventModal.vue'
import ProtocolInformationModal from '@/components/modals/ProtocolInformationModal.vue'
import ProgramInformationModal from '@/components/modals/ProgramInformationModal.vue'
import UnvalidatedSessionModal from '@/components/modals/UnvalidatedSessionModal.vue'
import Information from '@/components/Information.vue'
import Button from '@/components/buttons/Primary.vue'
import Modal from '@/components/ion/Modal.vue'

const sensor = inject('sensor')

const store = useStore()
const route = useRoute()
const router = useRouter()

const { clickedEvent, getEmptyEvent } = useEvents()
const { prefetchSession } = useSessions()

const openEventModal = ref(false)
const openProgramModal = ref(false)
const openProtocolModal = ref(false)
const openSyncModal = ref(false)
const openEraseModal = ref(false)
const openUnvalidatedSessionModal = ref(false)

const horse = ref(undefined)
const session = ref(undefined)
const protocol = ref(undefined)
const program = ref(undefined)

const crudEvent = ref(getEmptyEvent())

const openSessionPage = (sessionId) => {
    router.push({
        name: 'sessions.session',
        params: { sessionId: sessionId },
        query: { ...route.query },
    })
}

watch(
    () => clickedEvent.value,
    () => {
        if (clickedEvent.value) {
            switch (clickedEvent.value?.model) {
                case 'session':
                    if (
                        clickedEvent.value?.session?.id &&
                        (clickedEvent.value?.session?.status === 'processed' || store.state.auth?.user?.admin)
                    ) {
                        session.value = clickedEvent.value?.session
                        if (!session.value.validated && session.value.canEdit && !openUnvalidatedSessionModal.value) {
                            openUnvalidatedSessionModal.value = true
                            prefetchSession(session.value.id)
                        } else {
                            openSessionPage(session.value.id)
                        }
                    }
                    break

                case 'protocol':
                    horse.value = clickedEvent.value?.horse
                    protocol.value = clickedEvent.value?.protocol
                    openProtocolModal.value = true
                    break

                case 'program':
                    horse.value = clickedEvent.value?.horse
                    program.value = clickedEvent.value?.program
                    openProgramModal.value = true
                    break

                // Events
                default:
                    crudEvent.value = clickedEvent.value
                    crudEvent.value.start_at = format(crudEvent.value.start_at, 'YYYY-MM-DD')
                    crudEvent.value.end_at = format(crudEvent.value.end_at, 'YYYY-MM-DD')
                    openEventModal.value = true
                    break
            }
            clickedEvent.value = undefined
        }
    }
)

watch(
    () => sensor.current.files,
    (files) => {
        if (
            sensor.current.isConnected &&
            files.length > 0 &&
            sensor.current.properties.filesCount === files.filter((f) => f.properties.uploaded === true).length
        ) {
            openEraseModal.value = true
        }
    },
    { deep: true }
)

watch(
    () => sensor.current.isConnected,
    (isConnected, wasConnected) => {
        // console.log('isConnected, wasConnected', isConnected, wasConnected, sensor.current.isRecording)
        if (isConnected && !wasConnected) {
            if (sensor.current.isRecording || sensor.current.isPaused) {
                // we do not redirect automatically to the live page because
                // we could go to the live page through the record button
                // console.log(
                //     'should redirect to record.live isConnected && !wasConnected sensor.current.isRecording || sensor.current.isPaused'
                // )
                // router.push({ name: 'record.live' })
            } else if (sensor.current.properties.memory > 0) {
                if (!sensor.current.isMemoryMode && route.name !== 'sensors.index' && route.name !== 'record.live') {
                    openSyncModal.value = true
                }
            }
        }
    }
)

watch(
    () => session.value,
    (newSession) => {
        if (newSession?.validated) {
            openSessionPage(newSession.id)
        }
    }
)
</script>
