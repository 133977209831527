<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
            d="M 7 5 L 10 8 L 7 11"
            fill="transparent"
            stroke-width="2"
            stroke="rgb(132, 136, 144)"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
