<template>
    <svg height="25" width="25">
        <circle
            cx="12.5"
            cy="12.5"
            r="10"
            stroke-width="5"
            class="fill-brand stroke-base"
            :class="{ pulse }"
            :fill="color"
            :stroke="strokeColor"
        />
    </svg>
</template>

<script>
import tailwind from '@/lib/tailwind'

export default {
    props: {
        color: {
            type: String,
            default: tailwind.theme.colors.brand.base,
        },
        strokeColor: {
            type: String,
            default: tailwind.theme.colors.dark.base,
        },
        pulse: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
