<template>
    <Modal
        class="space-y-6"
        :title="isUpdate ? $t('event.update-event') : $t('event.add-event')"
        :is-open="isOpen"
        @update:is-open="setOpen($event)"
    >
        <div class="space-y-4">
            <Tabs v-model="categorySelected" :headers="categories" />
            <div class="flex-1 space-y-2">
                <Tabs v-model="subCategorySelected" :headers="subCategories" pills />
            </div>

            <div v-if="displayHorseField" class="rounded-md shadow-sm" :class="{ disabled: horseLocked }">
                <Select v-model.number="editableEvent.horse_id" name="Horse" :label="$t('horse.title')" required>
                    <option v-for="horse in horses" :key="horse.id" :value="horse.id">
                        {{ horse.name }}
                    </option>
                </Select>
            </div>

            <div v-if="metaKey" class="rounded-md shadow-sm">
                <Select
                    v-model="editableEvent.meta"
                    :name="$t(`meta.${metaKey}.title`)"
                    :label="$t(`meta.${metaKey}.title`)"
                    required
                >
                    <option
                        v-for="(meta, keyOfMeta) in metas[metaKey]"
                        :key="`crudEventModal-${keyOfMeta}`"
                        :value="keyOfMeta"
                    >
                        {{ $t(`meta.${metaKey}.${formatKeyOfMeta(keyOfMeta)}`) }}
                    </option>
                </Select>
            </div>
            <div v-else-if="title.display" class="rounded-md shadow-sm">
                <Input
                    v-model="editableEvent.title"
                    :placeholder="$t(`event.subcategory.placeholder-${title.label}`)"
                    :type="title.inputType"
                    :label="$t(`event.subcategory.label-${title.label}`)"
                    required
                    :unit="subCategories[subCategorySelected].unit"
                    :userUnit="subCategories[subCategorySelected].userUnit"
                />
            </div>

            <div class="rounded-md shadow-sm">
                <Input v-model="editableEvent.start_at" type="date" :label="$t('event.date')" required />
            </div>

            <div class="rounded-md shadow-sm">
                <Input
                    v-model="editableEvent.description"
                    :placeholder="$t('event.add-description')"
                    type="textarea"
                    :label="$t('event.description')"
                />
            </div>
        </div>

        <div class="mt-5 rounded-md shadow-sm">
            <div v-if="isUpdate" class="space-y-4">
                <Button :action="() => update(editableEvent)">
                    {{ $t('form.save') }}
                </Button>

                <Button :action="() => remove(editableEvent.id)" danger>
                    {{ $t('form.delete') }}
                </Button>
            </div>

            <Button v-else :action="() => create(editableEvent)">
                {{ $t('form.save') }}
            </Button>
        </div>
    </Modal>
</template>

<script setup>
import { computed, ref, watch, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { getUnitKey } from '@/lib/helpers/convert'

import useEvents from '@/lib/hooks/use-events'
import useMetas from '@/lib/hooks/use-metas'
import useHorses from '@/lib/hooks/use-horses'
import useUser from '@/lib/hooks/use-users'
import useSessions from '@/lib/hooks/use-sessions'
import { useRoute } from 'vue-router'

import { Toast } from '@/components/ion/Toast.vue'
import Button from '@/components/buttons/Primary.vue'
import Input from '@/components/form/Input.vue'
import Select from '@/components/form/Select.vue'
import Tabs from '@/components/Tabs.vue'
import Modal from '@/components/ion/Modal.vue'

const props = defineProps({
    isOpen: { type: Boolean, default: false },
    event: { type: Object, required: true },
    defaultCategory: { type: String, default: null },
    defaultSubCategory: { type: String, default: null },
})

const emit = defineEmits(['update:isOpen'])

const { t } = useI18n()
const { createEvent, updateEvent, deleteEvent } = useEvents()
const { metas, getEventTypeByKey } = useMetas()
const { horses, mutateHorse } = useHorses()
const { userUnits, refreshUser } = useUser()
const { mutateSession } = useSessions()
const route = useRoute()

const editableEvent = ref({})
const horseLocked = ref(false)

watch(
    () => props.event,
    () => {
        editableEvent.value = props.event
        if (editableEvent.value?.horse?.id) {
            editableEvent.value.horse_id = editableEvent.value?.horse?.id
            horseLocked.value = true
        } else horseLocked.value = false
    },
    { immediate: true }
)
const isUpdate = computed(() => 'id' in editableEvent.value)

const eventCategories = computed(() => metas.value?.['event_category'] ?? [])

const displayHorseField = ref(true)
const title = ref({
    label: 'value',
    inputType: 'number',
    display: true,
})
const metaKey = ref(null)

const categorySelected = ref(props.defaultCategory)
const categories = computed(() => {
    let result = {}
    for (let indexCategory = 0; indexCategory < eventCategories.value?.length; indexCategory++) {
        const categoryName = eventCategories.value[indexCategory]?.name
        result[categoryName] = {
            text: t('event.category.' + categoryName),
            disable: isUpdate.value,
        }
    }
    return result
})
//in order to auto select the first category on the first load
watch(
    () => eventCategories.value,
    () => {
        if (eventCategories.value?.length && !categorySelected.value) {
            categorySelected.value = eventCategories.value[0]?.name
        }
    },
    { immediate: true }
)

const subCategoriesOfCategory = computed(
    () => eventCategories.value?.find((category) => category.name === categorySelected.value)?.subcategories
)

const subCategorySelected = ref(props.defaultSubCategory)
const subCategories = computed(() => {
    let result = {}
    for (let indexSubcategory = 0; indexSubcategory < subCategoriesOfCategory.value?.length; indexSubcategory++) {
        const eventType = subCategoriesOfCategory.value[indexSubcategory]
        result[eventType.subcategory_name] = {
            text: t('event.subcategory.' + eventType.subcategory_name),
            unit: eventType.unit,
            userUnit: userUnits.value?.[getUnitKey(eventType.unit)],
            event_type_id: eventType.event_type_id,
            disable: isUpdate.value,
        }
    }
    return result
})

// To display right subcategories according to the category selected
watch(
    () => subCategories.value,
    () => {
        if (!subCategoriesOfCategory.value?.find((subcat) => subcat.subcategory_name === subCategorySelected.value)) {
            subCategorySelected.value = subCategoriesOfCategory.value?.[0]?.subcategory_name
        }
    },
    { immediate: true }
)
//when changing the event without closing the modal ?
watch(
    () => editableEvent.value?.event_type_id,
    () => {
        const eventType = getEventTypeByKey(editableEvent.value?.event_type_id, 'event_type_id')
        if (eventType) {
            categorySelected.value = eventType?.category_name
            subCategorySelected.value = eventType?.subcategory_name
        }
    },
    { immediate: true }
)
// To display right form fields according to the subcategory selected
watch(
    () => subCategorySelected.value,
    () => {
        const eventType = getEventTypeByKey(subCategorySelected.value, 'subcategory_name')
        displayHorseField.value = eventType?.have_horse
        metaKey.value = eventType?.meta_key

        title.value.display = eventType?.title_type !== 'hidden'

        if (['integer', 'float'].includes(eventType?.title_type)) {
            title.value.label = 'value'
            title.value.inputType = 'number'
        } else {
            title.value.label = 'title'
            title.value.inputType = 'text'
        }
    },
    { immediate: true }
)

watch(
    () => horses.value,
    () => {
        if (horses.value?.length && !editableEvent.value.horse_id) editableEvent.value.horse_id = horses.value?.[0]?.id
    },
    { immediate: true }
)

const setOpen = (state) => {
    if (state === true) {
        refreshUser()
    }
    emit('update:isOpen', state)
}

const create = (event) => {
    const formatedEvent = formatPayload(event)

    createEvent(formatedEvent)
        .then((response) => {
            Toast.success(response?.data?.message ?? t('event.event-created'))
            setOpen(false)
            if (route.params.sessionId) {
                mutateSession(route.params.sessionId)
            } else if (route.params.horse) {
                mutateHorse(route.params.horse)
            }
        })
        .catch((error) => {
            Toast.warning(error?.message)
        })
}

const update = (event) => {
    const formatedEvent = formatPayload(event)

    updateEvent(formatedEvent.id, formatedEvent)
        .then((response) => {
            Toast.success(response?.data?.message ?? t('event.event-updated'))
            setOpen(false)
        })
        .catch((error) => {
            Toast.warning(error?.message)
        })
}

const remove = (eventId) => {
    deleteEvent(eventId)
        .then((response) => {
            Toast.success(response?.data?.message ?? t('event.event-deleted'))
            setOpen(false)
        })
        .catch((error) => {
            Toast.warning(error?.message)
        })
}

const formatPayload = (event) => {
    event.event_type_id = subCategories.value[subCategorySelected.value].event_type_id
    event.end_at = event.start_at

    if (!title.value.display) event.title = null
    if (metaKey.value) event.title = event.meta //because the title is a translated version of the meta

    return event
}

const formatKeyOfMeta = (keyOfMeta) => {
    if (keyOfMeta && keyOfMeta?.toLowerCase()) {
        return keyOfMeta?.toLowerCase()?.replaceAll(' ', '-')
    }
    return ''
}
</script>
