import { asDecimal } from '@/lib/helpers/numeric'
import tailwind from '@/lib/tailwind'
import * as Sentry from '@sentry/vue'

export const toDatetimeLocal = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    if (!(date instanceof Date && !isNaN(date))) {
        return ''
    }

    let ten = (i) => {
        return (i < 10 ? '0' : '') + i
    }
    let mille = (i) => {
        return (i < 10 ? '000' : i < 100 ? '00' : i < 1000 ? '0' : '') + i
    }
    let YYYY = mille(date.getFullYear()),
        MM = ten(date.getMonth() + 1),
        DD = ten(date.getDate()),
        HH = ten(date.getHours()), //according to local time.
        II = ten(date.getMinutes()),
        SS = ten(date.getSeconds())
    return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS
}

export const fromDatetimeLocal = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    return date instanceof Date && !isNaN(date) ? new Date(date).toISOString() : ''
}

/**
 * Small utility to transform seconds into hours, minutes and seconds as an array or an object with unit.
 *
 * @param {int}      seconds        Seconds to format
 * @param {int|bool} maxUnits       Number of maximum units returneds (3: HH:MM:SS, 2: HH:MM or MM:SS, 1: HH or MM or SS)
 *                                  If 1 OR 2 OR 3, return an object. Example: for 1392 seconds (23min 12sec): { value: 23:12, unit: min}.
 *                                  I false, return an array. Example: for 1392 seconds (23min 12sec): [0: 00, 1: 23, 2: 12]
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 *                                   If false, return an object {value: the_value, unit : the_unit}
 * @returns {array|object}
 */
export const secondsToHMS = (seconds, maxUnits = 3, returnString = false) => {
    if (isNaN(seconds))
        return returnString
            ? ''
            : {
                  value: '',
                  unit: '',
              }

    if (seconds >= 86400) {
        const warningMessage = 'We do not manage as much seconds for now ' + seconds
        console.warn(warningMessage)
        Sentry.captureMessage(warningMessage)
        return returnString
            ? '01 d'
            : {
                  value: '01',
                  unit: 'd',
              }
    }
    const arrTime = new Date(seconds * 1000).toISOString().substr(11, 8).split(':')
    let time = {}

    if (!maxUnits) {
        time = arrTime[0] === '00' ? arrTime.slice(1).join(':') : arrTime.join(':')
    } else {
        while (arrTime[0] === '00') {
            arrTime.shift()
        }

        if (arrTime.length === 0) {
            return returnString
                ? '00 sec'
                : {
                      value: '00',
                      unit: 'sec',
                  }
        }

        switch (arrTime.length) {
            case 1:
                time.unit = 'sec'
                break
            case 2:
                if (maxUnits == 1) {
                    if (arrTime[1] >= 30) {
                        if (arrTime[0] < 59) {
                            arrTime[0]++
                            if (arrTime[0].toString().length === 1) arrTime[0] = '0' + arrTime[0].toString()
                        } else {
                            return returnString
                                ? '01 h'
                                : {
                                      value: '01',
                                      unit: 'h',
                                  }
                        }
                    }

                    arrTime.pop()
                }

                time.unit = 'min'

                break
            default:
                if (maxUnits < 3) {
                    if (maxUnits == 2) {
                        //more than 30 seconds
                        if (arrTime[2] >= 30) {
                            //add one minute
                            arrTime[1]++

                            if (arrTime[1].toString().length === 1) arrTime[1] = '0' + arrTime[1].toString()
                        }
                        arrTime.pop() //remove the seconds
                    } else {
                        // 1 units or less?
                        //more than 30 minutes
                        if (arrTime[1] >= 30) {
                            //add one hour
                            arrTime[0]++

                            if (arrTime[0].toString().length === 1) arrTime[0] = '0' + arrTime[0].toString()
                        }
                        arrTime.pop() //remove the minutes
                        arrTime.pop() //remove the seconds
                    }
                }

                time.unit = 'h'
        }

        time.value = arrTime.join(':')
    }

    return returnString ? time.value + ' ' + time.unit : time
}

/**
 * Small utility to transform seconds object or string corresponding to HH:MM:SS.
 *
 * @param {string|object}   time        Time in string format "HH:MM(:SS) sec|m|h" or in object { value: HH:MM(:SS), unit: "min"}
 * @param {bool}            isString    If isString is false, time is an object returned by secondsToHMS()
 *                                      If isString is true, time is a string like HH:MM(:SS) sec|m|h
 * @returns {int}
 */
//
export const HMStoSeconds = (time, isString = true) => {
    let seconds = 0

    if (isString) {
        const [value, unit] = time.split(' ')

        time = {
            value: value,
            unit: unit,
        }
    }

    switch (time.unit) {
        case 'sec':
            seconds = time.value
            break
        case 'min':
            seconds = parseInt(time.value.split(':')[1]) + parseInt(time.value.split(':')[0]) * 60
            break
        case 'h':
            seconds =
                parseInt(time.value.split(':')[2]) +
                parseInt(time.value.split(':')[1]) * 60 +
                parseInt(time.value.split(':')[0]) * 60 * 60
            break
    }

    return seconds
}

/**
 * Small utility to transform value with only the input
 *
 * @param {float}    value          Value to format
 * @param {string}   unitIn         Unit of the value to format.
 * @param {object}   userUnits      Users prefered units by key
 * @param {int}      decimals       Number of decimals
 * @param {bool}     forceUnit      If true, return unit desired without try to format with the upper unit.
 * @returns {object}                Example of return : { value: 55.90, unit: 'lbs' }
 */
export const convertValueUserUnits = (value, unitIn = null, userUnits = {}, decimals, forceUnit, returnString) => {
    if (!unitIn) return null
    const unitKey = getUnitKey(unitIn)
    // if (!unitKey) return null
    const userUnit = userUnits?.[unitKey]
    // if (!userUnit) return null
    return convertValueUnit(value, unitIn, userUnit, decimals, forceUnit, returnString)
}

/**
 * Small utility to transform value and unit
 *
 * @param {float}    value          Value to format
 * @param {string}   unitIn         Unit of the value to format.
 * @param {string}   unitOut        Unit desired. According to the unit set up in user profile.
 * @param {int}      decimals       Number of decimals
 * @param {bool}     forceUnit      If true, return unit desired without try to format with the upper unit.
 * @returns {object}                Example of return : { value: 55.90, unit: 'lbs' }
 */
export const convertValueUnit = (value, unitIn, unitOut, decimals, forceUnit, returnString) => {
    const emptyValue = {
        value: decimals || decimals === 0 ? asDecimal(value, decimals) : value,
        unit: unitOut ? unitOut : unitIn,
    }

    const supportedDistanceUnit = Object.keys(units['m']) //['cm', 'm', 'km', 'in', 'ft', 'mi']
    const supportedWeightUnit = Object.keys(units['kg']) //['kg', 'lbs']
    const supportedSpeedUnit = Object.keys(units['km/h']) //['km/h', 'm/min', 'mph']
    const supportedDegreeUnit = Object.keys(units['C']) // ['F', 'C', 'K']

    const supportedTimeUnit = [
        ...Object.keys(units['hh:mm:ss']),
        ...Object.keys(units['hh:mm']),
        ...Object.keys(units['mm:ss']),
    ] //['hh:mm','hh:mm:ss', 's', 'mm:ss']

    const supportedUnit = [
        ...supportedDistanceUnit,
        ...supportedWeightUnit,
        ...supportedSpeedUnit,
        ...supportedDegreeUnit,
        ...supportedTimeUnit,
    ]

    if (!supportedUnit.includes(unitIn) || !supportedUnit.includes(unitOut))
        return returnString ? emptyValue.value + ' ' + emptyValue.unit : emptyValue

    if (!unitIn || !unitOut || (isNaN(value) && !supportedTimeUnit.includes(unitIn)) || value === null || value === '')
        return returnString ? emptyValue.value + ' ' + emptyValue.unit : emptyValue

    if (supportedDistanceUnit.includes(unitIn)) {
        return convertDistance(value, unitIn, unitOut, forceUnit, decimals, returnString)
    } else if (supportedWeightUnit.includes(unitIn)) {
        return convertWeight(value, unitIn, unitOut, decimals, returnString)
    } else if (supportedSpeedUnit.includes(unitIn)) {
        return convertSpeed(value, unitIn, unitOut, decimals, returnString)
    } else if (supportedDegreeUnit.includes(unitIn)) {
        return convertDegree(value, unitIn, unitOut, decimals, returnString)
    } else if (supportedTimeUnit.includes(unitIn)) {
        return convertTime(value, unitIn, unitOut, returnString)
    } else {
        throw new Error('Unit not supported')
    }
}

/**
 * Small utility to transform grams/kilograms/pounds in grams/kilograms/pounds.
 * Feel free to add some unit.
 *
 * @param {float}    value          Value to format
 * @param {string}   unitIn         Unit of the value to format.
 * @param {string}   unitOut        Unit desired. According to the unit set up in user profile.
 * @param {int}      decimals        Number of decimals or null to disable it
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 * @returns {object}                Example of return : { value: 55.90, unit: 'lbs' }
 */
export const convertWeight = (value, unitIn = 'kg', unitOut = 'lbs', decimals = 2, returnString = false) => {
    const defaultunitOut = 'kg'

    // If unitOut is null, set the unit to default desired unit
    unitOut = unitOut ?? defaultunitOut

    let weight = {
        value: value,
        unit: unitIn,
    }

    // If the provided unit is equal to the desired unit, return the initial value/unit pair
    if (unitIn !== unitOut) {
        switch (unitIn) {
            case 'kg':
                if (unitOut === 'lbs') {
                    weight.value *= 2.20462
                    weight.unit = 'lbs'
                }

                break
            case 'lbs':
                if (unitOut === 'kg') {
                    weight.value /= 2.20462
                    weight.unit = 'kg'
                }
                break
        }
    }

    if (decimals !== null) {
        weight.value = asDecimal(weight.value, decimals)
    }
    return returnString ? weight.value + ' ' + weight.unit : weight
}

/**
 * Small utility to transform Celsius/Fahrenheit/Kelvin to Celsius/Fahrenheit
 *
 * @param {float}    value      Value to format
 * @param {string}   unitIn     Unit of the value to format
 * @param {string}   unitOut    Unit desired. According to the unit set up in user profile.
 * @param {int}      decimals   Number of decimals or null to disable it
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 * @returns {object}            Example of return : { value: 22.23, unit: '°C' }
 */
export const convertDegree = (value, unitIn = 'C', unitOut = 'F', decimals = 2, returnString = false) => {
    const defaultunitOut = 'C'
    // console.log('value', value)
    // console.log('unitIn', unitIn)
    // console.log('unitOut', unitOut)
    // If unitOut is null, set the unit to default desired unit
    unitOut = unitOut ?? defaultunitOut

    let degree = {
        value: value,
        unit: unitIn,
    }

    if (unitIn !== unitOut) {
        switch (unitIn) {
            case 'K':
                if (unitOut === 'C') degree.value -= 273.15
                else if (unitOut === 'F') degree.value = ((degree.value - 273.15) * 9) / 5 + 32
                break
            case 'C':
                if (unitOut === 'K') degree.value += 273.15
                else if (unitOut === 'F') degree.value = (degree.value * 9) / 5 + 32
                break
            case 'F':
                if (unitOut === 'C') degree.value = ((degree.value - 32) * 5) / 9
                else if (unitOut === 'K') degree.value = ((degree.value - 32) * 5) / 9 + 273.15
                break
        }

        degree.unit = unitOut
    }
    degree.unit = '°' + degree.unit
    if (decimals !== null) {
        degree.value = asDecimal(degree.value, decimals)
    }
    return returnString ? degree.value + degree.unit : degree
}

/**
 * Small utility to transform time
 *
 * @param {float}    value      Value to format
 * @param {string}   unitIn     Unit of the value to format
 * @param {string}   unitOut    Unit desired. According to the unit set up in user profile.
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 * @returns {object}            Example of return : { value: 22, unit: 's' }
 */
export const convertTime = (value = 0, unitIn = 'hh:mm:ss', unitOut = 's', returnString = false) => {
    const defaultunitOut = 's'
    // console.log('value', value)
    // console.log('unitIn', unitIn)
    // console.log('unitOut', unitOut)
    // If unitOut is null, set the unit to default desired unit
    unitOut = unitOut ?? defaultunitOut

    let time = {
        value: value,
        unit: unitIn,
    }

    if (unitIn !== unitOut) {
        switch (unitIn) {
            case 'hh:mm:ss':
                if (unitOut === 's') {
                    const [hours, minutes, seconds] = value.split(':').map((v) => parseInt(v))
                    time.value = hours * 60 * 60 + minutes * 60 + seconds
                } else {
                    const warningMessage = `Time conversion from ${unitIn} to ${unitOut} not developped`
                    console.warn(warningMessage)
                    Sentry.captureMessage(warningMessage)
                }
                break
            case 'hh:mm':
                if (unitOut === 's') {
                    const [hours, minutes] = value.split(':').map((v) => parseInt(v))
                    time.value = hours * 60 * 60 + minutes * 60
                } else {
                    const warningMessage = `Time conversion from ${unitIn} to ${unitOut} not developped`
                    console.warn(warningMessage)
                    Sentry.captureMessage(warningMessage)
                }
                break
            case 'mm:ss':
                if (unitOut === 's') {
                    const [minutes, seconds] = value.split(':').map((v) => parseInt(v))
                    time.value = minutes * 60 + seconds
                } else {
                    const warningMessage = `Time conversion from ${unitIn} to ${unitOut} not developped`
                    console.warn(warningMessage)
                    Sentry.captureMessage(warningMessage)
                }
                break
            case 's':
                if (unitOut === 'hh:mm:ss') {
                    if (value < 3600) {
                        // if seconds are less than 1 hour and you only need mm:ss
                        time.value = '00:' + new Date(value * 1000).toISOString().slice(14, 19)
                    } else {
                        time.value = new Date(value * 1000).toISOString().slice(11, 19)
                    }
                } else if (unitOut === 'hh:mm') {
                    if (value < 3600) {
                        // if seconds are less than 1 hour and you only need mm:ss
                        time.value = '00:' + new Date(value * 1000).toISOString().slice(14, 16)
                    } else {
                        time.value = new Date(value * 1000).toISOString().slice(11, 16)
                    }
                } else if (unitOut === 'mm:ss') {
                    if (value < 3600) {
                        // if seconds are less than 1 hour and you only need mm:ss
                        time.value = new Date(value * 1000).toISOString().slice(14, 19)
                    } else {
                        //more than 1 hour
                        const minutes = Math.round(value / 60)
                        time.value = minutes + ':' + new Date(value * 1000).toISOString().slice(17, 19)
                    }
                } else {
                    const warningMessage = `Time conversion from ${unitIn} to ${unitOut} not developped`
                    console.warn(warningMessage)
                    Sentry.captureMessage(warningMessage)
                }
                break
        }

        time.unit = unitOut
    }

    return returnString ? time.value + ' ' + time.unit : time
}

/**
 * Small utility to transform km/h / m/min / to km/h / m/min / mph
 * Feel free to add some unit.
 *
 * @param {float}    value      Value to format
 * @param {string}   unitIn     Unit of the value to format
 * @param {string}   unitOut    Unit desired. According to the unit set up in user profile.
 * @param {int}      decimals        Number of decimals or null to disable it
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 * @returns {object}            Example of return : { value: 12.30, unit: 'km/h' }
 */
export const convertSpeed = (value, unitIn = 'km/h', unitOut = 'm/min', decimals = 0, returnString = false) => {
    const defaultunitOut = 'm/min'

    // If unitOut is null, set the unit to default desired unit
    unitOut = unitOut ?? defaultunitOut

    let speed = {
        value: value,
        unit: unitIn,
    }

    // If the provided unit is equal to the desired unit, return the initial value/unit pair
    if (unitIn !== unitOut) {
        switch (unitIn) {
            case 'km/h':
                if (unitOut === 'm/min') {
                    speed.value *= 16.6667
                    speed.unit = 'm/min'
                } else if (unitOut === 'mph') {
                    speed.value /= 1.60934
                    speed.unit = 'mph'
                }

                break
            case 'm/min':
                if (unitOut === 'km/h') {
                    speed.value /= 16.6667
                    speed.unit = 'km/h'
                } else if (unitOut === 'mph') {
                    speed.value /= 26.8224
                    speed.unit = 'mph'
                }

                break
            case 'mph':
                if (unitOut === 'km/h') {
                    speed.value *= 1.60934
                    speed.unit = 'km/h'
                } else if (unitOut === 'm/min') {
                    speed.value *= 26.8224
                    speed.unit = 'mph'
                }

                break
        }
    }

    if (decimals !== null) {
        speed.value = asDecimal(speed.value, decimals)
    }
    return returnString ? speed.value + ' ' + speed.unit : speed
}

/**
 * Small utility to transform meters/centimeters in meters/kilometers/foot/inch.
 * Feel free to add some unit.
 *
 * @param {float}    value          Value to format
 * @param {string}   unitIn         Unit of the value to format
 * @param {string}   unitOut        Unit desired. According to the unit set up in user profile.
 * @param {bool}     forceUnit      If true, return unit desired without try to format with the upper unit.
 * @param {int}      decimals        Number of decimals or null to disable it
 * @param {bool}     returnString   If true, return a string "{value} {unit}".
 * @returns {object}                Example of return : { value: 23, unit: 'km' }
 */
export const convertDistance = (
    value,
    unitIn = 'm',
    unitOut = 'm',
    forceUnit = false,
    decimals = 2,
    returnString = false
) => {
    const defaultunitOut = 'm'

    // If unitOut is null, set the unit to default desired unit
    unitOut = unitOut ?? defaultunitOut

    let distance = {
        value: value,
        unit: unitOut,
    }

    switch (unitIn) {
        case 'cm':
            switch (unitOut) {
                case 'cm':
                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'm':
                    distance.value /= 100

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'km':
                    distance.value /= 100000

                    if (!forceUnit) distance = kmToMorCm(distance)

                    break

                case 'in':
                    distance.value /= 2.54

                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    distance.value /= 30.48

                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    distance.value /= 160934

                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }

            break

        case 'm':
            switch (unitOut) {
                case 'cm':
                    distance.value *= 100

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'm':
                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'cm':
                    distance.value *= 100

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'km':
                    distance.value /= 1000

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'in':
                    distance.value *= 39.37

                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    distance.value *= 3.281

                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    distance.value /= 1609

                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }
            break

        case 'km':
            switch (unitOut) {
                case 'cm':
                    distance.value *= 100000

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'm':
                    distance.value *= 1000

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'km':
                    if (!forceUnit) distance = kmToMorCm(distance)

                    break

                case 'in':
                    distance.value *= 39370

                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    distance.value *= 3281

                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    distance.value /= 1.609

                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }

            break

        case 'in':
            switch (unitOut) {
                case 'cm':
                    distance.value *= 2.54

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'm':
                    distance.value /= 39.37

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'km':
                    distance.value /= 39370

                    if (!forceUnit) distance = kmToMorCm(distance)

                    break

                case 'in':
                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    distance.value /= 12

                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    distance.value /= 63360

                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }

            break

        case 'ft':
            switch (unitOut) {
                case 'cm':
                    distance.value *= 30.48

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'm':
                    distance.value /= 3.281

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'km':
                    distance.value /= 3281

                    if (!forceUnit) distance = kmToMorCm(distance)

                    break

                case 'in':
                    distance.value *= 12

                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    distance.value /= 5280

                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }

            break

        case 'mi':
            switch (unitOut) {
                case 'cm':
                    distance.value *= 160934

                    if (!forceUnit) distance = cmToMorKm(distance)

                    break

                case 'm':
                    distance.value *= 1609

                    if (!forceUnit) distance = mToCmOrKm(distance)

                    break

                case 'km':
                    distance.value *= 1.609

                    if (!forceUnit) distance = kmToMorCm(distance)

                    break

                case 'in':
                    distance.value *= 63360

                    if (!forceUnit) distance = inToFtOrMi(distance)

                    break

                case 'ft':
                    distance.value *= 5280

                    if (!forceUnit) distance = ftToInOrMi(distance)

                    break

                case 'mi':
                    if (!forceUnit) distance = miToFtOrIn(distance)

                    break
            }

            break
    }

    if (decimals !== null) {
        distance.value = asDecimal(distance.value, decimals)
    }
    return returnString ? distance.value + ' ' + distance.unit : distance
}

/**
 * Transform centimeters in meters or kilometers
 *
 * @param {object}      distance    Example of param :  { value: 230, unit: 'cm' }
 * @returns {object}                Example of return : { value: 2.3, unit: 'm' }
 */
export const cmToMorKm = (distance) => {
    if (distance.value >= 100 || distance.value <= -100) {
        distance.value /= 100
        distance.unit = 'm'
    }

    if (distance.value >= 1000 || distance.value <= -1000) {
        distance.value /= 1000
        distance.unit = 'km'
    }

    return distance
}

/**
 * Transform meters in centimeters or kilometers
 *
 * @param {object}      distance    Example of param :  { value: 31000, unit: 'm' }
 * @returns {object}                Example of return : { value: 31, unit: 'km' }
 */
export const mToCmOrKm = (distance) => {
    if (distance.value > -1 && distance.value < 1) {
        distance.value *= 100
        distance.unit = 'cm'
    } else if (distance.value >= 1000 || distance.value <= -1000) {
        distance.value /= 1000
        distance.unit = 'km'
    }

    return distance
}

/**
 * Transform kilometers in meters or centimeters
 *
 * @param {object}      distance    Example of param :  { value: 0.5, unit: 'km' }
 * @returns {object}                Example of return : { value: 500, unit: 'm' }
 */
export const kmToMorCm = (distance) => {
    if (distance.value > -1 && distance.value < 1) {
        distance.value /= 1000
        distance.unit = 'm'
    }

    if (distance.value > -1 && distance.value < 1) {
        distance.value /= 100
        distance.unit = 'cm'
    }

    return distance
}

/**
 * Transform miles in foot or inches
 *
 * @param {object}      distance    Example of param :  { value: 23, unit: 'km' }
 * @returns {object}                Example of return : { value: 23, unit: 'km' }
 */
export const miToFtOrIn = (distance) => {
    if (distance.value > -1 && distance.value < 1) {
        distance.value *= 5280
        distance.unit = 'ft'
    }

    if (distance.value > -1 && distance.value < 1) {
        distance.value *= 12
        distance.unit = 'in'
    }

    return distance
}

/**
 * Transform foot in inches or miles
 *
 * @param {object}      distance    Example of param :  { value: 23, unit: 'km' }
 * @returns {object}                Example of return : { value: 23, unit: 'km' }
 */
export const ftToInOrMi = (distance) => {
    if (distance.value > -1 && distance.value < 1) {
        distance.value *= 12
        distance.unit = 'in'
    } else if (distance.value >= 5280 || distance.value <= -5280) {
        distance.value /= 5280
        distance.unit = 'mi'
    }

    return distance
}

/**
 * Transform inches in foot or miles
 *
 * @param {object}      distance    Example of param :  { value: 23, unit: 'km' }
 * @returns {object}                Example of return : { value: 23, unit: 'km' }
 */
export const inToFtOrMi = (distance) => {
    if (distance.value >= 12 || distance.value <= -12) {
        distance.value /= 12
        distance.unit = 'ft'
    }

    if (distance.value >= 5280 || distance.value <= -5280) {
        distance.value /= 5280
        distance.unit = 'mi'
    }

    return distance
}

export const units = {
    // DISTANCES
    'm': {
        cm: 'cm',
        m: 'm',
        km: 'km',
        in: 'cm',
        ft: 'm',
        mi: 'km',
    },
    'mi': {
        cm: 'in',
        m: 'ft',
        km: 'mi',
        in: 'in',
        ft: 'ft',
        mi: 'mi',
    },
    // WEIGHT
    'kg': {
        kg: 'kg',
        lbs: 'kg',
    },
    'lbs': {
        kg: 'lbs',
        lbs: 'lbs',
    },
    // SPEED
    'km/h': {
        'km/h': 'km/h',
        'm/min': 'km/h',
        'mph': 'km/h',
    },
    'm/min': {
        'km/h': 'm/min',
        'm/min': 'm/min',
        'mph': 'm/min',
    },
    'mph': {
        'km/h': 'mph',
        'm/min': 'mph',
        'mph': 'mph',
    },
    // DEGREE
    'C': { F: 'C', C: 'C', K: 'C' },
    'F': { F: 'F', C: 'F', K: 'F' },
    'K': { F: 'K', C: 'K', K: 'K' },
    // TIME
    'hh:mm:ss': { 'hh:mm:ss': 's', 's': 'hh:mm:ss' },
    'hh:mm': { 'hh:mm': 's', 's': 'hh:mm' },
    'mm:ss': { 'mm:ss': 's', 's': 'mm:ss' },
}

/**
 * Get the unit type matching the userUnits
 *
 * @param {string}      unit        Unit provided
 * @returns {string}                The key matching the userUnits
 */
export const getUnitKey = (unit) => {
    switch (unit) {
        case 'kg':
        case 'lbs':
            return 'weight'
        case 'cm':
        case 'm':
        case 'km':
        case 'in':
        case 'ft':
        case 'mi':
            return 'distance'
        case 'km/h':
        case 'm/min':
        case 'mph':
            return 'speed'
        case 'C':
        case 'F':
        case 'K':
            return 'temperature'
        case 'hh:mm:ss':
        case 'hh:mm':
        case 'mm:ss':
            return 'time'
        default:
            return null
    }
}

/**
 * Transform inches in foot or miles
 *
 * @param {string}      unit        Unit provided (registered in the DB)
 * @param {string}      userUnit    Configured unit in the user (in DB): "mi" or "m"
 * @returns {string}                The final unit which will be displayed
 */
export const getUserUnit = function (unit, userUnit) {
    if (units[userUnit] && units[userUnit][unit]) return units[userUnit][unit]
    else {
        console.warn(`Unit not managed in convert::getUserUnit() : unit [${unit}] / userUnit [${userUnit}]`)
        return units[unit][unit]
    }
}

// Convert an angle to a meaningful term
const roundAngle = (angle) => asDecimal(angle, 1)
export const leftOrRight = (angle, decimals = 1) => {
    const angleRounded = roundAngle(angle)
    return angleRounded === 0 ? 'center' : angle < 0 ? 'left' : 'right'
}
export const frontOrBack = (angle, decimals = 1) => {
    const angleRounded = roundAngle(angle)
    return angleRounded === 0 ? 'center' : angle < 0 ? 'front' : 'back'
}
export const formatAngle = (angle, asAbsolute = true) => roundAngle(asAbsolute ? Math.abs(angle) : angle) + '°'

// Energy convertions
export const wattsToJoules = (watts, decimals = 2) => asDecimal(watts / 100, decimals)
export const wattsToKiloCalories = (watts, decimals = 2) => asDecimal(watts / 100 / 4184, decimals)

// Get color with gradient defined in tailwind.config.js
export const getColor = (value, max) => {
    if (value === null || value === undefined || max === null || max === undefined || max === 0 || max === '') {
        return
    }
    value = value ? Math.abs(value) : 0

    let i = value === 0 ? 0 : Math.floor((value * tailwind.theme.colors.gradient.length) / max)

    if (i >= tailwind.theme.colors.gradient.length) i = tailwind.theme.colors.gradient.length - 1

    return tailwind.theme.colors.gradient[i]
}
