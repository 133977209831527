import { ref } from 'vue'

export default {
    beforeMount(el, binding) {
        let pressTimer = null
        let releaseTimer = null
        const isLongPressActive = ref(false)

        // Function to start long press
        const startLongPress = () => {
            // Start a timer to detect long press
            pressTimer = setTimeout(() => {
                isLongPressActive.value = true
                // Trigger the callback passed as argument (binding.value)
                binding.value(true) // Activate long press after timer
            }, 200) // Adjust this duration for long press as needed
        }

        // Function to stop and reset the long press
        const endLongPress = () => {
            clearTimeout(pressTimer) // Cancel the long press if released before timer

            if (isLongPressActive.value) {
                // Start a timer to revert back after 1 second
                releaseTimer = setTimeout(() => {
                    isLongPressActive.value = false
                    // Trigger the callback again with false
                    binding.value(false) // Revert back after the timer
                }, 1000) // Adjust this duration for reverting
            }
        }

        // Reset if touch is canceled
        const resetLongPress = () => {
            clearTimeout(pressTimer)
            clearTimeout(releaseTimer)
            isLongPressActive.value = false
            binding.value(false) // Revert immediately
        }

        // Add event listeners for touch events
        el.addEventListener('touchstart', startLongPress)
        el.addEventListener('touchend', endLongPress)
        el.addEventListener('touchcancel', resetLongPress)
    },
    beforeUnmount(el) {
        // Clean up the event listeners when the directive is unmounted
        el.removeEventListener('touchstart', startLongPress)
        el.removeEventListener('touchend', endLongPress)
        el.removeEventListener('touchcancel', resetLongPress)
    },
}
