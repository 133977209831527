/**
 * Stores the application routes.
 *
 * All pages require authentication unless `guest: true` is set.
 * If that's the case, only unauthenticated users can access.
 *
 * Meta props available:
 * - title: string = Determines the title of the page.
 * - tabs: boolean = Determines if the tabs should be shown.
 * - guest: boolean = Determines if the page can only be accessed as a guest.
 */

// Redirect for old routes
const sessionChildren = (root) => [
    {
        path: 'map',
        redirect: { name: `sessions.session` },
        meta: { layout: root },
    },
    {
        path: 'trace',
        redirect: { name: `sessions.session` },
        meta: { layout: root },
    },
    {
        path: 'charts',
        redirect: { name: `sessions.session` },
        meta: { layout: root },
    },
    {
        path: 'settings',
        redirect: { name: `sessions.edit` },
        meta: { layout: root },
    },
]

export default [
    /**
     * Statistic routes.
     */
    {
        path: '/',
        name: 'index',
        redirect: { name: 'sessions.index' },
        meta: { layout: 'sessions' },
    },
    /**
     * Debug.
     */
    {
        path: '/debug',
        name: 'debug',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/debug.vue'),
            mobile: () => import('@/pages/debug.vue'),
        },
        meta: { layout: 'settings' },
    },
    /**
     * BEGIN: coaching
     */
    {
        path: '/coaching/sessions',
        name: 'coaching.sessions.index',
        components: {
            secondColumn: () => import('@/pages/coaching/sessions/list.vue'),
            thirdColumn: () => import('@/pages/coaching/sessions/index.vue'),
            mobile: () => import('@/pages/coaching/sessions/list.vue'),
        },
        meta: { layout: 'coaching' },
    },
    {
        path: '/coaching/sessions/:sessionId',
        name: 'coaching.sessions.session',
        components: {
            secondColumn: () => import('@/pages/coaching/sessions/list.vue'),
            thirdColumn: () => import('@/pages/coaching/sessions/session.vue'),
            mobile: () => import('@/pages/coaching/sessions/session.vue'),
        },
        meta: { layout: 'coaching' },
    },
    // END: coaching
    /**
     * BEGIN: sessions
     */
    {
        path: '/sessions',
        name: 'sessions.index',
        components: {
            secondColumn: () => import('@/pages/sessions/list.vue'),
            thirdColumn: () => import('@/pages/sessions/index.vue'),
            mobile: () => import('@/pages/sessions/index.vue'),
        },
        meta: { layout: 'sessions' },
    },
    {
        path: '/sessions/:sessionId',
        name: 'sessions.session',
        components: {
            secondColumn: () => import('@/pages/sessions/list.vue'),
            thirdColumn: () => import('@/pages/sessions/session.vue'),
            mobile: () => import('@/pages/sessions/session.vue'),
        },
        meta: { layout: 'sessions' },
        children: sessionChildren('sessions'),
    },
    {
        path: '/sessions/:sessionId/edit',
        name: `sessions.edit`,
        components: {
            secondColumn: () => import('@/pages/sessions/list.vue'),
            thirdColumn: () => import('@/pages/sessions/edit.vue'),
            mobile: () => import('@/pages/sessions/edit.vue'),
        },
        meta: { layout: 'sessions' },
    },
    // END: sessions
    /**
     * BEGIN: horses
     */
    {
        path: '/horses',
        name: 'horses.index',
        components: {
            secondColumn: () => import('@/pages/horses/list.vue'),
            thirdColumn: () => import('@/pages/horses/horse.vue'),
            mobile: () => import('@/pages/horses/list.vue'),
        },
        meta: { layout: 'horses' },
    },
    {
        path: '/horses/:horse',
        name: 'horses.horse',
        components: {
            secondColumn: () => import('@/pages/horses/list.vue'),
            thirdColumn: () => import('@/pages/horses/horse.vue'),
            mobile: () => import('@/pages/horses/horse.vue'),
        },
        meta: { layout: 'horses' },
    },
    {
        path: '/horses/:horse/edit',
        name: 'horses.edit',
        components: {
            secondColumn: () => import('@/pages/horses/list.vue'),
            thirdColumn: () => import('@/pages/horses/edit.vue'),
            mobile: () => import('@/pages/horses/edit.vue'),
        },
        meta: { layout: 'horses' },
    },
    {
        path: '/horses/:horse/reporting',
        name: 'horses.reporting',
        components: {
            secondColumn: () => import('@/pages/horses/list.vue'),
            thirdColumn: () => import('@/pages/horses/reporting.vue'),
            mobile: () => import('@/pages/horses/reporting.vue'),
        },
        meta: { layout: 'horses' },
    },
    {
        path: '/horses/create',
        name: 'horses.create',
        components: {
            secondColumn: () => import('@/pages/horses/list.vue'),
            thirdColumn: () => import('@/pages/horses/create.vue'),
            mobile: () => import('@/pages/horses/create.vue'),
        },
        meta: { layout: 'horses' },
    },
    // END: horse
    /**
     * BEGIN: teams
     */
    {
        path: '/teams',
        name: 'teams.index',
        components: {
            secondColumn: () => import('@/pages/teams/list.vue'),
            thirdColumn: () => import('@/pages/sessions/session.vue'),
            mobile: () => import('@/pages/teams/list.vue'),
        },
        meta: { layout: 'teams' },
    },

    {
        path: '/teams/sessions',
        name: 'teams.sessions.index',
        components: {
            secondColumn: () => import('@/pages/teams/sessionsList.vue'),
            thirdColumn: () => import('@/pages/sessions/session.vue'),
            mobile: () => import('@/pages/teams/sessionsList.vue'),
        },
        meta: { layout: 'teams' },
    },
    {
        path: '/teams/sessions/:sessionId',
        name: 'teams.session',
        components: {
            secondColumn: () => import('@/pages/teams/sessionsList.vue'),
            thirdColumn: () => import('@/pages/sessions/session.vue'),
            mobile: () => import('@/pages/sessions/session.vue'),
        },
        meta: { layout: 'teams' },
        children: sessionChildren('teams'),
    },
    {
        path: '/teams/create',
        name: 'teams.create',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/teams/create.vue'),
            mobile: () => import('@/pages/teams/create.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/teams/:team/edit',
        name: 'teams.edit',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/teams/edit.vue'),
            mobile: () => import('@/pages/teams/edit.vue'),
        },
        meta: { layout: 'settings' },
    },
    // END: teams
    /**
     * BEGIN: recording sessions
     */
    {
        path: '/record',
        name: 'record.index',
        components: {
            mobile: () => import('@/pages/record/index.vue'),
        },
        meta: { layout: 'record' },
    },
    {
        path: '/record/live',
        name: 'record.live',
        component: () => import('@/pages/record/live.vue'),
        meta: { tabs: false },
    },
    // END: recording sessions
    /**
     * BEGIN: comparison
     */
    {
        path: '/compare',
        name: 'comparison.index',
        redirect: { name: 'comparison.compare', params: { type: 'session' } },
        meta: { layout: 'comparison', hideSecondColumn: true },
    },
    {
        path: '/compare/:type',
        name: 'comparison.compare',
        components: {
            thirdColumn: () => import('@/pages/comparison/index.vue'),
        },
        meta: { layout: 'comparison', hideSecondColumn: true },
    },
    // END: comparison
    /**
     * BEGIN: settings
     */
    {
        path: '/settings',
        name: 'settings.index',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            mobile: () => import('@/pages/settings/listActionCard.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/subscription',
        name: 'settings.subscription',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/settings/subscription.vue'),
            mobile: () => import('@/pages/settings/subscription.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/profile',
        name: 'settings.profile',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/settings/profile.vue'),
            mobile: () => import('@/pages/settings/profile.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/application',
        name: 'settings.application',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/settings/application.vue'),
            mobile: () => import('@/pages/settings/application.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/sensors',
        redirect: { name: 'sensors.index' },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/sensors/detail',
        redirect: { name: 'sensors.sensor' },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/sensors/heartrate',
        redirect: { name: 'sensors.heartrate.index' },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/sensors/heartrate/detail',
        redirect: { name: 'sensors.heartrate.sensor' },
        meta: { layout: 'settings' },
    },
    {
        path: '/sensors',
        name: 'sensors.index',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/sensors/index.vue'),
            mobile: () => import('@/pages/sensors/index.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/sensors/sensor',
        name: 'sensors.sensor',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/sensors/sensor.vue'),
            mobile: () => import('@/pages/sensors/sensor.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/sensors/heartrate',
        name: 'sensors.heartrate.index',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/sensors/heartrate/index.vue'),
            mobile: () => import('@/pages/sensors/heartrate/index.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/sensors/heartrate',
        name: 'sensors.heartrate.sensor',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/sensors/heartrate/sensor.vue'),
            mobile: () => import('@/pages/sensors/heartrate/sensor.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/password',
        name: 'settings.password',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/settings/password.vue'),
            mobile: () => import('@/pages/settings/password.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/legal',
        name: 'settings.legal.index',
        components: {
            mobile: () => import('@/pages/settings/legal/index.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/legal/dependencies',
        name: 'settings.legal.dependencies',
        components: {
            mobile: () => import('@/pages/settings/legal/dependencies.vue'),
        },
        meta: { layout: 'settings' },
    },
    {
        path: '/settings/strideadvisor',
        name: 'settings.strideadvisor.index',
        components: {
            mobile: () => import('@/pages/settings/strideAdvisor.vue'),
        },
        meta: { layout: 'settings' },
    },
    // END: settings
    {
        path: '/contact',
        name: 'contact',
        components: {
            secondColumn: () => import('@/pages/settings/listActionCard.vue'),
            thirdColumn: () => import('@/pages/contact.vue'),
            mobile: () => import('@/pages/contact.vue'),
        },
        meta: { layout: 'settings' },
    },

    /**
     * Tools
     */
    {
        path: '/sync',
        name: 'sync',
        redirect: { name: 'sensors.index' },
        meta: { layout: 'settings', hideSecondColumn: true },
    },
    /**
     * BEGIN: authentication
     */
    {
        path: '/login',
        name: 'auth.login',
        component: () => import('@/pages/auth/login.vue'),
        meta: {
            layout: 'auth',
            tabs: false,
            guest: true,
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        redirect: { name: `auth.login` },
    },
    {
        path: '/password',
        name: 'auth.password',
        redirect: { name: `auth.login` },
    },
    {
        path: '/reset',
        name: 'auth.reset',
        component: () => import('@/pages/auth/reset.vue'),
        meta: {
            layout: 'auth',
            tabs: false,
            guest: true,
            redirect: false,
        },
    },
    // END: authentication
    // Pricing
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('@/pages/pricing/index.vue'),
        meta: {
            layout: 'pricing',
            tabs: false,
            guest: true,
            redirect: false,
            hideSecondColumn: true,
        },
    },
    {
        path: '/settings/subscription/plans',
        name: 'settings.subscription.plans',
        components: {
            mobile: () => import('@/pages/pricing/index.vue'),
            thirdColumn: () => import('@/pages/pricing/index.vue'),
        },
        meta: {
            layout: 'pricing',
            tabs: true,
            guest: false,
            hideSecondColumn: true,
        },
    },
    // END: pricing
    // Protocols
    {
        path: '/protocols',
        name: 'protocols.index',
        components: {
            secondColumn: () => import('@/pages/protocols/list.vue'),
            thirdColumn: () => import('@/pages/protocols/index.vue'),
            mobile: () => import('@/pages/protocols/list.vue'),
        },
        meta: { layout: 'protocols' },
    },
    {
        path: '/protocols/:protocol',
        name: 'protocols.protocol',
        components: {
            secondColumn: () => import('@/pages/protocols/list.vue'),
            thirdColumn: () => import('@/pages/protocols/index.vue'),
            mobile: () => import('@/pages/protocols/index.vue'),
        },
        meta: { layout: 'protocols' },
    },
    // {
    //     path: '/protocols/create',
    //     name: 'protocols.create',
    //     components: {
    //         secondColumn: () => import('@/pages/protocols/list.vue'),
    //         thirdColumn: () => import('@/pages/protocols/create.vue'),
    //         mobile: () => import('@/pages/protocols/create.vue'),
    //     },
    //     meta: { layout: 'protocols' },
    // },
    /**
     * Error
     */
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import('@/pages/404.vue'),
        meta: { tabs: false },
    },
]
