<template>
    <div
        ref="element"
        :class="{ 'bg-dark-lighter': hasFocus }"
        class="scrollableContent p-3 rounded-lg bg-dark-light flex items-center w-full"
    >
        <div class="flex flex-col flex-grow">
            <div class="uppercase text-xxs tracking-widest font-bold" @click="$refs.select.focus()">
                <span> {{ label }} <BadgePremium v-if="premium" class="normal-case" /> </span>
                <span v-if="formatDisplayedValue.unit"> ({{ formatDisplayedValue.unit }})</span>
                <RequiredText v-if="required"> *</RequiredText>
            </div>
            <Blur class="flex flex-col" :blurred="!$store.state.auth?.user?.subscription?.active && premium">
                <select
                    ref="select"
                    class="text-white bg-transparent border-none w-full pr-8 overflow-y-hidden overflow-ellipsis focus:ring-0 focus:outline-none outline-none h-6 p-0"
                    :value="formatDisplayedValue.value"
                    @input="formatRealValue($event.target.value)"
                    @focus="hasFocus = true"
                    @blur="hasFocus = false"
                >
                    <option class="text-gray-500" value="" disabled selected hidden>
                        {{ $t('form.select-option') }}
                    </option>
                    <slot />
                </select>
            </Blur>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { convertValueUnit, getUserUnit } from '@/lib/helpers/convert'
import RequiredText from '@/components/form/RequiredText.vue'
import BadgePremium from '@/components/BadgePremium.vue'
import Blur from '@/components/Blur.vue'

const props = defineProps({
    modelValue: { type: [String, Number, Date], default: '' },
    decimals: { type: Number, default: undefined },
    unit: { type: String, default: null },
    userUnit: { type: String, default: null },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    premium: { type: Boolean, default: false },
})
const emit = defineEmits(['update:modelValue'])

const hasFocus = ref(false)
const select = ref(undefined)
const element = ref(undefined)

const userUnit = computed(() => (props.unit ? getUserUnit(props.unit, props.userUnit) : null))

const formatDisplayedValue = computed(() =>
    convertValueUnit(props.modelValue, props.unit, userUnit.value, props.decimals, true)
)

const formatRealValue = async (value) =>
    emit('update:modelValue', convertValueUnit(value, userUnit.value, props.unit, props.decimals, true).value)
</script>
