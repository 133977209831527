import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/lib/API/client'
import fetcher from '@/lib/API/fetcher'
import useSWRV from 'swrv'
import { mutate } from 'swrv'
import { format } from '@/lib/helpers/time'
import * as Sentry from '@sentry/vue'
import useCalendar from '@/lib/hooks/calendar'
import { useRoute, useRouter } from 'vue-router'

// --------------------------------------------------------------------------------
// Todo: refacto ! Not here ! No dependencies in this file !!

import useMetas from '@/lib/hooks/use-metas'
import { getSessionName } from '@/lib/trash'
import { capitalizeFirstLetter } from '@/lib/helpers/text'
import { shouldTextBeBlack } from '@/lib/helpers/color'
import tailwind from '@/lib/tailwind'

// --------------------------------------------------------------------------------
// global state, created in module scope
const {
    gotoToday,
    incrementPeriod,
    decrementPeriod,
    period,
    dateSelected,
    numberOfDays,
    firstCalendarDay,
    lastCalendarDay,
    calendarDateFrom,
    calendarDateTo,
    getPreviousCalendarRange,
} = useCalendar()

const horseId = ref('')
const riderId = ref('')
const categoryName = ref('')
const sessionType = ref('')
const sessionDiscipline = ref('')

const eventsUrl = computed(() => `/user/events?from=${calendarDateFrom.value}&to=${calendarDateTo.value}`)

const clickedEvent = ref(null)

export default () => {
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    // const protocolId = computed(() => route.params.protocol)
    // const protocolId = computed(() => route.params.protocol)
    // onMounted(() => {
    if (route.query.period) {
        period.value = route.query.period
    }
    if (route.query.date) {
        dateSelected.value = route.query.date
    }
    // })

    // local state, created per-component
    /**
     *
     * use* methods
     *
     **/

    const { data: events, error } = useSWRV(
        () =>
            // 'calendarDateFrom' and 'calendarDateTo' parameters are mandatory
            calendarDateFrom.value && calendarDateTo.value && eventsUrl.value,
        fetcher,
        {
            refreshInterval: 2000,
            revalidateOnFocus: false,
        }
    )

    watch(
        [period, dateSelected],
        () => {
            router.replace({
                query: { ...route.query, period: period.value, date: format(dateSelected.value, 'YYYY-MM-DD') },
            })

            const range = getPreviousCalendarRange(dateSelected.value)
            prefetchRange(format(range[0], 'YYYY-MM-DD'), format(range[1], 'YYYY-MM-DD'))
        },
        { immediate: true }
    )

    function prefetchRange(nextFrom, nextTo) {
        const url = `/user/events?from=${nextFrom}&to=${nextTo}`
        mutate(url, fetcher(url))
        // the second parameter is a Promise
        // SWRV will use the result when it resolves
    }

    /**
     *
     * mutate* methods
     *
     **/
    const mutateEvents = () => {
        mutate('/user/events', fetcher(eventsUrl.value))
    }

    // const mutateEvent = (eventId, data) => {
    //     mutate(`/events/${eventId}`, data ? data : fetcher(`/events/${eventId}`))
    // }

    /**
     *
     * API methods
     *
     **/
    const createEvent = (data) => {
        return axios.post('/events', data).then((response) => {
            // mutateEvent(response.data.id, response.data)
            mutateEvents()
            return response.data
        })
    }

    const updateEvent = (eventId, data) => {
        return axios.put(`/events/${eventId}`, data).then((response) => {
            // mutateEvent(eventId, response.data)
            mutateEvents()
            return response.data
        })
    }

    const deleteEvent = (eventId) => {
        return axios.delete(`/events/${eventId}`).then((response) => {
            // mutateEvent(eventId, {})
            mutateEvents()
            return response.data
        })
    }

    const isError = computed(() => !!error?.value)
    const isReady = computed(() => events?.value && !isError.value)
    const isLoading = computed(() => !!(!isReady.value && !isError.value))

    const { getEventTypeByKey } = useMetas() // Todo: move from here too !

    /**
     *
     * methods
     *
     **/

    const formatedEventsBeforeFiltering = computed(() => {
        return events.value?.map((event) => {
            let resultEvent = Object.assign({}, event)

            //fix if not end_at
            if (!resultEvent.end_at) {
                resultEvent.end_at = resultEvent.start_at
            }

            //Default colors if not in the meta event_type list
            resultEvent.colors = {
                background: tailwind.theme.colors.dark.lightest,
                text: tailwind.theme.colors.text.darker,
            }

            let eventType = {}
            if (event?.model) {
                eventType = getEventTypeByKey(event?.model, 'subcategory_name')
            } else if (event.event_type_id) {
                eventType = getEventTypeByKey(event.event_type_id, 'event_type_id')
            }

            if (event.session) {
                resultEvent.title = getSessionName(event.session)
            } else {
                if (eventType?.meta_key) {
                    // Title via meta from back-office (exp: horse state-> injured, ... )
                    //because the title is a translated version of the meta
                    resultEvent.meta = resultEvent.title
                    try {
                        resultEvent.title = capitalizeFirstLetter(
                            t(`meta.${eventType?.meta_key}.${resultEvent?.title?.toLowerCase()?.replaceAll(' ', '-')}`)
                        )
                    } catch (error) {
                        console.log(`ERROR replaceAll ${resultEvent?.id} : ${resultEvent?.title}`)
                        Sentry.captureMessage(error)
                    }
                } else if (event.title === null) {
                    // Implicit title (osteo, vent, dentist, ...)
                    resultEvent.title = eventType?.subcategory_name
                        ? capitalizeFirstLetter(t('event.subcategory.' + eventType?.subcategory_name))
                        : ''
                }
            }

            const rider = event.rider ?? event?.[event?.model]?.rider
            const horse = event.horse ?? event?.[event?.model]?.horse
            resultEvent.icon = eventType?.icon ?? ''
            resultEvent.avatar = horse?.avatar ?? null
            //fields bellow are used for filtering
            resultEvent.horseId = horse?.id ?? null
            resultEvent.riderId = rider?.id ?? null
            resultEvent.categoryName = eventType?.category_name ?? null
            resultEvent.sessionType = event?.[event?.model]?.type ?? null
            resultEvent.sessionDiscipline = event?.[event?.model]?.discipline ?? null

            if (eventType?.text_color) {
                resultEvent.colors.text = eventType.text_color
            }
            if (eventType?.background_color) {
                resultEvent.colors.background = eventType.background_color
            }
            if (event.color) {
                resultEvent.colors.background = event.color
                resultEvent.colors.text = shouldTextBeBlack(event.color)
                    ? tailwind.theme.colors.dark.base
                    : tailwind.theme.colors.text.darker
            }
            delete resultEvent.color //to remove uncessary field

            return resultEvent
        })
    })

    const formatedEvents = computed(() => {
        return formatedEventsBeforeFiltering.value?.filter((ev) => {
            if (horseId.value) {
                if (ev.horseId !== parseInt(horseId.value)) return false
            }
            if (riderId.value) {
                if (ev.riderId !== parseInt(riderId.value)) return false
            }
            if (categoryName.value) {
                if (ev.categoryName !== categoryName.value) return false
            }
            if (sessionType.value) {
                if (ev.sessionType !== sessionType.value) return false
            }
            if (sessionDiscipline.value) {
                if (ev.sessionDiscipline !== sessionDiscipline.value) return false
            }
            return true
        })
    })

    const getEmptyEvent = (day = new Date(), horseId = null) => {
        return {
            title: '',
            start_at: format(day, 'YYYY-MM-DD'),
            end_at: format(day, 'YYYY-MM-DD'),
            description: '',
            meta: null, //because the title is a translated version of the meta
            icon: null,
            avatar: null,
            colors: { text: null, background: null },
            horse: {
                id: horseId,
            },
        }
    }

    return {
        //data
        clickedEvent,
        events,
        formatedEvents,
        isError,
        isReady,
        isLoading,
        //methods
        updateEvent,
        deleteEvent,
        createEvent,
        getEmptyEvent,
        //ref parameter
        horseId,
        riderId,
        categoryName,
        sessionType,
        sessionDiscipline,
        gotoToday,
        dateSelected,
        numberOfDays,
        incrementPeriod,
        decrementPeriod,
        firstCalendarDay,
    }
}
