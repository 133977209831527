<template>
    <RightIcon style="transform: rotate(180deg)" />
</template>

<script>
import RightIcon from '@/components/icons/RightIcon.vue'
export default {
    components: { RightIcon },
}
</script>
