import { createStore } from 'vuex'
import auth from './modules/auth'
import device from './modules/device'
import geolocation from './modules/geolocation'
import other from './modules/other'
const modules = { auth, device, geolocation, other }
const store = createStore({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: !import.meta.env.PROD,
})

export default store
