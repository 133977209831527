import { reactive } from 'vue'
import axios from '@/lib/API/client'
import Memory from '@/lib/memory'
import { concatenateUint8Arrays } from './common'
export default async ({
    uploaded = undefined,
    city = undefined,
    filename = undefined,
    uuid = undefined,
    language = '',
    selected = true,
    syncedPercent = 0,
    date = undefined,
    weekNumber = '',
    iTOW = '',
    size = '',
    firmware = '',
    index = '',
    startPage = '',
    numberOfPages = '',
    latitude = '',
    longitude = '',
    discipline = '',
    horseId = undefined,
    riderId = undefined,
    goal = '',
    goal_value = '',
    uploadedPercent = 0,
}) => {
    if (!uuid) throw new Error('File must be linked to a uuid')

    // ************************************************
    // ****************  properties ****************
    // ***********************************************
    const heartrateStream = []
    const pagesSynced = []
    const properties = reactive({
        uploaded,
        city,
        filename,
        uuid,
        language,
        selected,
        syncedPercent,
        date,
        weekNumber,
        iTOW,
        size,
        firmware,
        index,
        startPage,
        numberOfPages,
        latitude,
        longitude,
        discipline,
        horseId,
        riderId,
        goal,
        goal_value,
        uploadedPercent,
    })

    // ************************************************
    // ****************  methods ****************
    // ***********************************************
    const getDateString = (type) => {
        let date = getDate()
        let YYYY = date.getFullYear().toString()
        let MM = (date.getMonth() + 1).toString()
        if (MM.length == 1) MM = '0' + MM
        let DD = date.getDate().toString()
        if (DD.length == 1) DD = '0' + DD
        let hh = date.getHours().toString()
        if (hh.length == 1) hh = '0' + hh
        let mm = date.getMinutes().toString()
        if (mm.length == 1) mm = '0' + mm
        let ss = date.getSeconds().toString()
        if (ss.length == 1) ss = '0' + ss
        if (type == 1) return YYYY + '/' + MM + '/' + DD + ' - ' + hh + ':' + mm + ':' + ss
        else if (type == 2) return YYYY + MM + DD + '_' + hh + mm // used for the filename https://github.com/Alogo-Analysis/mobile-app/issues/208#issuecomment-851439094
    }
    const getSizeString = () => {
        const size = properties.size
        let sizeStr = size.toString() + ' Bytes'
        if (size == 1) sizeStr = size + ' Byte'
        else if (size <= 1000) sizeStr = size + ' Bytes'
        else if (size > 1000 * 1000) sizeStr = (size / 1000.0 / 1000.0).toFixed(2) + ' MB'
        else if (size > 1000) sizeStr = (size / 1000.0).toFixed(2) + ' KB'
        return sizeStr
    }

    const getFilename = (extension = null) => {
        return 'D' + properties.index + '_' + properties.uuid.substring(0, 8) + (extension ? `_${extension}` : '')
    }

    const getDatFilename = () => {
        return getFilename() + '_' + getDateString(2) + '.dat'
    }

    const getCity = async () => {
        if (!properties.latitude || !properties.longitude) return ''
        const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${properties.latitude}&longitude=${properties.longitude}&localityLanguage=${properties.language}`
        let response = {}
        try {
            response = await axios.get(url, {
                timeout: 1000,
            })
        } catch (error) {}
        return (
            response?.data?.locality || `${properties.latitude.toPrecision(6)}, ${properties.longitude.toPrecision(6)}`
        )
    }

    const isUploaded = async () => {
        const filenameCleaned = properties.filename.split('.')[0] //remove the extension
        // console.log('newProperties filenameCleaned', filenameCleaned)
        let response = {}
        try {
            response = await axios.post(
                'uploads/exist',
                { filename: filenameCleaned },
                {
                    timeout: 5000,
                }
            )
        } catch (error) {}
        return !!response?.data
    }

    const upload = async () => {
        //upload the file to the server
        const formData = new FormData()
        // https://www.measurethat.net/Benchmarks/Show/7536/0/dataview-vs-uint8array-by-bytes
        // "set" is slower than "by byte"
        const size = pagesSynced.reduce((accumulator, page) => accumulator + page.length, 0)
        let buf = new ArrayBuffer(size)
        let data = new Uint8Array(buf)
        let j = 0
        for (const key in pagesSynced) {
            const pageSize = pagesSynced[key].length
            for (let i = 0; i < pageSize; i++) {
                data[j] = pagesSynced[key][i]
                j++
            }
        }

        formData.append(
            'source',
            new Blob([data], {
                type: 'application/octet-stream',
            }),
            properties.filename
        )
        formData.append('size', properties.size)
        formData.append('isodate', getISODateString())
        formData.append('latitude', properties.latitude)
        formData.append('longitude', properties.longitude)
        formData.append('firmware_version', properties.firmware)
        formData.append('serial_id', properties.uuid || 'unknown')
        if (
            properties.discipline !== undefined &&
            properties.discipline !== null &&
            properties.discipline !== '' &&
            properties.discipline !== 'default'
        ) {
            formData.append('discipline', properties.discipline)
        }
        if (properties.horseId !== undefined && properties.horseId !== null && properties.horseId !== '') {
            formData.append('horse_id', properties.horseId)
        }
        if (properties.riderId !== undefined && properties.riderId !== null && properties.riderId !== '') {
            formData.append('rider_id', properties.riderId)
        }
        if (properties.goal !== undefined && properties.goal !== null) {
            formData.append('goal', properties.goal)
        }
        if (properties.goal_value !== undefined && properties.goal_value !== null) {
            formData.append('goal_value', properties.goal_value)
        }
        //needed if not yet pre-load
        const newHeartrateStream = await Memory.get(getFilename('hr'))
        if (newHeartrateStream?.length > 0) {
            formData.append('heartrateStream', JSON.stringify(newHeartrateStream))
        } else if (properties.heartrateStream?.length > 0) {
            //todo to be remove later on ; leave this line for retro compatibility
            formData.append('heartrateStream', JSON.stringify(properties.heartrateStream))
        }
        // ! not possible right now because the upload will be very large
        // if (properties?.ecgStream?.length > 0) {
        //     formData.append('ecgStream', JSON.stringify(properties.ecgStream))
        // }
        // we only use the light format so this should not change
        formData.append('light', 1)

        await axios.post('/uploads', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                properties.uploadedPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            },
        })

        properties.uploaded = true
        properties.selected = false
    }

    const getEstimatedDuration = () => {
        return Math.round(properties.size / 3791)
    }
    const getISODateString = () => {
        return getDate().toISOString()
    }
    const getLocalDateString = () => {
        return getDate().toLocaleDateString(properties.language)
    }
    const getDate = () => {
        if (properties.date === undefined) {
            properties.date = new Date(
                properties.weekNumber * 7 * 24 * 3600 * 1000 +
                    properties.iTOW +
                    (new Date(1980, 0, 6, 0, 0, 0, 0).getTime() - new Date(0).getTime()) -
                    new Date(0).getTimezoneOffset() * 60000
            )
        } else if (typeof properties.date === 'string' || typeof properties.date === 'number') {
            properties.date = new Date(properties.date)
        }
        return properties.date
    }
    const getWeek = (date) => {
        const onejan = new Date(date.getFullYear(), 0, 1)
        return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7)
    }

    const save = async (newProperties = {}, others = {}) => {
        if (others?.heartrateStream.length > 0) {
            // line after not necessary because it is loaded during upload
            // heartrateStream = others?.heartrateStream
            await Memory.set(getFilename('hr'), others.heartrateStream)
        }
        Object.assign(properties, newProperties) // keep reactivity
        // console.log('going to save', properties.filename, { ...properties })
        await Memory.set(getFilename(), { ...properties })
    }

    // ************************************************
    // **************** instanciate properties ****************
    // ***********************************************
    if (filename === undefined) {
        properties.filename = await getDatFilename()
    }
    //should be done after the getFilename method is called
    const newProperties = await Memory.get(getFilename())
    // console.log('newProperties', newProperties, properties.filename)
    if (newProperties) {
        delete newProperties['ecgStream']
        Object.assign(properties, newProperties) // keep reactivity
    }
    // use lines after only if needed to pre-load the hr values / already done directly in the upload function
    // const newHeartrateStream = await Memory.get(getFilename('hr'))
    // if (newHeartrateStream.length > 0) {
    //     heartrateStream = newHeartrateStream
    // }
    if (city === undefined && properties.city === undefined) {
        properties.city = await getCity()
    }

    //this checkup should be done after the getFilename
    properties.uploaded = await isUploaded()
    if (properties.uploaded) {
        properties.selected = false
    }

    return {
        //properties
        pagesSynced,
        properties,
        //methods
        getDateString,
        getSizeString,
        getFilename,
        getDatFilename,
        getCity,
        isUploaded,
        upload,
        getEstimatedDuration,
        getISODateString,
        getLocalDateString,
        getDate,
        getWeek,
        save,
    }
}
