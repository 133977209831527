import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import isBetween from 'dayjs/plugin/isBetween'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import _isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'

// Day.JS must be extended with
// the calendar plugin.
dayjs.extend(calendar)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)
const config = {
    thresholds: [
        { l: 'd', r: 1 },
        { l: 'dd', r: 29, d: 'day' },
        { l: 'M', r: 1 },
        { l: 'MM', r: 11, d: 'month' },
        { l: 'y', r: 1 },
        { l: 'yy', d: 'year' },
    ],
}
dayjs.extend(relativeTime, config)
dayjs.extend(_isToday)
dayjs.extend(localeData)
dayjs.extend(weekday)

/**
 * Time class helper to perform
 * operations with time.
 */
export default class Time {
    /**
     * Stores the current dayjs
     * instance to perform the time
     * operations on the class.
     */
    time = undefined

    /**
     * Creates a new instance of
     * the Time class.
     */
    constructor(time = Date.now()) {
        this.time = dayjs(time)
    }

    getTime() {
        return this.time
    }

    /**
     * Determines if the given date is in the past.
     */
    isPast(time = Date.now()) {
        return this.time.isBefore(time)
    }

    /**
     * Returns a human readable way to
     * display the time as calendar time.
     */
    human() {
        return this.time.calendar()
    }

    /**
     * Returns the time from now.
     */
    fromNow() {
        return this.time.fromNow()
    }

    from(dateFrom) {
        return this.time.from(dateFrom)
    }

    diff(dateTo, unit) {
        return this.time.diff(dateTo, unit)
    }

    /**
     * Formats the time given the
     * format as a parameter.
     */
    format(as = 'DD/MM/YY') {
        return this.time.format(as)
    }

    /**
     * Adds a value to the time.
     */
    add(value, unit) {
        return this.time.add(value, unit)
    }

    isToday() {
        return this.time.isToday()
    }

    toLocaleString(locale = 'en-US', options = {}) {
        let defaultOptions = { dateStyle: 'medium', timeStyle: 'short' }
        return new Date(this.time.toDate()).toLocaleString(locale, { ...defaultOptions, ...options }) // default is Saturday, September 17, 2016
    }

    toLocaleDateString(locale = 'en-US', options = {}) {
        let defaultOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return new Date(this.time.toDate()).toLocaleDateString(locale, { ...defaultOptions, ...options }) // default is Saturday, September 17, 2016
    }

    toLocaleTimeString(locale = 'en-US', options = {}) {
        let defaultOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(this.time.toDate()).toLocaleTimeString(locale, { ...defaultOptions, ...options }) // default is Saturday, September 17, 2016
    }
}

/**
 * Small utility to convert a time into human
 * readable time.
 */
export const humanTime = (time) => new Time(time).human()

/**
 * Small utility to return the time from now.
 */
export const fromNow = (time) => new Time(time).fromNow()

export const from = (time, timeFrom) => new Time(time).from(timeFrom)

export const diff = (dateFrom, dateTo, unit) => new Time(dateFrom).diff(dateTo, unit)

/**
 * Indicates whether the date is today or not.
 */
export const isToday = (timeOrDay, isDay = true) => {
    const date = new Time(timeOrDay).getTime()
    return isDay ? new Date().toDateString() === date.toDate().toDateString() : date.isToday()
}

export const isSameDay = (time, dateToCompare) => {
    const date = new Time(time).getTime()
    return date.isSame(dateToCompare, 'day')
}

/**
 * Returns a string with the phase of the day.
 */
export const phaseOfDay = (time) => {
    const hour = new Date(time).getHours()

    if (hour >= 5 && hour < 12) return 'morning'
    else if (hour >= 12 && hour < 17) return 'afternoon'
    else if (hour >= 17 && hour < 21) return 'evening'
    return 'night'
}

/**
 * Formats the given time.
 */
export const format = (time, format) => new Time(time).format(format)

/**
 * Add day, week, mont, ... to a given time.
 */
export const add = (time, value, unit) => new Time(time).add(value, unit)

/**
 * Transform inches in foot or miles
 *
 * @param {string}    locale     Locale for format
 * @param {Date}      dateStart  First date of range
 * @param {Date}      dateEnd    Second date of range
 * @param {Object}    options    Options to format
 * @returns {string}             Result of the range
 */
export const formatRange = (
    locale,
    dateStart,
    dateEnd,
    options = {
        year: 'numeric', // long, numeric, short
        month: 'short',
        day: 'numeric',
    }
) => {
    const dateTimeFormat = new Intl.DateTimeFormat(locale, options)

    return dateTimeFormat.formatRange(dateStart, dateEnd).replace('–', ' – ')
}

/**
 * Format the date to the locale format
 * e.g. Saturday, September 17, 2016
 * The locale provided is on format 'en-US' for Date object
 * Instead of locale of the Time class is 'en' for english, 'fr' for french, 'fr-ch' for swiss
 */
export const toLocaleString = (time, locale, options) => new Time(time).toLocaleString(locale, options)

export const toLocaleDateString = (time, locale, options) => new Time(time).toLocaleDateString(locale, options)

export const toLocaleTimeString = (time, locale, options) => new Time(time).toLocaleTimeString(locale, options)

export const toLocaleTimeStringByAddingSeconds = (time, locale, seconds, options) =>
    toLocaleTimeString(new Time(time).add(seconds, 'second'), locale, options)

export const now = () => new Time()

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const getAge = (birthDate) => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)
