import { useBanner } from './lib'

const BannerPlugin = {
    install: (app, options) => {
        let instance = useBanner(options)
        app.config.globalProperties.$banner = instance
        app.provide('banner', instance)
    },
}

export default BannerPlugin
