<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <g transform="translate(6 8.722)">
            <path
                d="M 10.456 11.016 L 5.005 15.716"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 5.788 9.098 C 5.788 7.591 7.009 6.369 8.516 6.369 C 10.023 6.369 11.245 7.59 11.245 9.097 C 11.245 10.604 10.024 11.826 8.517 11.826 C 7.01 11.826 5.788 10.605 5.788 9.098 Z"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linejoin="round"
            />
            <path
                d="M 5.005 15.716 L 10.942 17.944 M 26.625 11.849 L 26.625 17.437"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linejoin="round"
            />
            <path
                d="M 21.376 11.849 L 26.625 11.849"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 6.808 16.393 L 6.819 22.735 M 21.376 17.099 L 21.376 22.686"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linejoin="round"
            />
            <path
                d="M 21.376 11.849 L 21.376 17.099"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 8.517 6.369 L 19.696 6.368 L 19.696 12.376 C 16.598 12.376 13.686 11.577 11.155 10.175"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 16.674 9.372 C 16.654 8.286 17.223 7.274 18.16 6.725 C 19.098 6.176 20.258 6.177 21.196 6.726 C 22.133 7.275 22.701 8.287 22.681 9.373 C 22.651 11.01 21.315 12.322 19.677 12.322 C 18.039 12.321 16.704 11.009 16.674 9.372 Z"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 18.521 6.184 C 18.519 8.063 19.374 9.84 20.842 11.012 L 22.681 9.372 L 22.681 2.024 C 22.681 0.906 23.587 0 24.705 0 C 21.29 0 18.521 2.769 18.521 6.184 Z"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 26.632 0 L 24.705 0"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 25.571 0 L 25.571 4.431 L 26.139 4.431 L 27.114 0 Z"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-miterlimit="10"
            />
            <path
                d="M 10.882 10.456 L 8.517 13.908 L 6.151 10.456"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 10.882 10.456 L 6.813 16.393"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 5.788 9.097 C 5.788 7.59 7.01 6.368 8.517 6.368 C 10.023 6.368 11.245 7.59 11.245 9.097 C 11.245 10.604 10.023 11.825 8.517 11.825 C 7.01 11.825 5.788 10.604 5.788 9.097 Z"
                fill="currentColor"
                stroke-width="2"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M 6.927 7.465 L 5.457 7.465 C 3.936 7.465 2.703 8.698 2.703 10.219 L 2.703 15.194 M 0 17.898 C 1.493 17.897 2.703 16.687 2.703 15.194"
                fill="transparent"
                stroke-width="2"
                stroke="currentColor"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>
