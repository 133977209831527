<template>
    <div v-if="!absoluteLoader" v-bind="$attrs" class="w-full">
        <div v-if="when" class="flex items-center justify-center">
            <div class="relative" :style="{ height: noHeight ? undefined : `${size}px`, width: `${size}px` }">
                <div class="absolute inset-0 flex items-center justify-center">
                    <Loader :size="size" />
                </div>
            </div>
        </div>
        <div v-else class="w-full h-full">
            <slot />
        </div>
    </div>
    <div v-else class="relative w-full h-full" v-bind="$attrs">
        <div v-if="when" class="absolute inset-0 flex items-center justify-center bg-dark-light">
            <Loader :size="size" />
        </div>
        <slot />
    </div>
</template>

<script>
// use normal <script> to declare options
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import Loader from '@/components/Loader.vue'
const props = defineProps({
    when: { type: Boolean, default: true },
    size: { type: Number, default: 75 },
    noHeight: { type: Boolean, default: false },
    absoluteLoader: { type: Boolean, default: false },
})
</script>
