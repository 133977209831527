<template>
    <div v-if="closable && !modelValue" class="flex justify-end -mt-2 -mr-2">
        <XLightIcon class="h-3 w-3 ml-3 cursor-pointer text-white" @click="close" />
    </div>

    <div
        class="relative w-full h-full flex-1 flex items-center justify-center text-center"
        @click.stop="onClick"
        @mouseover="showHide(true)"
        @mouseleave="showHide(false)"
    >
        <slot />

        <InformationCircleIcon v-if="isInfo" class="w-4 h-4" :class="iconClasses" />
        <QuestionMarkCircleIcon
            v-else
            class="h-4 w-4 ml-1"
            :class="`${iconClasses} ${link ? 'cursor-pointer' : ''}`"
            @click="openLink"
        />

        <transition v-if="$slots.tooltip" name="fade">
            <div
                v-show="isVisible"
                :class="bottomPosition ? 'bottom' : 'top'"
                class="absolute w-full text-sm text-white p-2 rounded-lg bg-dark-lighter items-center justify-center text-center z-50"
            >
                <span :class="bottomPosition ? 'triangle-bottom' : 'triangle-top'"></span>
                <slot name="tooltip"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref } from 'vue'

import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'

import XLightIcon from '@/components/icons/XLightIcon.vue'

export default {
    components: { InformationCircleIcon, QuestionMarkCircleIcon, XLightIcon },
    inheritAttrs: false,
    emits: ['close'],
    props: {
        duration: { type: Number, default: 3000 },
        fadeOut: { type: Number, default: 500 },
        bottomPosition: { type: Boolean, default: false },
        closable: { type: Boolean, default: false },
        modelValue: { type: Boolean, default: false },
        displayIcon: { type: Boolean, default: true },
        isInfo: { type: Boolean, default: false },
        link: { type: String, default: null },
        iconClasses: { type: String, default: '' },
    },
    setup(props, { emit }) {
        const isVisible = ref(false)

        let timerOnHide
        const showHide = (show) => {
            if (timerOnHide) {
                clearTimeout(timerOnHide)
            }
            if (show) {
                isVisible.value = true
            } else {
                timerOnHide = setTimeout(() => {
                    isVisible.value = false
                }, props.fadeOut)
            }
        }

        let timerOnClick
        const onClick = () => {
            showHide(true)
            if (timerOnClick) {
                clearTimeout(timerOnClick)
            }
            timerOnClick = setTimeout(() => showHide(false), props.duration)
        }

        const close = () => emit('close')

        const openLink = () => (props.link ? window.open(props.link, '_blank') : false)

        return { showHide, onClick, close, openLink, isVisible }
    },
}
</script>
