<template>
    <div class="w-full">
        <nav :class="pills ? 'flex flex-wrap' : 'w-full flex bg-dark-lighter rounded-lg'" aria-label="Tabs">
            <div
                v-for="(header, index) in Object.keys(headers)"
                :key="'header-' + index"
                :class="[
                    pills
                        ? modelValue == header
                            ? 'bg-dark-lightest text-brand-base'
                            : 'text-gray-500 hover:bg-dark-lighter'
                        : '',
                    headers[header]?.disable && 'disabled',
                    pills && 'mx-1 px-3 py-2 font-medium text-sm rounded-md cursor-pointer',
                    !pills && 'h-full flex-grow flex',
                ]"
                :aria-current="modelValue == header ? 'page' : undefined"
                @click.prevent.stop="$emit('update:modelValue', header)"
            >
                <div v-if="pills">{{ headers[header]?.text }}</div>
                <div
                    v-else
                    :class="[
                        modelValue == header ? 'bg-dark-lightest' : 'hover:scale-105 transition duration-200',
                        index !== 0 && '-ml-1',
                        index !== Object.keys(headers).length - 1 && '-mr-1',
                        headers[header]?.disable && 'disabled',
                        'py-2 flex-grow flex items-center justify-center cursor-pointer m-1 rounded-lg',
                    ]"
                >
                    <h4>{{ headers[header]?.text }}</h4>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { watch } from 'vue'

const props = defineProps({
    /**
     * Headers of the Tabs.
     *
     * Example:
     * { sample1: 'Sample 1', sample2: 'Sample 2' }
     */
    headers: { type: Object, default: () => ({}) },

    /**
     * Classics tabs or pills tabs (like separated buttons)
     */
    pills: { type: Boolean, default: false },

    /**
     * Determines the initially selected tab by slot name.
     */
    modelValue: { type: [String, Number], default: undefined },
})

const emit = defineEmits(['update:modelValue'])

watch(
    () => props.headers,
    () => {
        if (props.headers?.[props.modelValue]?.disable) {
            let newHeaderSelected = null
            let headerCount = 0
            const headersArray = Object.entries(props.headers)

            while (newHeaderSelected === null && headerCount < headersArray.length) {
                if (!headersArray[headerCount][1].disable) {
                    newHeaderSelected = headersArray[headerCount][0]
                    emit('update:modelValue', newHeaderSelected)

                    break
                }
                headerCount++
            }
        }
    },
    { deep: true }
)
</script>
