<template>
    <div class="relative w-full h-full">
        <div
            v-bind="$attrs"
            :inert="blurred"
            class="w-full h-full"
            :class="{ 'blur-sm cursor-not-allowed pointer-events-none opacity-25': blurred }"
        >
            <slot />
        </div>

        <div v-if="blurred" class="absolute top-0 h-full w-full">
            <div class="flex flex-col space-y-1 h-full w-full">
                <div
                    v-if="title || $slots.title"
                    class="flex grow h-full m-2 text-center items-center justify-center align-middle"
                >
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>

                <hr
                    v-if="
                        (title || $slots.title) &&
                        (imagePath || $slots.image || text || $slots.text || buttonText || $slots.button)
                    "
                    class="rounded-lg m-2 border-dark-lightest"
                />

                <div
                    v-if="imagePath || $slots.image || text || $slots.text || buttonText || $slots.button"
                    class="flex-none h-26 p-2 w-full"
                >
                    <div class="flex p-2 rounded-lg backdrop-blur-sm bg-white/40 w-full h-full">
                        <div
                            v-if="imagePath || $slots.image"
                            class="opacity-100 flex flex-none w-20 h-20 p-1 text-center items-center justify-center align-middle"
                        >
                            <slot name="image">
                                <img
                                    :src="imagePath"
                                    class="rounded-lg cursor-pointer hover:scale-105 transition duration-200"
                                    alt="Mockup"
                                    @click="popupOpen = true"
                                />
                                <Popup v-model:is-open="popupOpen">
                                    <img :src="imagePath" class="rounded-lg" alt="Mockup" />
                                </Popup>
                            </slot>
                        </div>
                        <div class="opacity-100 flex h-full grow">
                            <div
                                class="flex flex-col space-y-2 mx-4 w-full text-xs text-center items-center justify-center align-middle"
                            >
                                <div v-if="text || $slots.text" class="w-full">
                                    <slot name="text">
                                        {{ text }}
                                    </slot>
                                </div>
                                <div v-if="buttonText || $slots.button" class="w-full">
                                    <slot name="button">
                                        <Button
                                            small
                                            class="w-full"
                                            :action="buttonAction ? buttonAction : defaultButtonAction"
                                        >
                                            {{ buttonText }}
                                        </Button>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import Button from '@/components/buttons/Primary.vue'
import Popup from '@/components/Popup.vue'

const props = defineProps({
    blurred: { type: Boolean, default: false },
    title: { type: String, default: '' },
    imagePath: { type: String, default: '' },
    buttonAction: { type: Function },
    buttonText: { type: String, default: '' },
    text: { type: String, default: '' },
})

const { t, locale } = useI18n()
const router = useRouter()

const popupOpen = ref(false)
const defaultButtonAction = () => {
    router.push({
        name: 'settings.subscription',
    })
}
</script>
