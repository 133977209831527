import axios from 'axios'
import { onRequest, onRequestError, onResponse, onResponseError } from './interceptors'

const baseURL = import.meta.env.VITE_APP_ROOT_API
// Base API axios client to perform
// HTTP requests on the application.
const client = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    baseURL,
})

// Add the request interceptor that will run when
// the client performs any HTTP request.
client.interceptors.request.use(onRequest, onRequestError)

// Add the response interceptor that will run when
// the client gets any HTTP response.
client.interceptors.response.use(onResponse, onResponseError)

export default client
