<template>
    <div class="flex items-end">
        <div
            class="flex text-base items-center justify-center text-center cursor-pointer text-brand-base space-x-1 hover:scale-105 transition duration-200"
            @click="() => buttonClicked()"
        >
            <slot />
        </div>
    </div>
</template>

<script setup>
import useButton, { buttonProps } from '@/lib/hooks/button'

const props = defineProps({ ...buttonProps })

let { active, onClick } = useButton()
const buttonClicked = () => {
    onClick({ action: props.action, to: props.to })
}
</script>
