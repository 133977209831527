import { createSensor } from '@/plugins/sensor'
import { Toast } from '@/components/ion/Toast.vue'
import { i18n } from '@/i18n'

const events = {
    onProcessLocked: () => {
        Toast.warning(i18n.global.t('sensor.onProcessLocked')) //'There is already a process, please wait'
    },
    onGetSessionsListFailed: () => {
        Toast.warning(i18n.global.t('sensor.onGetSessionsListFailed')) //'Failed to read the sessions list'
    },
    onGetSessionFailed: () => {
        Toast.warning(i18n.global.t('sensor.onGetSessionFailed')) //'Failed to read the session'
    },
    onUploadFailed: (file) => {
        Toast.warning(i18n.global.t('sensor.onUploadFailed', { filename: file.properties.filename })) //'Failed to upload the session '
    },
}

export default createSensor({ language: i18n.global.locale, ...events })
