/* ******
Use examples:

show({ id: 'Test', text: 'Hello' }) // Or :
showById(1)  // Or :
startBannersWatcher()
****** */

import { inject, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useSWRV from 'swrv'
import fetcher from '@/lib/API/fetcher'

export default () => {
    const { locale } = useI18n()
    const banner = inject('banner')

    const store = useStore()

    const watchBanners = ref(false)

    let bannersId = []
    let timers = {}

    const { data: banners, error: bannersErrors } = useSWRV('/banners', fetcher, {
        revalidateOnFocus: false,
        refreshInterval: 120000,
    })

    const showById = (bannerId, fallbackLocale = null) => {
        const banner = banners.value.find(({ id }) => id === bannerId)

        if (banner === -1) {
            console.warn(`Banner #${bannerId} not found `)
            return
        }

        let content = banner.contents?.[locale.value]
        if (!content && fallbackLocale) content = banner.contents?.[fallbackLocale]

        if (!content) {
            console.warn(`Banner #${bannerId} without content`)
            return
        }
        show({
            id: banner.id,
            name: banner.name,
            level: banner.level,
            title: content?.title,
            text: content?.text_long,
            shortText: content?.text_short,
            buttonText: content?.button_text,
            buttonLink: content?.button_link,
        })
    }

    const show = ({ id, name = '', level, title = '', text, shortText = '', buttonText = '', buttonLink = '' }) => {
        const topPadding = store.getters['device/topPadding']

        banner.show(id, {
            id,
            name,
            level,
            title,
            text,
            shortText,
            buttonText,
            buttonLink,
            topPadding,
        })
    }
    const hide = (id) => {
        banner.hide(id)
    }

    const startBannersWatcher = () => {
        if (!watchBanners.value) {
            watchBanners.value = true
            refresh()
        }
    }

    const refresh = () => {
        if (!watchBanners.value) return
        const bannersToDisplay = banners.value?.filter(
            ({ id }) => bannersId.findIndex((bannerId) => bannerId === id) === -1
        )

        const currentTimeStamp = Date.now()

        bannersToDisplay?.forEach((banner) => {
            // const beginAt = currentTimeStamp + Math.round(Math.random() * 10000)
            // const endAt = beginAt + Math.round(Math.random() * 1000)
            const beginAt = new Date(banner.begin_at).getTime()
            const endAt = new Date(banner.end_at).getTime()

            if (beginAt <= currentTimeStamp && endAt >= currentTimeStamp) {
                bannersId.push(banner.id)
                showById(banner.id)
            } else if (beginAt > currentTimeStamp) {
                console.log(
                    `The banner [${banner.id}] will be triggered in [${Math.round(
                        (beginAt - currentTimeStamp) / 1000
                    )}] secs`
                )

                clearTimeout(timers[banner.id])
                timers[banner.id] = setTimeout(() => {
                    bannersId.push(banner.id)
                    showById(banner.id)
                }, beginAt - currentTimeStamp)
            }
        })
    }

    watch(banners, (newBanners, oldBanners) => {
        // Hide removed banners
        oldBanners?.forEach((oldBanner) => {
            const indexNewBanner = newBanners?.findIndex((newBanner) => oldBanner.id === newBanner.id)

            if (indexNewBanner === -1 || indexNewBanner === undefined) banner.hide(oldBanner.id)
        })

        refresh()
    })

    return { banners, showById, show, hide, startBannersWatcher }
}
