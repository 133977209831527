<template>
    <Modal
        class="space-y-6"
        :title="$t('protocol.title')"
        :is-open="isOpen"
        @update:is-open="setOpen($event)"
        :back-text="$t('nav.close')"
    >
        <div class="space-y-4">
            <HorseHeader :horse="horse" :displayOwners="false" read-only small />

            <div class="space-y-1">
                <h3> {{ protocol?.title }} </h3>
                <Paragraph> {{ protocol?.description }} </Paragraph>
            </div>

            <h4>
                {{
                    $t('metric.from-to', {
                        start: format(protocol?.start_at, 'D MMM YYYY'),
                        end: format(add(protocol?.start_at, protocol?.day_count - 1, 'day'), 'D MMM YYYY'),
                    })
                }}
            </h4>

            <Loading v-if="!steps" />
            <div v-else class="space-y-2">
                <div class="grid gap-3 grid-cols-2 md:grid-cols-3">
                    <ProtocolStepCard
                        v-for="(step, indexStep) of steps"
                        :key="`protocol-step-${indexStep}`"
                        :step="step"
                        :index="indexStep"
                        :displayAddButton="false"
                        :editable="false"
                        :class="
                            step?.isCurrent ? 'border-2 border-dark-lightest scale-105' : 'bg-dark-light bg-opacity-50'
                        "
                    >
                        <template #top>
                            <div class="flex text-xs mt-1">
                                {{ step?.range }}
                            </div>
                        </template>

                        <template v-if="step?.status" #icon-status>
                            <CheckIcon v-if="step?.status === 'done'" checked />
                        </template>
                    </ProtocolStepCard>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { computed, ref, watch, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import { add, format } from '@/lib/helpers/time'
import useProtocolSteps from '@/lib/hooks/use-protocol-steps'

import Paragraph from '@/components/Paragraph.vue'
import Modal from '@/components/ion/Modal.vue'
import ProtocolStepCard from '@/components/ProtocolStepCard.vue'
import HorseHeader from '@/components/HorseHeader.vue'
import Loading from '@/components/Loading.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'

const { locale } = useI18n()
const { setSteps } = useProtocolSteps()

const props = defineProps({
    isOpen: { type: Boolean, default: false },
    protocol: { type: Object, required: true },
    horse: { type: Object, required: true },
})

const emit = defineEmits(['update:isOpen'])

const steps = computed(() => setSteps(props.protocol?.steps, props.protocol?.start_at))

const setOpen = (state) => {
    emit('update:isOpen', state)
}
</script>
