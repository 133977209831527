import router from '@/router'
import axios from '@/lib/API/client'
import Memory from '@/lib/memory'
import Time from '@/lib/helpers/time'
import { loadTranslations } from '@/i18n'
import * as Sentry from '@sentry/vue'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor'

export default {
    namespaced: true,
    state: () => {
        return {
            /**
             * Stores the current logged in user.
             */
            user: undefined,

            /**
             * Stores the authentication token of
             * the application.
             */
            token: undefined,

            /**
             * Stores the refresh token of the
             * application.
             */
            refresh_token: undefined,

            /**
             * Stores the expiration time of the token.
             *
             * time: Date instance with the time it expires.
             * handler: Handler that will update the token before
             *  expiration happens
             */
            expires: { time: undefined, handler: undefined },

            /**
             * Determines if the initial check has been done.
             */
            initialCheckDone: false,

            offerings: [],
        }
    },

    getters: {
        /**
         * Determines if the user is logged in or not.
         */
        loggedIn(state) {
            return (
                typeof state.user !== 'undefined' &&
                typeof state.token !== 'undefined' &&
                typeof state.refresh_token !== 'undefined' &&
                typeof state.expires.time !== 'undefined' &&
                typeof state.expires.handler !== 'undefined'
            )
        },
    },

    mutations: {
        /**
         * Change the state to determine if the
         * authentication has performed the initial check.
         */
        setInitialCheck(state, value) {
            state.initialCheckDone = value
        },

        /**
         * Sets the user to the state.
         */
        setUser(state, { user, token, refresh_token }) {
            // Only mutate the user if the request
            // contains the user. This must be checked
            // because this method is also called when
            // the token refreshes and the token refresh
            // endpoint does not return the user information.
            if (user) state.user = user
            // Stores the authentication tokens
            // We also need to conditionally check this
            // since this method is also called when the user
            // edits his account info and not the tokens.
            if (token) state.token = token
            if (refresh_token) state.refresh_token = refresh_token
        },

        /**
         * Sets the available offering
         */
        setOfferings(state, { offerings }) {
            state.offerings = offerings
        },

        /**
         * Sets the expiration information.
         */
        setExpires(state, { time, handler }) {
            state.expires.time = time
            state.expires.handler = handler
        },

        /**
         * Clears the user authentication information.
         */
        clear(state) {
            state.user = undefined
            state.token = undefined
            state.expires.time = undefined
            state.expires.handler = undefined
        },
    },

    actions: {
        /**
         * Updates the user information on the server
         * and updates the local copy.
         */
        async edit({ commit, dispatch }, info) {
            // Perform a server update
            const response = await axios.put('/user', info)
            // Update the local copy of the user.
            commit('setUser', { user: response.data })
            return response
        },
        /**
         * Update the local copy of the user
         */
        setUser({ commit }, user) {
            commit('setUser', { user })
        },
        /**
         * Performs the initial check of the user
         * authenticaton. This must be called upon
         * the given layout to initiate the authentication.
         */
        async initialCheck({ commit, getters, dispatch }) {
            // Check if there's a token inside the phone's memory.
            const token = await Memory.get('auth_token')
            const expiresIn = await Memory.get('auth_expire_at')
            const refresh_token = await Memory.get('auth_refresh_token')
            const guest = router?.currentRoute?.value?.meta?.guest
            let user = undefined
            if (token && expiresIn && refresh_token && !guest) {
                // There's a token and an expiration at. We need to check
                // if it has expired and if the token is valid.
                const expire_at_date = new Time(expiresIn)
                if (expire_at_date.isPast()) {
                    // The token has expired and therefore, we should
                    // force a new log-in of the user.
                    await loadTranslations()
                    await router.push({
                        name: 'auth.login',
                        query: { ...router.currentRoute.value.query, redirect: router.currentRoute.value.path },
                    })
                    return commit('setInitialCheck', true)
                }
                // The expiration time is valid, but we need to check
                // if the token is valid and grab the user info from the server.
                user = await dispatch('user', { token })

                if (user !== undefined) {
                    // Looks like the token is valid and we now have the
                    // user information. We can already set the data to the auth.
                    await dispatch('saveUser', { user, token, refresh_token, forceExpiresIn: expiresIn })
                }
            }
            // Check if there's the need for perform redirects
            if (getters.loggedIn && guest) {
                await router.push({ name: 'index' })
            } else if (!getters.loggedIn && !guest) {
                await router.push({
                    name: 'auth.login',
                    query: { ...router.currentRoute.value.query, redirect: router.currentRoute.value.path },
                })
            }
            await loadTranslations(user?.locale)

            commit('setInitialCheck', true)
        },

        /**
         * Returns a promise that resolves to the
         * offering available
         */
        async loadPurchases({ state, commit }, { user }) {
            console.log('#PURCHASES loadPurchases', user)

            if (Capacitor.getPlatform() !== 'ios') {
                return
            }

            try {
                // await Purchases.setMockWebResults({ shouldMockWebResults: true })
                await Purchases.setLogLevel({ level: LOG_LEVEL.VERBOSE })
                const appUserID = user?.revenuecat_id ?? user?.id
                console.log('#PURCHASES appUserID.', appUserID)
                await Purchases.configure({
                    apiKey: import.meta.env?.VITE_APP_APPLE_REVENUECAT_API_KEY,
                    appUserID: appUserID,
                }) //Apple
                Purchases.setEmail({ email: user?.email })
                Purchases.setDisplayName({ displayName: user?.first_name + ' ' + user?.last_name })
                const customerInfo = await Purchases.getCustomerInfo()
                console.log('#PURCHASES getCustomerInfo', customerInfo)
                console.log('#PURCHASES getCustomerInfo.activeSubscriptions', customerInfo?.activeSubscriptions)

                const offerings = await Purchases.getOfferings()
                console.log('#PURCHASES offerings', offerings)
                if (offerings?.current?.availablePackages?.length > 0) {
                    // offerings.current.availablePackages
                    commit('setOfferings', { offerings: offerings.current.availablePackages })
                } else {
                    console.log('Aucune offre disponible') //this is normal from a web browser
                    commit('setOfferings', { offerings: [] })
                }
            } catch (error) {
                console.warn('Purchases', error)
            }
        },

        /**
         * Returns a promise that resolves to the
         * authenticated user or undefined. Additionally
         * it accepts a token.
         */
        user({ state }, { token }) {
            return axios
                .get('/user', { headers: { Authorization: `Bearer ${token ?? state.token}` } })
                .then((response) => response.data)
                .catch(() => undefined)
        },

        /**
         * Saves the user information and the expiration
         * time for the next token-refresh. This function
         * is abstracted correctly to perform additional
         * recursive calls by using the setTimeout function.
         * This means that this function is called on both,
         * the login and the token-refresh actions.
         */
        async saveUser(
            { commit, dispatch },
            { user, token, refresh_token, expiresIn, forceExpiresIn = undefined, impersonate = false }
        ) {
            commit('setUser', { user, token, refresh_token })
            // Convert from seconds to milliseconds
            // the expiration time of the token.
            const expiresInMilliseconds = expiresIn * 1000
            const refreshToken = () =>
                axios
                    .post('/refresh-token', { refresh_token })
                    .then((response) => dispatch('saveUser', response.data))
                    .catch(function (error) {
                        console.log('refresh token error', error)
                        router.push({
                            name: 'auth.login',
                            query: { ...router.currentRoute.value.query, redirect: router.currentRoute.value.path },
                        })
                    })

            // Check if there's the need for an immediate refresh
            const safeSpotInMilliseconds = 1000 * 60 * 60 * 24 * 3
            const fExpireIn = new Time(forceExpiresIn)
            // Calculates if fExpireIn is between now and safeSpotInMilliseconds ago.
            const mustForceRefresh =
                forceExpiresIn &&
                new Time().time.isBetween(
                    fExpireIn.time.subtract(safeSpotInMilliseconds, 'millisecond'),
                    fExpireIn.time
                )
            // fExpireIn.time.isBetween(fExpireIn.time.subtract(safeSpotInMilliseconds, 'millisecond'), new Time().time)
            const fExpireInDate = new Date(fExpireIn.time.toISOString())

            // if (expiresInMilliseconds < safeSpotInMilliseconds || mustForceRefresh) {
            if (mustForceRefresh) {
                // Perform a refresh request and stop.
                return refreshToken()
            }
            // Set the expiration information such as
            // the time and the refresh handler.
            const expire_at = new Date(Date.now() + (expiresInMilliseconds - safeSpotInMilliseconds))
            const finalExpirationAt = forceExpiresIn ? fExpireInDate : expire_at
            commit('setExpires', {
                time: finalExpirationAt,
                handler: setTimeout(refreshToken, finalExpirationAt - Date.now()),
            })
            // To identify the user in Sentry:
            if (user) {
                Sentry.setUser({ id: user?.id, email: user?.email, username: user?.first_name + ' ' + user?.last_name })
                await dispatch('loadPurchases', { user })

                if (!impersonate) {
                    // date.toISOString().slice(0, 19).replace('T', ' ')
                    Device.getInfo().then((info) => {
                        let userInfo = {
                            last_seen: new Date().toISOString().slice(0, 19).replace('T', ' '),
                            last_platform: info['operatingSystem'],
                        }
                        if (Capacitor.getPlatform() === 'web') {
                            userInfo['webapp_version'] = APP_VERSION
                        } else {
                            userInfo['mobile_version'] = APP_VERSION
                        }

                        //here we don't care to wait for the response
                        axios.put('/user', { ...userInfo }).catch(() => undefined)
                    })
                }
            }

            // Save the user to the phone's memory
            return Promise.all([
                Memory.set('auth_token', token),
                Memory.set('auth_expire_at', finalExpirationAt),
                Memory.set('auth_refresh_token', refresh_token),
            ])
        },

        /**
         * Perform a forgot action against the server API.
         */
        async forgot({ dispatch }, { credentials }) {
            const response = await axios.post('/password/email', { email: credentials.email })
            return response.data
        },

        /**
         * Perform a exist action against the server API.
         */
        async exist({ dispatch }, { credentials }) {
            const response = await axios.post('/login/exist', credentials, { withCredentials: true })
            return response.data
        },

        /**
         * Perform a login action against the server API.
         */
        async login({ dispatch }, { credentials }) {
            const response = await axios.post('/login', credentials, { withCredentials: true })
            loadTranslations(response.data?.user.locale)
            await dispatch('saveUser', response.data)
        },

        /**
         * Registers a new user to the application.
         */
        async register({ dispatch }, { credentials }) {
            const response = await axios.post('/register', credentials, { withCredentials: true })
            await dispatch('saveUser', response.data)
        },

        /**
         * Logs the user out of the application.
         */
        async logout({ commit }) {
            // clear the currently set user:
            Sentry.getCurrentScope().setUser('user', null)

            // Clear the application user info.
            commit('clear')

            // Purchases log out
            try {
                if (Capacitor.getPlatform() === 'ios') {
                    const customerInfo = await Purchases.logOut()
                }
            } catch (error) {
                console.log('Purchases.logOut failed', error)
            }

            // Clear the persistance of the data.
            await Promise.all([
                Memory.remove('auth_token'),
                Memory.remove('auth_expire_at'),
                Memory.remove('auth_refresh_token'),
            ])

            loadTranslations()
        },
    },
}
