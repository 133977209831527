<template>
    <ion-app class="relative h-full flex flex-col bg-dark-base text-text-base">
        <SkeletonLayout />
    </ion-app>
</template>

<script>
import { onMounted, inject } from 'vue'
import { useStore } from 'vuex'

import { IonApp, isPlatform } from '@ionic/vue'
import SkeletonLayout from '@/layouts/Skeleton.vue'

import { Capacitor } from '@capacitor/core'
// import { SplashScreen } from '@capacitor/splash-screen'
import { Keyboard } from '@capacitor/keyboard'
import { StatusBar, Style } from '@capacitor/status-bar'
import tailwind from '@/lib/tailwind'

export default {
    components: { SkeletonLayout, IonApp },
    setup() {
        const isMobile = inject('isMobile')
        const isBuildForMobile = inject('isBuildForMobile')
        const store = useStore()

        const checkIfAppInstalled = async () => {
            const deepLink = import.meta.env?.VITE_APP_URLSCHEME + '://' + window.location.href

            const iframe = document.createElement('iframe')
            iframe.style.visibility = 'hidden'
            iframe.src = deepLink
            document.body.appendChild(iframe)

            await new Promise((resolve) => setTimeout(resolve, 3000)) // Wait for 3 seconds

            document.body.removeChild(iframe)

            // Check if the specific URL was successfully opened
            if (!iframe.contentWindow) {
                // If the iframe couldn't open the specific URL, redirect the user to the app store
                console.log('### DEEPLINK you should install the app')
                if (confirm('App?')) {
                    if (isPlatform('android')) {
                        window.open(import.meta.env.VITE_APP_PLAYSTORE_URL)
                    } else {
                        window.open(import.meta.env.VITE_APP_APPSTORE_URL)
                    }
                }
            }
        }

        // Logic related to the splash screen.
        onMounted(async () => {
            console.log('### DEEPLINK tests ###', !isBuildForMobile, isPlatform('android'), isPlatform('ios'))
            if (!window.location.href.includes('pricing')) {
                if (!isBuildForMobile && isMobile && (isPlatform('android') || isPlatform('ios'))) {
                    console.log('### DEEPLINK redirection ###')
                    await checkIfAppInstalled()
                }
            }

            if (Capacitor.isPluginAvailable('StatusBar')) {
                StatusBar.setStyle({ style: Style.Dark })
                if (isPlatform('android')) StatusBar.setBackgroundColor({ color: tailwind.theme.colors.dark.base })
                StatusBar.show()
            }
            //idea is to use store.state.auth.initialCheckDone and hide quickly when app is loaded
            // if (Capacitor.isPluginAvailable('SplashScreen')) {
            // SplashScreen.hide()
            // }
        })

        // Logic related to the device.
        store.dispatch('device/load')

        if (Capacitor.isPluginAvailable('Keyboard')) {
            if (isPlatform('ios') && isMobile) Keyboard.setAccessoryBarVisible({ isVisible: true })
        }
    },
}
</script>
