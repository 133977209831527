<template>
    <Card class="flex flex-col space-y-2 text-sm tracking-wide">
        <div class="flex justify-between w-full overflow-hidden overflow-ellipsis border-b border-dark-lightest">
            <div class="flex w-full items-center justify-between space-x-2 overflow-hidden">
                <div class="text-text-darker whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {{ $t('metric.step') }} {{ index + 1 }}
                </div>
                <div>
                    <div v-if="$slots['icon-status']">
                        <slot name="icon-status" />
                    </div>
                    <div v-else-if="editable" class="flex flex-row items-center space-x-4">
                        <TrashIcon class="h-5 w-5 cursor-pointer text-brand-base" @click="deleteStep" />
                    </div>
                    <div v-else class="flex flex-row items-center space-x-4">
                        <!-- <XMarkIcon class="h-5 w-5 cursor-pointer" @click="cancelStep" />
                        <CheckIcon class="h-5 w-5 cursor-pointer" @click="saveStep" /> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="space-y-3 md:space-y-4 mb-6">
            <slot v-if="$slots.top" name="top" />
            <div v-else class="flex mt-4 justify-center">
                <InputEditable
                    v-model="step.day_count"
                    @input:leave="() => saveStep({ day_count: step.day_count })"
                    :placeholder="$t('metric.day-count')"
                    :label="$t('metric.day-count')"
                    type="number"
                    :unit="$t('metric.day')"
                    :editable="editable"
                >
                    <Value :title="$t('metric.day-count')" :value="step.day_count" allowZero column>
                        <template #icon>
                            <CalendarDaysIcon class="h-5 w-5 mr-1" />
                        </template>
                    </Value>
                </InputEditable>
            </div>

            <InputEditable
                v-model="step.description"
                @input:leave="() => saveStep({ description: step.description })"
                :placeholder="$t('form.description')"
                type="textarea"
                :editable="editable"
            >
                <div class="flex flex-col space-y-2">
                    <h4>{{ $t('form.description') }}</h4>
                    <Paragraph class="max-h-14 overflow-scroll text-wrap max-w-52">{{ step.description }} </Paragraph>
                </div>
            </InputEditable>
            <!-- {{ step.gaits }} -->
            <div class="w-full" v-for="(row, rowIndex) in step.gaits" :key="rowIndex">
                <!-- :class="{ 'bg-dark-lightest': rowIndex % 2 === 0 }" -->
                <!-- :label="$t('metric.duration')" -->
                <ProtocolStepGaitsEditable
                    v-model:duration="row.duration"
                    v-model:gait="row.gait"
                    :editable="editable"
                    @delete="() => removeRow(rowIndex, step)"
                    @save="() => saveGaitStep(rowIndex, row, step)"
                />
            </div>
            <Button v-if="editable" :action="() => addGait(step)"> {{ $t('program.add-gait') }} </Button>
        </div>

        <div v-if="displayAddButton && editable" class="flex justify-end absolute -right-2 -bottom-6 cursor-pointer">
            <PlusCircleIcon class="w-8 h-8 -mt-4 -mr-4 text-brand-base rounded-full" @click.stop="addStep" />
        </div>
    </Card>
</template>

<script setup>
import { ref } from 'vue'

import { CalendarDaysIcon, CalculatorIcon } from '@heroicons/vue/24/outline'
import { PlusCircleIcon } from '@heroicons/vue/24/solid'
import { TrashIcon } from '@heroicons/vue/24/outline'

import Card from '@/components/Card.vue'
import Value from '@/components/Value.vue'
import Paragraph from '@/components/Paragraph.vue'
import InputEditable from '@/components/form/InputEditable.vue'
import ProtocolStepGaitsEditable from '@/components/ProtocolStepGaitsEditable.vue'
import Input from '@/components/form/Input.vue'
import Select from '@/components/form/Select.vue'
import Button from '@/components/buttons/Primary.vue'

const props = defineProps({
    index: { type: Number, required: true },
    step: { type: Object, required: true },
    displayAddButton: { type: Boolean, default: true },
    editable: { type: Boolean, default: true },
    startAt: { type: Object, default: null },
})

const emit = defineEmits(['click:add', 'click:delete', 'click:save', 'click:cancel'])

const addStep = () => {
    emit('click:add', props.step)
}
const deleteStep = () => {
    emit('click:delete', props.step.id)
}
const saveStep = (step) => {
    emit('click:save', step)
}
const saveGaitStep = (index, gait, step) => {
    step.gaits[index] = gait
    emit('click:save', step)
}
const cancelStep = () => {
    emit('click:cancel')
}
const addGait = async (step) => {
    if (!step.gaits) {
        step.gaits = []
    }
    step.gaits.push({ gait: 'walk', duration: 0 })
    emit('click:save', step)
}
const removeRow = async (rowIndex, step) => {
    step.gaits.splice(rowIndex, 1)
    emit('click:save', step)
}
const updateGaits = async () => {
    // await sleep(200)
    // await calculateStrides()
}
</script>
