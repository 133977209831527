import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

export const DIRECTORIES = {
    /**
     * The Cache directory Can be deleted in cases of low memory, so use this directory
     * to write app-specific files that your app can re-create easily.
     */
    cache: Directory.Cache,

    /**
     * The Data directory On iOS it will use the Documents directory On Android it's the
     * directory holding application files. Files will be deleted when the application is uninstalled.
     */
    data: Directory.Data,

    /**
     * The Documents directory On iOS it's the app's documents directory. Use this directory to store
     * user-generated content. On Android it's the Public Documents folder, so it's accessible from other
     * apps. It's not accesible on Android 10 unless the app enables legacy External Storage by adding
     * `android:requestLegacyExternalStorage="true"` in the `application` tag in the `AndroidManifest.xml`
     */
    documents: Directory.Documents,

    /**
     * The external directory On iOS it will use the Documents directory On Android it's the directory
     * on the primary shared/external storage device where the application can place persistent files it
     * owns. These files are internal to the applications, and not typically visible to the user as media.
     * Files will be deleted when the application is uninstalled.
     */
    external: Directory.External,

    /**
     * The external storage directory On iOS it will use the Documents directory On Android it's the
     * primary shared/external storage directory. It's not accesible on Android 10 unless the app enables
     * legacy External Storage by adding `android:requestLegacyExternalStorage="true"` in the `application`
     * tag in the `AndroidManifest.xml`
     */
    externalStorage: Directory.ExternalStorage,
}

export default class Storage {
    /**
     * Create a new instance.
     */
    static make() {
        return new Storage()
    }

    /**
     * Set the directory for this file instance.
     *
     * @param {string} directory
     */
    directory(directory) {
        this.directory = directory

        return this
    }

    /**
     * Set the directory to 'Data'.
     */
    dataDirectory() {
        return this.directory(DIRECTORIES.data)
    }

    /**
     * Set the directory to 'Documents'.
     */
    documentsDirectory() {
        return this.directory(DIRECTORIES.documents)
    }

    copy(from, to) {
        return Filesystem.copy({ directory: this.directory, from, to })
    }

    mkdir(path, { recursive } = { recursive: true }) {
        return Filesystem.mkdir({
            ...this._buildPathObject(path),
            recursive,
        })
    }

    rmdir(path, { recursive } = { recursive: true }) {
        return Filesystem.rmdir({
            ...this._buildPathObject(path),
            recursive,
        })
    }

    directoryExists(path) {
        return this.readDirectory(path)
    }

    readDirectory(path) {
        return Filesystem.readdir(this._buildPathObject(path))
    }

    exists(path) {
        return this.getFileInfo(path)
    }

    getFileInfo(path) {
        return Filesystem.stat(this._buildPathObject(path))
    }

    getFilePath(path, filename) {
        return path ? `${path}/${filename}` : filename
    }

    async readAsArrayBufferView(path, filename) {
        const response = await Filesystem.readFile(this._buildPathObject(this.getFilePath(path, filename)))

        return Uint8Array.from(atob(response.data), (c) => c.charCodeAt(0))
    }

    async readFile(path, filename) {
        const fullPath = this.getFilePath(path, filename)
        return Filesystem.readFile({ encoding: Encoding.UTF8, directory: this.directory, path: fullPath })
    }

    async writeFile(path, filename, data, { recursive, encoding } = { recursive: true, encoding: Encoding.UTF8 }) {
        const fullPath = this.getFilePath(path, filename)
        try {
            const statistics = await this.exists(fullPath)
            if (statistics && statistics.size > 0) {
                await this.deleteFile(fullPath)
            }
        } catch (error) {
            console.log('writeFile file does not exist', error)
        }

        const writingOptions = {
            ...this._buildPathObject(fullPath),
            data,
            recursive,
        }

        if (encoding) {
            writingOptions.encoding = encoding
        }

        return Filesystem.writeFile(writingOptions)
    }

    deleteFile(path) {
        return Filesystem.deleteFile(this._buildPathObject(path))
    }

    _buildPathObject(path) {
        return { directory: this.directory, path }
    }

    getUri(path) {
        return Filesystem.getUri(this._buildPathObject(path))
    }
}
