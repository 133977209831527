export const PbPFtpOperation = {
    Command: {
        GET: 0,
        PUT: 1,
        MERGE: 2,
        REMOVE: 3,
    },
    command: 1,
    path: '',
}

export const PbPFtpQuery = {
    SET_SYSTEM_TIME: 1,
    SET_LOCAL_TIME: 3,
    GET_LOCAL_TIME: 4,
    REQUEST_START_RECORDING: 14,
    REQUEST_STOP_RECORDING: 15,
    REQUEST_RECORDING_STATUS: 16,
}

export const PbPFtpRequestStartRecordingParams = {
    sample_type: 0,
    recording_interval: {
        hours: 0,
        minutes: 0,
        seconds: 0,
        millis: 0,
    },
    sample_data_identifier: '',
}

export const PbPFtpSetLocalTimeParams = {
    date: {
        year: 0,
        month: 0,
        day: 0,
    },
    time: {
        hour: 0,
        minute: 0,
        seconds: 0,
        millis: 0,
    },
    tz_offset: 0,
}

export const PbPFtpSetSystemTimeParams = {
    date: {
        year: 0,
        month: 0,
        day: 0,
    },
    time: {
        hour: 0,
        minute: 0,
        seconds: 0,
        millis: 0,
    },
    trusted: false,
}
