<template>
    <div v-if="editing" class="flex space-x-2 w-full h-full bg-dark-lightest rounded-md p-2" @blur="save">
        <div class="flex items-center text-center space-x-2">
            <Select
                :label="$t('gait.title')"
                v-model="gait"
                class="pr-2"
                @change="$emit('update:gait', gait)"
                @input:focus="() => setEditing(true)"
            >
                <option :key="'walk'" :value="'walk'"> {{ $t(`gait.walk`) }} </option>
                <option :key="'trot'" :value="'trot'"> {{ $t(`gait.trot`) }} </option>
                <option :key="'canter'" :value="'canter'"> {{ $t(`gait.canter`) }} </option>
                <option :key="'standing'" :value="'standing'"> {{ $t(`gait.standing`) }} </option>
            </Select>
            <Select
                label="duration"
                v-model.number="duration"
                class="pr-2"
                @change="$emit('update:duration', duration)"
                @input:focus="() => setEditing(true)"
            >
                <option v-for="value in availableDuration" :key="value" :value="value">
                    {{ secondsToHMS(value, 2, true) }}
                </option>
            </Select>
            <!-- <Input
                :xcirclebutton="false"
                v-model="duration"
                type="time"
                step="1"
                unit="s"
                userUnit="mm:ss"
                :emptyValue="0"
                @input:focus="() => setEditing(true)"
                @input:leave="() => $emit('update:duration', duration)"
            /> -->
        </div>
        <div class="flex items-center text-center">
            <CheckCircleIcon class="text-state-green h-6 w-6" @click="save" />
        </div>
    </div>
    <div v-else class="grid grid-cols-5 space-x-1">
        <div class="grid grid-cols-2 col-span-4 items-center" @dblclick="() => setEditing(true)">
            <Text>{{ $t(`gait.${gait}`) }}</Text>
            <div class="grid grid-cols-2">
                <div
                    :class="{
                        'text-gaits-canter': gait === 'canter',
                        'text-gaits-trot': gait === 'trot',
                        'text-gaits-walk': gait === 'walk',
                        'text-gaits-standing': gait === 'standing',
                    }"
                >
                    {{ convertTime(duration, 's', 'mm:ss', false).value }}
                </div>
                <PencilSquareIcon
                    v-if="editable"
                    class="h-4 w-4 cursor-pointer text-brand-base"
                    @click="() => setEditing(true)"
                />
            </div>
        </div>

        <div v-if="editable" class="flex justify-center text-brand-base m-auto">
            <TrashIcon class="h-4 w-4 cursor-pointer" @click="$emit('delete')" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { convertTime, secondsToHMS } from '@/lib/helpers/convert'

import Select from '@/components/form/Select.vue'
import Button from '@/components/buttons/Primary.vue'
import Input from '@/components/form/Input.vue'
import Text from '@/components/Text.vue'
import { PencilSquareIcon, TrashIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    editable: { type: Boolean, default: true },
})
const emits = defineEmits(['save', 'delete'])
const duration = defineModel('duration', { type: Number, default: 0 })
const gait = defineModel('gait', { type: String, default: undefined })
const editing = defineModel('editing', { type: Boolean, default: false })
const test = ref('42:42')
const setEditing = (isEditing) => {
    // console.log('setEditing', isEditing)
    editing.value = isEditing
}
const save = () => {
    setEditing(false)
    emits('save')
}
const generateTimeIntervals = () => {
    let intervals = []

    // Jusqu'à 1 minute avec des intervalles de 1 seconde
    for (let i = 1; i <= 60; i++) {
        intervals.push(i)
    }

    // // De 1 minute à 5 minutes avec des intervalles de 30 secondes
    // for (let i = 90; i <= 300; i += 30) {
    //     intervals.push(i)
    // }
    // De 1 minute à 1 heure avec des intervalles de 30 secondes
    for (let i = 90; i <= 3600; i += 30) {
        intervals.push(i)
    }

    // // De 5 minutes à 1 heure avec des intervalles de 1 minute
    // for (let i = 360; i <= 3600; i += 60) {
    //     intervals.push(i)
    // }

    return intervals
}
const availableDuration = generateTimeIntervals()
</script>
