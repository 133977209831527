<template>
    <Modal
        class="space-y-6"
        :title="$t('protocol.program')"
        :is-open="isOpen"
        @update:is-open="setOpen($event)"
        :back-text="$t('nav.close')"
    >
        <div class="space-y-4">
            <HorseHeader :horse="horse" :displayOwners="false" read-only small />

            <div class="space-y-1">
                <h4> {{ $t('event.date') }} </h4>
                <Paragraph class="ucfirst">
                    {{ toLocaleDateString(program?.vet_check_at, $i18n.locale, { weekday: 'short', month: 'short' }) }}
                </Paragraph>
            </div>

            <div class="space-y-1">
                <h4> {{ $t('protocol.reason') }} </h4>
                <Paragraph> {{ program?.reason }} </Paragraph>
            </div>

            <div class="space-y-1">
                <h4> {{ $t('protocol.diagnostic') }} </h4>
                <Paragraph> {{ program?.diagnostic }} </Paragraph>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'

import { toLocaleDateString } from '@/lib/helpers/time'

import Paragraph from '@/components/Paragraph.vue'
import Modal from '@/components/ion/Modal.vue'
import HorseHeader from '@/components/HorseHeader.vue'

const props = defineProps({
    isOpen: { type: Boolean, default: false },
    program: { type: Object, required: true },
    horse: { type: Object, required: true },
})

const emit = defineEmits(['update:isOpen'])

const setOpen = (state) => {
    emit('update:isOpen', state)
}
</script>
