<template>
    <Modal
        class="space-y-6"
        :title="$t('protocol.add-my-vet')"
        :is-open="isOpen"
        @update:is-open="setOpen($event)"
        :back-text="$t('nav.close')"
    >
        <div class="space-y-8">
            <HorseHeader :horse="horse" :displayOwners="false" read-only small />

            <Card>
                <div class="space-y-6">
                    <Paragraph>
                        <span>
                            {{ $t('protocol.vet-description') }}
                            <a href="https://alogo.io/products/health/" class="text-brand-base" target="_blank">
                                {{ $t('nav.more-info') }}.
                            </a>
                        </span>
                    </Paragraph>

                    <InputWithButton
                        class="h-fit"
                        placeholderInput="john.doe@example.com"
                        :labelInput="$t('login.email')"
                        :labelButton="$t('team.invite')"
                        @click:submit="shareAsVet"
                    />
                </div>
            </Card>
        </div>
    </Modal>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import useHorse from '@/lib/hooks/use-horses'

import Card from '@/components/Card.vue'

import Paragraph from '@/components/Paragraph.vue'
import Modal from '@/components/ion/Modal.vue'
import HorseHeader from '@/components/HorseHeader.vue'
import InputWithButton from '@/components/form/InputWithButton.vue'
import { Toast } from '@/components/ion/Toast.vue'

const props = defineProps({
    isOpen: { type: Boolean, default: false },
    horse: { type: Object, required: true },
})

const { t } = useI18n()
const { shareHorse } = useHorse()

const emit = defineEmits(['update:isOpen'])

const setOpen = (state) => {
    emit('update:isOpen', state)
}

const shareAsVet = async (email) => {
    await shareHorse(props.horse.id, email, 'veterinarian')
        .then((response) => {
            Toast.success(t('horse.sharing.invited'))
        })
        .catch((error) => {
            Toast.warning(error?.message)
        })
}
</script>
