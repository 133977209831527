import { ref } from 'vue'
import { useRouter } from 'vue-router'

export const buttonProps = {
    to: { type: [Object, String], required: false },
    action: { type: Function, required: false },
}

export default (defaultProps) => {
    let action = defaultProps?.action
    let to = defaultProps?.to
    const active = ref(false)
    const router = useRouter()

    const onClick = async (newProps = undefined) => {
        if (newProps && newProps.action) {
            action = newProps.action
        }
        if (newProps && newProps.to) {
            to = newProps.to
        }
        if (active.value) return
        active.value = true
        try {
            if (action) await action()
        } catch (e) {
            /* There was an error executing the function action. */
        }
        if (to) {
            if (typeof to === 'string' && to.match(/^(http(s)?|ftp):\/\//)) {
                window.open(to, '_blank')
            } else {
                await router.push(to)
            }
        }
        active.value = false
    }

    return { active, onClick }
}
