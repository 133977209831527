import { ref, reactive, computed } from 'vue'
import {
    BleClient,
    dataViewToHexString,
    dataViewToText,
    textToDataView,
    numbersToDataView,
    dataViewToNumbers,
    hexStringToDataView,
} from '@capacitor-community/bluetooth-le'

import {
    PftpRfc76ResponseHeader,
    processRfc76MessageFrameHeader,
    buildRfc76MessageFrameAll,
    Rfc76SequenceNumber,
} from './BlePsFtpUtils'

import { PbPFtpQuery } from './PftpRequest'
import { PbPFtpError } from './PftpError'
import { PbSampleType } from './PftpTypes'

import BDBleApiImpl from './BDBleApiImpl'

export const PSFTP_SERVICE = '0000feee-0000-1000-8000-00805f9b34fb'
export const MTU_CHARACTERISTIC = 'fb005c51-02e7-f387-1cad-8acd2d8df0c8'
export const D2H_NOTIFICATION_CHARACTERISTIC = 'fb005c52-02e7-f387-1cad-8acd2d8df0c8'
export const H2D_NOTIFICATION_CHARACTERISTIC = 'fb005c53-02e7-f387-1cad-8acd2d8df0c8'
// Polar simple file transfer client declaration.
// POLAR_ELECTRO_OY_SERVICE PSFTP_SERVICE
const DEFAULT_ATT_MTU_SIZE = 23
const DEFAULT_MTU_SIZE = DEFAULT_ATT_MTU_SIZE - 3
//  /**
//  * set mtu to lower than default(232 is the default for polar devices, minimum for H10 is 70 and for OH1 is 140)
//  * to minimize latency
//  *
//  * @param mtu value between 70-512 to be set
//  */
// abstract fun setMtu(@IntRange(from = 70, to = 512) mtu: Int)

export default class PolarSimpleFileTransferProtocol {
    // private properties
    buffer = null
    promises = {}
    sampleHexa = ['53', '41', '4D', '50', '4C', '45', '53', '2E', '42', '50', '42'] // SAMPLES.BPB

    device = null

    properties = reactive({
        ready: false,
        exercise: '',
        exerciseContent: null,
        recording: false,
    })

    constructor(device) {
        this.device = device
    }

    async start() {
        await BleClient.startNotifications(this.device.deviceId, PSFTP_SERVICE, MTU_CHARACTERISTIC, (value) => {
            // console.log('characteristicMTU characteristicvaluechanged', new Uint8Array(value.buffer))
            const header = new PftpRfc76ResponseHeader()
            const newHeader1 = processRfc76MessageFrameHeader(header, new Uint8Array(value.buffer))
            console.log('newHeader1 MTU', newHeader1)
            // status = 0 => frame final sans donnée
            // status = 1 => frame final avec donnée
            // status = 3 => frame avec donnée non fini (une autre va suivre)
            // next = 0 => premiere frame
            // next = 1 => frame suivantes

            if (newHeader1.error === 0) {
                //if we are going to have others frame
                if (newHeader1.next === 0) {
                    this.buffer = newHeader1.payload
                } else {
                    let buf = new ArrayBuffer(this.buffer.length + newHeader1.payload.length)
                    let data = new Uint8Array(buf)
                    let j = 0
                    for (let i = 0; i < this.buffer.length; i++) {
                        data[j] = this.buffer[i]
                        j++
                    }
                    for (let i = 0; i < newHeader1.payload.length; i++) {
                        data[j] = newHeader1.payload[i]
                        j++
                    }
                    this.buffer = data
                }
                // if (newHeader1.status === 3) { }
                if (newHeader1.status === 1 || newHeader1.status === 0) {
                    // console.log('buffer', this.buffer)
                    this.promises.configuration.resolve(this.buffer)
                    this.buffer = null
                }
            } else {
                const error = PbPFtpError?.[newHeader1.error]
                if (error) {
                    this.promises.configuration.reject(error)
                } else {
                    this.promises.configuration.reject(new Error('Fail!'))
                }
            }
        })
        // await this.characteristicD2H.startNotifications()
        // this.characteristicD2H.addEventListener('characteristicvaluechanged', (event) => {
        //     // console.log('characteristicD2H characteristicvaluechanged', event)
        //     const { value } = event.target
        //     console.log('characteristicD2H characteristicvaluechanged', value)
        //     const status = value.getUint8(2)
        //     const feature = value.getUint8(1)
        //     console.log('characteristicD2H characteristicvaluechanged status', status, feature, value)
        //     if (status === 1) {
        //         this.promises.configuration.resolve(value.byteLength === 4 && value.getUint8(3))
        //     } else {
        //         this.promises.configuration.reject(new Error('Fail!'))
        //     }
        // })

        this.properties.ready = true
        await this.requestRecordingStatus()
    }

    /**
     *
     * @returns bool true if succeed else false
     */
    async requestRecordingStatus() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        const payload = await this.getConfiguration(numbersToDataView([2, PbPFtpQuery.REQUEST_RECORDING_STATUS, 128]))
        if (payload[0] !== 8) {
            console.log('not the right frame returned')
            return false
        }

        const isRecording = payload[1] === 1 // 0 or 1
        const toBeDefine = payload[2] // 18
        const packetSize = payload[3] // 17 or 0 if no session
        // console.log('packetSize', packetSize)
        const ts = payload.slice(4, packetSize)
        let date = null
        if (ts.length === 0) {
            date = null
        } else {
            date = dataViewToText(new Uint8Array(ts)) * 1000 //multiply by 1000 to have a valid timestamp javascript date
            // console.log('date', date, ts)
        }
        this.properties.exercise = date
        this.properties.recording = isRecording
        return true
    }

    /**
     *
     * @returns bool true if succeed else false
     */
    async stopRecording() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        await this.requestRecordingStatus()
        if (this.properties.recording === false) {
            return false
        }

        const payload = await this.getConfiguration(numbersToDataView([2, PbPFtpQuery.REQUEST_STOP_RECORDING, 128]))
        await this.requestRecordingStatus()
        return true
    }

    /**
     *
     * @returns bool true if succeed else false
     */
    async startRecording() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        await this.requestRecordingStatus()
        if (this.properties.recording === true) {
            return false
        }
        if (this.properties.exercise !== null) {
            return false
        }
        // @Size(min = 1, max = 64) exerciseId: String,
        // PbSampleType.SAMPLE_TYPE_HEART_RATE ou SAMPLE_TYPE_HEART_RATE.SAMPLE_TYPE_RR_INTERVAL
        // /**
        //  * Recoding intervals for H10 recording start
        //  */

        // 0x01, 0x12, 0x08, 0x08, 0x00, 0x10, 0x00, 0x18, 0x01, 0x20, 0x00, 0x1A, 0x11

        // start walking
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3439 3937 342E 3238 3332 3632
        // start jogging
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3439 3837 392E 3830 3832 3432
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3634 3031 382E 3831 3430 3633
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3634 3939 342E 3735 3138 3733
        // start cycling
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3530 3031 372E 3431 3532 3339
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3634 3636 382E 3738 3731 3837
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3634 3831 312E 3139 3634 3637
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3634 3837 352E 3038 3533 3139
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3333 342E 3231 3532 3634

        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3633 342E 3934 3034 3338
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3634 352E 3435 3734 3736
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3635 332E 3730 3132 3739
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3635 352E 3235 3635 3633
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3636 342E 3238 3531 3535
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3635 3636 352E 3830 3733 3134

        //un vieux lancement en 2022
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3636 3237 3238 3238 312E 3232 3736 3431

        // start hiking
        // Value: 020E 8008 0112 0808 0010 0018 0120 001A 1131 3637 3930 3530 3433 392E 3238 3732 3938

        // val recordIdentifier = "TEST_APP_ID"
        // api.startRecording(deviceId, recordIdentifier, PolarBleApi.RecordingInterval.INTERVAL_1S, PolarBleApi.SampleType.HR)
        // startRecording(identifier: String, exerciseId: String, interval: RecordingInterval?, type: SampleType)
        // val params = PftpRequest.PbPFtpRequestStartRecordingParams.newBuilder().setSampleDataIdentifier(exerciseId)
        //                 .setSampleType(pbSampleType)
        //                 .setRecordingInterval(duration)
        //                 .build()

        const timestampString = (Date.now() / 1000).toFixed(6)
        // console.log('timestampString', timestampString)
        const timestampDataView = textToDataView(timestampString)
        // console.log('timestampDataView', timestampDataView)
        const timestampNumbers = dataViewToNumbers(textToDataView(timestampString)) //49, 54, 55, 56, 57, 55, 55, 50, 48, 54, 46, 55, 50, 48, 48, 48, 52
        // console.log('timestampNumbers', timestampNumbers)
        const PbPFtpRequestStartRecordingParams = {
            sample_type: PbSampleType.SAMPLE_TYPE_HEART_RATE, //SAMPLE_TYPE_RR_INTERVAL or SAMPLE_TYPE_HEART_RATE
            recording_interval: {
                //h10 can work with 1 or 5 seconds ELSE it will use 1s
                hours: 0, //uint8
                minutes: 0, //uint8
                seconds: 1, //uint8
                millis: 0, //uint8
            },
            sample_data_identifier: '',
        }
        // const params = [8, 1, 18, 8, 8, 0, 16, 0, 24, 1, 32, 0, 26, 17]
        const params = [
            // PbSampleType sample_type
            8, // Sample type TAG
            PbPFtpRequestStartRecordingParams.sample_type, // Sample type value
            // PbDuration recording_interval
            18, // Recording interval TAG
            8, // Recording PbDuration size
            8, //separator 1 => 8*1
            PbPFtpRequestStartRecordingParams.recording_interval.hours, // Hours
            16, //separator 2 => 8*2
            PbPFtpRequestStartRecordingParams.recording_interval.minutes, // Minutes
            24, //separator 3 => 8*3
            PbPFtpRequestStartRecordingParams.recording_interval.seconds, // Seconds
            32, //separator 4 => 8*4
            PbPFtpRequestStartRecordingParams.recording_interval.millis, // Millis
            // string sample_data_identifier
            26, // Sample data identifier TAG
            timestampNumbers.length, // Sample data identifier byte length (this should be 0 if empty string is given as identifier as in your example)
            // Parsing this proto would fail now due to missing bytes in sample data identifier. Here should be 17 bytes length UTF-8 encoded string or last byte should be 0 instead of 17.
        ]
        // console.log('params', params, dataViewToNumbers(textToDataView('')), dataViewToNumbers(textToDataView(' ')))
        // debugger

        // header: 02 0E 80 === 2, 15, 128
        // params: 08 01 12 08 08 00 10 00 18 01 20 00 1A 11 === 8, 1, 18, 8, 8, 0, 16, 0, 24, 1, 32, 0, 26, 17
        // date: 31 36 36 32 37 32 38 32 38 31 2E 32 32 37 36 34 31 === 49, 54, 54, 50, 48, 52, 52, 56, 52, 50, 46, 57, 54, 48, 48, 50, 50
        const data = [2, PbPFtpQuery.REQUEST_START_RECORDING, 128, ...params, ...timestampNumbers]
        // console.log('data', data)
        const payload = await this.getConfiguration(numbersToDataView(data))
        await this.requestRecordingStatus()
        return true
    }

    async removeExercise() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        await this.requestRecordingStatus()
        if (this.properties.recording === true) {
            return false
        }
        if (this.properties.exercise === null) {
            return false
        }
        // REMOVE ONE SPECIFIC FILE
        // 02 22 00 08 00 12 + 1E (la taille) + 2F (espace ou /) + LADATE + 2F (espace ou /) +  53 41 4D 50 4C 45 53 2E 42 50 42 (SAMPLES.BPB)
        const command = this.getExerciceFrame(true)
        const payload = await this.getConfiguration(command)
        await this.requestRecordingStatus()
        return true
    }

    async fetchExercise() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        await this.requestRecordingStatus()
        if (this.properties.recording === true) {
            return false
        }
        if (this.properties.exercise === null) {
            return false
        }
        // Value: 02 22 00 08 00 12 1E 2F 31 36 37 39 33 30 31 31 33 36 2E 35 39 35 35 31 36 2F 53 41 4D 50 4C 45 53 2E 42 50 42
        const command = this.getExerciceFrame(false)
        const payload = await this.getConfiguration(command)
        // console.log('payload', payload)
        // 10,2,24,1,18,218,6
        //    2, 8,0,18,  0
        this.properties.exerciseContent = payload.slice(7) //to remove the header
        // 858
        // 255
        // 3
        await this.requestRecordingStatus()
        return true
    }

    async listExercises() {
        if (!this.properties.ready) {
            console.log('not ready')
            return false
        }
        await this.requestRecordingStatus()
        // if (this.properties.recording === true) {
        //     return false
        // }
        // if (this.properties.exercise === null) {
        //     return false
        // }

        // REMOVE ALL
        // 02 05 00 08 00 12 01 2F
        // const header = await this.getConfiguration(hexStringToDataView('02 05 00 08 00 12 01 2F'))
        // dataViewToText
        // console.log(
        //     dataViewToHexString(
        //         numbersToDataView([
        //             2, 10, 14, 10, 10, 68, 69, 86, 73, 67, 69, 46, 66, 80, 66, 16, 127, 10, 17, 10, 12, 69, 82, 82, 79,
        //             82, 76, 79, 71, 46, 66, 80, 66, 16, 181, 11, 10, 22, 10, 18, 49, 54, 55, 57, 51, 49, 49, 49, 49, 54,
        //             46, 49, 55, 51, 48, 48, 48, 47, 16, 0,
        //         ])
        //     )
        // )
        //TODO FINIR DE COMPRENDRE CETTE CHAINE ENTRE AUTRE LES VALEURS ENTRE LES CHAINES
        // 2  10 14 10 10 68 69 86 73 67 69 46 66 80 66 16 127 10 17 10 12 69 82 82 79 82 76 79 71 46 66 80 66 16 181 11 10 22 10 18 49 54 55 57 51 49 49 49 49 54 46 49 55 51 48 48 48 47 16 0
        // 02 0A 0E 0A 0A 44 45 56 49 43 45 2E 42 50 42 10 7F  0A 11 0A 0C 45 52 52 4F 52 4C 4F 47 2E 42 50 42 10  B5 0B 0A 16 0A 12 31 36 37 39 33 31 31 31 31 36 2E 31 37 33 30 30 30 2F 10 00
        // LA DATE : 49 54 55 57 51 49 49 49 49 54 46 49 55 51 48 48 48
        // 0A 16 0A = (16 ou autre est un id?) SEPARATEUR PUIS TAILLE du paquet qui suis
        // 44 45 56 49 43 45 2E 42 50 42 = DEVICE.BPB
        // 45 52 52 4F 52 4C 4F 47 2E 42 50 42 = ERRORLOG.BPB
        // 31 36 37 39 33 31 31 31 31 36 2E 31 37 33 30 30 30 2F = 1679311116.173000/
        // 00 FIN

        await this.requestRecordingStatus()
    }

    async test(numelo) {
        let res = ''

        // /**
        //  * Characteristic properties
        //  */https://github.com/polarofficial/polar-ble-sdk/blob/39c809ab4a3398d3ea88ff8a0cc6f6d44cd58bda/sources/Android/android-communications/library/src/main/java/com/polar/androidcommunications/api/ble/model/gatt/BleGattBase.java#L302
        // public static final int PROPERTY_BROADCAST = 0x01;
        // public static final int PROPERTY_READ = 0x02;
        // public static final int PROPERTY_WRITE_NO_RESPONSE = 0x04;
        // public static final int PROPERTY_WRITE = 0x08;
        // public static final int PROPERTY_NOTIFY = 0x10;
        // public static final int PROPERTY_INDICATE = 0x20;
        // public static final int PROPERTY_SIGNED_WRITE = 0x40;
        // public static final int PROPERTY_EXTENDED_PROPS = 0x80;

        // 58 35 4D 24
        // 58 35 4D 24 => 88 53 77 36 => ID DU CAPTEUR
    }
    async stop() {}

    async getConfiguration(dataview) {
        try {
            await BleClient.writeWithoutResponse(this.device.deviceId, PSFTP_SERVICE, MTU_CHARACTERISTIC, dataview)
        } catch (err) {
            console.log('getConfiguration polar error', err)
        }
        const promise = new Promise((resolve, reject) => {
            this.promises.configuration = { resolve, reject }
        })
        return promise
    }

    getExerciceFrame(deleted = false) {
        const action = deleted ? '03' : '00' // 03 delete ; 00 fetch
        const header = ['02', '22', '00', '08', action, '12']
        const timestampString = (this.properties.exercise / 1000).toFixed(6)
        const dateHexa = dataViewToHexString(textToDataView(timestampString)).split(' ')
        const path = ['2F', ...dateHexa, '2F', ...this.sampleHexa]
        const size = dataViewToHexString(numbersToDataView([path.length]))
        const command = [...header, size, ...path].join(' ')
        // console.log('command', command)
        return hexStringToDataView(command)
    }
}
