import store from '@/store'
import { i18n } from '@/i18n'
const production = import.meta.env.PROD

/**
 * Runs when the axios instance tries
 * to make an HTTP request.
 */
export const onRequest = (config) => {
    // We need to append the authentication
    // token to the request in case the
    // user is authenticated.
    if (store.getters['auth/loggedIn']) {
        config.headers.Authorization = `Bearer ${store.state.auth.token}`
    }
    config.headers['X-localization'] = i18n.global.locale.value.substr(0, i18n.global.locale.value.indexOf('-'))
    // We also need to indicate to the application
    // that we are performing an HTTP request.
    store.commit('other/request', true)
    return config
}

/**
 * Runs when the axios instance fails
 * to perform an HTTP request.
 */
export const onRequestError = (error) => {
    store.commit('other/request', false)
    return Promise.reject(error)
}

/**
 * Runs when the axios instance finishes
 * performing an HTTP request and have
 * the response ready.
 */
export const onResponse = (response) => {
    store.commit('other/request', false)
    return response
}

/**
 * Runs when the axios instance fails with
 * the HTTP response.
 */
export const onResponseError = (error) => {
    store.commit('other/request', false)
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const data = error.response?.data
        if (data) {
            // The given data was invalid.
            if (data.errors) {
                error.message = Object.values(data?.errors).flat().join('<br />')
            } else if (data.message) {
                error.message = data.message
            }
        }
        //error.config.url === '/user' to prevent infinite loop
        if (error.response.status === 401 && error.config.url !== '/user') {
            // auth.js initialCheck() redirect to login page
            if (store.state.auth.initialCheckDone || !production) location.reload()
            else console.warn('Reload skipped', error.config.url)
        }
    } else {
        if (!navigator.onLine) {
            error.message = 'Looks like you are offline.'
        }
    }
    return Promise.reject(error)
}
