import { ref, computed, watch } from 'vue'

import dayjs from 'dayjs'
import { format } from '@/lib/helpers/time'

// export const calendarProps = {
//     to: { type: [Object, String], required: false },
//     action: { type: Function, required: false },
// }

// global state, created in module scope

export default () => {
    // local state, created per-component

    const defaultPeriod = 'month'
    const periodLocal = ref(defaultPeriod)
    const period = computed({
        get() {
            return periodLocal.value
        },
        set(newValue) {
            if (['day', 'month', 'week', 'year'].indexOf(newValue) !== -1) {
                periodLocal.value = newValue
            } else {
                periodLocal.value = defaultPeriod
            }
        },
    })

    const defaultDateSelected = computed(() => dayjs(new Date()).startOf(period.value))
    const dateSelectedLocal = ref(defaultDateSelected.value)
    const dateSelected = computed({
        get() {
            return dateSelectedLocal.value.toDate()
        },
        set(newValue) {
            if (dayjs(newValue).isValid()) {
                dateSelectedLocal.value = dayjs(newValue).startOf(period.value)
            } else {
                dateSelectedLocal.value = defaultDateSelected.value
            }
        },
    })

    const numberOfDays = computed(() => {
        switch (period.value) {
            case 'day':
                return 1
            case 'month':
                return 42
            case 'week':
                return 7
            case 'year':
                return 365
        }
    })

    const getFirstDay = (date) => date.startOf('week').toDate()
    const getLastDay = (date, days) => date.startOf('week').add(days, 'day').toDate()

    const firstCalendarDay = computed(() => getFirstDay(dateSelectedLocal.value))
    const lastCalendarDay = computed(() => getLastDay(dateSelectedLocal.value, numberOfDays.value))
    const calendarDateFrom = computed(() => format(firstCalendarDay.value, 'YYYY-MM-DD'))
    const calendarDateTo = computed(() => format(lastCalendarDay.value, 'YYYY-MM-DD'))

    /**
     *
     * methods
     *
     **/

    const gotoToday = () => {
        dateSelectedLocal.value = defaultDateSelected.value
    }

    const getNextPeriodDate = (date, p) => date.add(1, p)
    const getPreviousPeriodDate = (date, p) => date.subtract(1, p)

    const incrementPeriod = () => {
        dateSelectedLocal.value = getNextPeriodDate(dateSelectedLocal.value, period.value)
    }

    const decrementPeriod = () => {
        dateSelectedLocal.value = getPreviousPeriodDate(dateSelectedLocal.value, period.value)
    }

    const getPreviousCalendarRange = () => {
        const prevDate = getPreviousPeriodDate(dateSelectedLocal.value, period.value)
        return [getFirstDay(prevDate), getLastDay(prevDate, numberOfDays.value)]
    }

    return {
        //properties
        period,
        numberOfDays,
        dateSelected,
        firstCalendarDay,
        lastCalendarDay,
        calendarDateFrom,
        calendarDateTo,
        //method
        gotoToday,
        incrementPeriod,
        decrementPeriod,
        getPreviousCalendarRange,
    }
}
