import { i18n } from '@/i18n'

import { phaseOfDay } from '@/lib/helpers/time'
import { capitalizeFirstLetter } from '@/lib/helpers/text'

const getSessionName = (session) => {
    let name = ''

    if (session?.start_at) {
        const wordPhaseOfDay = phaseOfDay(session?.start_at)
        const rideOrDiscipline = session?.discipline
            ? i18n.global.t(`session.discipline.${session?.discipline}`)
            : i18n.global.t('session.ride')

        name = session?.name
            ? session?.name
            : capitalizeFirstLetter(
                  i18n.global.t(`session.${wordPhaseOfDay}-ride-discipline`, {
                      rideOrDiscipline: rideOrDiscipline.toLowerCase(),
                  })
              )
    }

    return name
}

export { getSessionName }
