<template>
    <div
        class="flex whitespace-nowrap gap-1 items-center"
        :class="{
            'text-xs': textSize === 'xs',
            'text-sm': textSize === 'sm',
            'text-md': textSize === 'md',
            'text-lg': textSize === 'lg',
            'text-xl': textSize === 'xl',
        }"
    >
        <div v-if="titlePosition === 'start'" class="text-text-darker">
            {{ title }}
        </div>

        <div v-if="!allowZero && isNullOrZero(formatDisplayedValue.value)">-</div>
        <div v-else-if="formatDisplayedValue.unit && displayUnit">
            {{ formatDisplayedValue.value }} {{ formatDisplayedValue.unit }}
        </div>
        <div v-else> {{ formatDisplayedValue.value }} </div>

        <div v-if="titlePosition === 'end'" class="text-text-darker">
            {{ title }}
        </div>

        <slot name="details" />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { convertValueUnit } from '@/lib/helpers/convert'
import { isNullOrZero } from '@/lib/helpers/numeric'

const props = defineProps({
    value: { type: [String, Number], default: '' },
    decimals: { type: Number, default: undefined },
    unit: { type: String, default: '' },
    userUnit: { type: String, default: null },
    title: { type: [String, Boolean], default: '' },
    allowZero: { type: Boolean, default: false },
    forceUnit: { type: Boolean, default: false },
    displayUnit: { type: Boolean, default: true },
    textSize: { type: String, default: 'xs' },
    titlePosition: { type: String, default: 'start' },
})
const formatDisplayedValue = computed(() =>
    convertValueUnit(props.value, props.unit, props.userUnit, props.decimals, props.forceUnit)
)
</script>
