// https://github.com/pareeknikhil/biofeedback/blob/master/Polar%20Device%20Data%20Stream/Accelerometer/main.py
// https://github.com/pareeknikhil/biofeedback/blob/master/Polar%20Device%20Data%20Stream/ECG/main.py
// https://towardsdatascience.com/creating-a-data-stream-with-polar-device-a5c93c9ccc59
// https://medium.com/swlh/creating-an-accelerometer-data-stream-with-polar-device-450a223f5789
// https://github.com/polarofficial/polar-ble-sdk/blob/master/technical_documentation/SdkModeExplained.md

import { ref, reactive, computed } from 'vue'
import { BleClient, numbersToDataView, dataViewToNumbers } from '@capacitor-community/bluetooth-le'
import Memory from '@/lib/memory'
import { exportAndSaveCsv } from '@/lib/helpers/export'

import PolarFeaturesConfiguration from './polar/PolarFeaturesConfiguration'
import PolarSimpleFileTransferProtocol from './polar/PolarSimpleFileTransferProtocol'

export default ({}) => {
    // ************************************************
    // ****************  properties ****************
    // ***********************************************

    //HEART_RATE_SERVICE
    const HEART_RATE_SERVICE = '0000180d-0000-1000-8000-00805f9b34fb'
    const HEART_RATE_MEASUREMENT_CHARACTERISTIC = '00002a37-0000-1000-8000-00805f9b34fb'
    const BODY_SENSOR_LOCATION_CHARACTERISTIC = '00002a38-0000-1000-8000-00805f9b34fb' //0 Other, 1 Chest, 2 Wrist, 3 Finger, 4 Hand, 5 Ear Lobe, 6 Foot, 7 - 255 Reserved for future use
    //USER_DATA_SERVICE
    const USER_DATA_SERVICE = '0000181c-0000-1000-8000-00805f9b34fb'
    const DATABASE_CHANGE_INCREMENT_CHARACTERISTIC = '00002a99-0000-1000-8000-00805f9b34fb'
    const USER_INDEX_CHARACTERISTIC = '00002a9a-0000-1000-8000-00805f9b34fb'
    const USER_CONTROL_POINT_CHARACTERISTIC = '00002a9f-0000-1000-8000-00805f9b34fb'
    const FIRST_NAME_CHARACTERISTIC = '00002a8a-0000-1000-8000-00805f9b34fb'
    const LAST_NAME_CHARACTERISTIC = '00002a90-0000-1000-8000-00805f9b34fb'
    const AGE_CHARACTERISTIC = '00002a80-0000-1000-8000-00805f9b34fb'
    const GENDER_CHARACTERISTIC = '00002a8c-0000-1000-8000-00805f9b34fb'
    const WEIGHT_CHARACTERISTIC = '00002a98-0000-1000-8000-00805f9b34fb'
    const HEIGHT_CHARACTERISTIC = '00002a8e-0000-1000-8000-00805f9b34fb'
    const LANGUAGE_CHARACTERISTIC = '00002aa2-0000-1000-8000-00805f9b34fb'
    //DEVICE_INFORMATION_SERVICE
    const DEVICE_INFORMATION_SERVICE = '0000180a-0000-1000-8000-00805f9b34fb'
    const MANUFACTURER_NAME_STRING_CHARACTERISTIC = '00002a29-0000-1000-8000-00805f9b34fb'
    const MODEL_NBR_STRING_CHARACTERISTIC = '00002a24-0000-1000-8000-00805f9b34fb'
    const SERIAL_NUMBER_STRING_CHARACTERISTIC = '00002a25-0000-1000-8000-00805f9b34fb'
    const HARDWARE_REVISION_STRING_CHARACTERISTIC = '00002a27-0000-1000-8000-00805f9b34fb'
    const FIRMWARE_REVISION_STRING_CHARACTERISTIC = '00002a26-0000-1000-8000-00805f9b34fb'
    const SOFTWARE_REVISION_STRING_CHARACTERISTIC = '00002a28-0000-1000-8000-00805f9b34fb'
    const SYSTEM_ID_CHARACTERISTIC = '00002a23-0000-1000-8000-00805f9b34fb'
    // public static final UUID IEEE_11073_20601 = UUID.fromString("00002a2a-0000-1000-8000-00805f9b34fb");
    // public static final UUID PNP_ID = UUID.fromString("00002a50-0000-1000-8000-00805f9b34fb");

    // GAP / Generic Access Profile
    // public static UUID GAP_SERVICE = UUID.fromString("00001800-0000-1000-8000-00805f9b34fb");
    // public static UUID GAP_DEVICE_NAME_CHARACTERISTIC = UUID.fromString("00002a00-0000-1000-8000-00805f9b34fb");
    // public static UUID GAP_APPEARANCE_CHARACTERISTIC = UUID.fromString("00002a01-0000-1000-8000-00805f9b34fb");

    // PFC / Polar Features Configuration
    // public static final UUID PFC_SERVICE = UUID.fromString("6217FF4B-FB31-1140-AD5A-A45545D7ECF3"); /* Polar Features Configuration Service (PFCS)*/
    // public static final UUID PFC_FEATURE = UUID.fromString("6217FF4C-C8EC-B1FB-1380-3AD986708E2D");
    // public static final UUID PFC_CP = UUID.fromString("6217FF4D-91BB-91D0-7E2A-7CD3BDA8A1F3");
    // https://github.com/polarofficial/polar-ble-sdk/blob/b81d1547f0b9d17682c59b708e5b33e9469452e1/sources/Android/android-communications/library/src/main/java/com/polar/androidcommunications/api/ble/model/gatt/client/BlePfcClient.java

    // PSD
    // public static final UUID PSD_SERVICE = UUID.fromString("FB005C20-02E7-F387-1CAD-8ACD2D8DF0C8");
    // public static final UUID PSD_FEATURE = UUID.fromString("FB005C21-02E7-F387-1CAD-8ACD2D8DF0C8");
    // public static final UUID PSD_CP = UUID.fromString("FB005C22-02E7-F387-1CAD-8ACD2D8DF0C8");
    // public static final UUID PSD_PP = UUID.fromString("FB005C26-02E7-F387-1CAD-8ACD2D8DF0C8");
    // public static final byte OP_CODE_START_ECG_STREAM = 0x01;
    // public static final byte OP_CODE_STOP_ECG_STREAM = 0x02;
    // public static final byte OP_CODE_START_OHR_STREAM = 0x03;
    // public static final byte OP_CODE_STOP_OHR_STREAM = 0x04;
    // public static final byte OP_CODE_START_ACC_STREAM = 0x05;
    // public static final byte OP_CODE_STOP_ACC_STREAM = 0x06;
    // public static final byte RESPONSE_CODE = (byte) 0xF0;
    // https://github.com/polarofficial/polar-ble-sdk/blob/b81d1547f0b9d17682c59b708e5b33e9469452e1/sources/Android/android-communications/library/src/main/java/com/polar/androidcommunications/api/ble/model/gatt/client/BlePsdClient.java

    //BATTERY_SERVICE
    const BATTERY_SERVICE = '0000180f-0000-1000-8000-00805f9b34fb'
    const BATTERY_CHARACTERISTIC = '00002a19-0000-1000-8000-00805f9b34fb'
    //PFC_SERVICE
    const PFC_SERVICE = '6217ff4b-fb31-1140-ad5a-a45545d7ecf3'
    const PFC_CHARACTERISTIC_FEATURE = '6217ff4c-c8ec-b1fb-1380-3ad986708e2d'
    const PFC_CHARACTERISTIC_CONFIGURATION = '6217ff4d-91bb-91d0-7e2a-7cd3bda8a1f3'
    //PSFTP_SERVICE
    const PSFTP_SERVICE = '0000feee-0000-1000-8000-00805f9b34fb'
    const MTU_CHARACTERISTIC = 'fb005c51-02e7-f387-1cad-8acd2d8df0c8'
    const D2H_NOTIFICATION_CHARACTERISTIC = 'fb005c52-02e7-f387-1cad-8acd2d8df0c8'
    const H2D_NOTIFICATION_CHARACTERISTIC = 'fb005c53-02e7-f387-1cad-8acd2d8df0c8'

    // ## UUID for connection establsihment with device ##
    const POLAR_PMD_SERVICE = 'fb005c80-02e7-f387-1cad-8acd2d8df0c8'
    // ## UUID for Request of stream settings ##
    const POLAR_PMD_CONTROL_POINT_CHARACTERISTIC = 'fb005c81-02e7-f387-1cad-8acd2d8df0c8'
    // ## UUID for Request of start stream ##
    const POLAR_PMD_DATA_CHARACTERISTIC = 'fb005c82-02e7-f387-1cad-8acd2d8df0c8'

    // https://github.com/polarofficial/polar-ble-sdk
    // https://github.com/polarofficial/polar-ble-sdk/blob/master/technical_documentation/SdkModeExplained.md
    // console.log(new Uint8Array(PPG_WRITE.map((hex) => parseInt(hex, 16))))

    // const PPG_WRITE = [0x02, 0x01, 0x00, 0x01, 0x82, 0x00, 0x01, 0x01, 0x0e, 0x00]
    // const PPG_WRITE_INT = [2, 1, 0, 1, 48, 0, 1, 1, 20, 0]
    // the supported resolution for PPG stream in Polar OH1 is 22 (0x16)
    // const PPG_WRITE = [0x02, 0x01, 0x00, 0x01, 0x82, 0x00, 0x01, 0x01, 0x16, 0x00]
    const PPG_WRITE_INT = [2, 1, 0, 1, 48, 0, 1, 1, 34, 0]

    // ## UUID for Request of ECG Stream ##
    // const ECG_WRITE = ['0x02', '0x00', '0x00', '0x01', '0x82', '0x00', '0x01', '0x01', '0x0e', '0x00']
    const ECG_WRITE_INT = [2, 0, 0, 1, 130, 0, 1, 1, 14, 0]

    // ## UUID for Request of ACC Stream ##
    // const ACC_WRITE = [
    //     '0x02',
    //     '0x02',
    //     '0x00',
    //     '0x01',
    //     '0xC8',
    //     '0x00',
    //     '0x01',
    //     '0x01',
    //     '0x10',
    //     '0x00',
    //     '0x02',
    //     '0x01',
    //     '0x08',
    //     '0x00',
    // ]
    const ACC_WRITE_INT = [2, 2, 0, 1, 200, 0, 1, 1, 16, 0, 2, 1, 8, 0]

    // ## For Polar H10  sampling frequency ##
    const ECG_SAMPLING_FREQ = 130
    // ax.set_xlim(left=n - 130, right=n)
    //     n = n + 130

    // ## For Polar H10  sampling frequency ##
    const ACC_SAMPLING_FREQ = 200

    const initialSensorProperties = {
        battery: '',
        name: '',
        location: '',
        heartrate: '',
        rrsMs: '',
        heartrateTimestamp: '',
        ecg: '',
        ecgTimestamp: '',
    }
    const properties = reactive({ ...initialSensorProperties })

    let device = undefined

    /**
     * Check whether a sensor is connected
     */
    const isBusy = ref(false)

    /**
     * Check whether a sensor is recording
     */
    const PFC = ref(undefined)
    const PSFTP = ref(undefined)
    /**
     * Check whether a sensor connected is a Polar device
     */
    const isPolar = computed(
        () => properties.name?.toLowerCase().includes('polar') && properties.name?.toLowerCase().includes('h10')
    )

    const isRecording = computed(() => !!PSFTP.value?.properties?.recording)
    const hasRecord = computed(() => !!PSFTP.value?.properties?.exercise)
    const isReadyToFetchRecord = computed(() => !isRecording.value && hasRecord.value)
    const isMultiConnectionEnabled = computed(() => !!PFC?.value?.features?.MULTI_CONNECTION?.value)
    /**
     * Check whether a sensor is connected
     */
    const isConnected = ref(false)

    let lastConnectedId = ref(undefined)

    const requestBleDeviceOptions = {
        // namePrefix: 'Polar',
        services: [HEART_RATE_SERVICE],
        optionalServices: [
            BATTERY_SERVICE,
            // USER_DATA_SERVICE,
            // DEVICE_INFORMATION_SERVICE,
            // POLAR_ELECTRO_OY_SERVICE,
            POLAR_PMD_SERVICE,
            // BODY_SENSOR_LOCATION_CHARACTERISTIC,
            PSFTP_SERVICE,
            PFC_SERVICE,
        ],
    }

    let driver = 'heartrate'
    // ************************************************
    // **************** constructor ****************
    // ***********************************************
    Memory.get('lastConnectedHeartrateId').then((deviceId) => {
        // console.log('### lastConnectedId', deviceId)
        lastConnectedId.value = deviceId
    })
    // ************************************************
    // **************** methods ****************
    // ***********************************************
    function getUint64(dataview, byteOffset, littleEndian) {
        // on décompose la valeur 64 sur bits en deux nombres 32 bits
        const gauche = dataview.getUint32(byteOffset, littleEndian)
        const droite = dataview.getUint32(byteOffset + 4, littleEndian)

        // on combine les deux valeurs 32 bits
        const combinaison = littleEndian ? gauche + 2 ** 32 * droite : 2 ** 32 * gauche + droite
        // if (!Number.isSafeInteger(combinaison)) {
        //     console.warn(combinaison, ' dépasse MAX_SAFE_INTEGER : perte de précision !')
        // }
        return combinaison
    }

    /**
     * Open a connection to a heartrate
     */
    const connect = async () => {
        return new Promise(async (resolve, _) => {
            // catch if not supported
            try {
                await BleClient.stopLEScan()
            } catch (error) {
                // console.log('### debug', error)
            }
            BleClient.requestDevice(requestBleDeviceOptions)
                .then(connectDevice)
                .catch(async (error) => {
                    // The user didn't select a device.
                    console.log('connectSensor', error)
                    await disconnect()
                })
                .finally(() => {
                    isBusy.value = false
                    resolve(isConnected.value)
                })
        })
    }
    /**
     * Close a connection to a sensor
     */
    const disconnect = async (forgetDevice = false) => {
        isBusy.value = true
        try {
            if (forgetDevice) {
                Memory.remove('lastConnectedHeartrateId')
                lastConnectedId.value = undefined
            }
            if (device?.deviceId) {
                await BleClient.stopNotifications(
                    device.deviceId,
                    HEART_RATE_SERVICE,
                    HEART_RATE_MEASUREMENT_CHARACTERISTIC
                )
                await BleClient.disconnect(device.deviceId)
            }
            console.log('disconnected from device', device)
        } catch (error) {
            console.log('disconnect hr error', error)
        } finally {
            Object.assign(properties, initialSensorProperties)
            device = undefined
            isConnected.value = false
            isBusy.value = false
        }
    }

    const onDisconnect = (deviceId) => {
        //we do that before disconnect() because we already know the device is not link to the sensor
        device = undefined
        disconnect()
        console.log(`device ${deviceId} disconnected`)
    }

    const connectDevice = async (newDevice) => {
        if (isBusy.value === true) return
        if (isConnected.value === true && lastConnectedId.value === newDevice.deviceId) {
            console.log('fix android reconnect')
            return
        }
        isBusy.value = true
        device = newDevice
        if (lastConnectedId.value !== device.deviceId) {
            lastConnectedId.value = device.deviceId
            await Memory.set('lastConnectedHeartrateId', device.deviceId)
        }
        properties.name = device.name
        try {
            // connect to device, the onDisconnect callback is optional
            await BleClient.disconnect(device.deviceId)
            await BleClient.connect(device.deviceId, (deviceId) => onDisconnect(deviceId))
            // console.log('connected to device', device)

            // const location = await BleClient.read(
            //     device.deviceId,
            //     HEART_RATE_SERVICE,
            //     BODY_SENSOR_LOCATION_CHARACTERISTIC
            // )
            // console.log('body sensor location', location.getUint8(0))
            // properties.location = location.getUint8(0)

            //some device does not have a battery service
            try {
                const battery = await BleClient.read(device.deviceId, BATTERY_SERVICE, BATTERY_CHARACTERISTIC)
                console.log('hr battery level', battery.getUint8(0))
                properties.battery = battery.getUint8(0)
            } catch (error) {}
            // fetch all services available
            // const info = await BleClient.getServices(device.deviceId)
            // console.log('info', info)

            // const PMD = await BleClient.read(
            //     device.deviceId,
            //     POLAR_PMD_SERVICE,
            //     POLAR_PMD_CONTROL_POINT_CHARACTERISTIC
            // )
            // by default
            // [15, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            // console.log('PMD', dataViewToNumbers(PMD))
            if (isPolar.value) {
                await BleClient.write(
                    device.deviceId,
                    POLAR_PMD_SERVICE,
                    POLAR_PMD_CONTROL_POINT_CHARACTERISTIC,
                    numbersToDataView([1, 0])
                )
            }
            // // PPG_WRITE_INT DOES NOT WORK
            // await BleClient.write(
            //     device.deviceId,
            //     POLAR_PMD_SERVICE,
            //     POLAR_PMD_CONTROL_POINT_CHARACTERISTIC,
            //     numbersToDataView(PPG_WRITE_INT)
            // )
            // ECG_WRITE_INT 229
            if (isPolar.value) {
                await BleClient.write(
                    device.deviceId,
                    POLAR_PMD_SERVICE,
                    POLAR_PMD_CONTROL_POINT_CHARACTERISTIC,
                    numbersToDataView(ECG_WRITE_INT)
                )
            }
            // // ACC_WRITE_INT 226
            // await BleClient.write(
            //     device.deviceId,
            //     POLAR_PMD_SERVICE,
            //     POLAR_PMD_CONTROL_POINT_CHARACTERISTIC,
            //     numbersToDataView(ACC_WRITE_INT)
            // )

            await BleClient.startNotifications(
                device.deviceId,
                HEART_RATE_SERVICE,
                HEART_RATE_MEASUREMENT_CHARACTERISTIC,
                (value) => {
                    const flags = value.getUint8(0)
                    // console.log('### HR', value, flags)

                    let heartrate
                    let RRin1024Unit
                    // let energy
                    const rate16Bits = flags & 0x1

                    if (rate16Bits > 0) {
                        heartrate = value.getUint16(1, true)
                        if (value.byteLength > 4) {
                            RRin1024Unit = value.getUint16(3, true)
                        }
                    } else {
                        heartrate = value.getUint8(1)
                        if (value.byteLength > 3) {
                            RRin1024Unit = value.getUint16(2, true)
                        }
                    }

                    /**
                     * Code bellow does not work, I don't know why the flags field is not the same as the documention
                     * https://github.com/polarofficial/polar-ble-sdk/blob/master/sources/Android/android-communications/library/src/test/java/com/polar/androidcommunications/api/ble/model/gatt/client/BleHrClientTest.kt
                    switch (flags) {
                        // HEX: 00 FF
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x00
                        // Heart rate value format bit:     0 (uint8)
                        // 1:       Heart Rate Measurement Value field      size 1:     0xFF
                        case 0: // hr 0x00
                            heartrate = value.getUint8(1)
                            break
                        // HEX: 01 80 80
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x01
                        // Heart rate value format bit      1 (uint16)
                        // 1..2:    Heart Rate Measurement Value field      size 2:     0x80 0x80 (32896)
                        case 1: // hr 0x01
                            heartrate = value.getUint16(1, true)
                            break
                        // HEX: 09 00 00 FF FF
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x09
                        // Heart rate value format bit      1 (uint16)
                        // Energy Expended Status bit       1 (Energy Expended field is present)
                        // 1..2:    Heart Rate Measurement Value field      size 2:     0x00 0x00
                        // 3..4:    Energy Expended field                   size 2:     0xFF 0xFF
                        case 9: // energy expended 0x09
                            heartrate = value.getUint16(1, true)
                            energy = value.getUint16(3, true)
                            break
                        // HEX: 08 00 7F 80
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x08
                        // Heart rate value format bit      0 (uint8)
                        // Energy Expended Status bit       1 (Energy Expended field is present)
                        // 1:       Heart Rate Measurement Value field      size 1:     0x00
                        // 2..3:    Energy Expended field                   size 2:     0x7F 0x80
                        case 8: // energy expended 0x08
                            heartrate = value.getUint8(1)
                            energy = value.getUint16(2, true)
                            break
                        // HEX: 10 00 FF FF
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x10
                        // Heart rate value format bit      0 (uint8)
                        // RR-interval bit                  1 (RR-Interval values are present)
                        // 1:       Heart Rate Measurement Value field      size 1:     0x00
                        // 2..3:    RR-Interval Field                       size 2:     0xFF 0xFF
                        case 16: // rr 0x10
                            heartrate = value.getUint8(1)
                            RRin1024Unit = value.getUint16(2, true)
                            break
                        // HEX: 11 00 00 FF FF 7F 80
                        // index    type                                                data:
                        // 0:       Flags field                             size 1:     0x11
                        // Heart rate value format bit      1 (uint16)
                        // RR-interval bit                  1 (RR-Interval values are present)
                        // 1..2:    Heart Rate Measurement Value field      size 1:     0x00 0x00
                        // 3..4:    RR-Interval Field                       size 2:     0xFF 0xFF
                        // 5..6:    RR-Interval Field                       size 2:     0x7F 0x80
                        case 17: // rr 0x11
                            heartrate = value.getUint16(1, true)
                            RRin1024Unit = value.getUint16(3, true)
                            // RRin1024Unit = value.getUint16(5, true)//IGNORED but we should use it
                            break
                    }
                    */

                    // console.log(`### HR ${heartrate} RRin1024Unit ${RRin1024Unit}`, new Date().getSeconds())
                    if (RRin1024Unit) {
                        properties.rrsMs = Math.round(RRin1024Unit / 1.024)
                    }
                    properties.heartrate = heartrate
                    properties.heartrateTimestamp = Date.now()
                    return
                }
            )

            //some device does not have a battery service
            try {
                await BleClient.startNotifications(
                    device.deviceId,
                    BATTERY_SERVICE,
                    BATTERY_CHARACTERISTIC,
                    (value) => {
                        console.log('hr battery level', value.getUint8(0))
                        properties.battery = value.getUint8(0)
                    }
                )
            } catch (error) {}

            try {
                if (isPolar.value) {
                    PFC.value = new PolarFeaturesConfiguration(device)
                    await PFC.value.start()
                    PSFTP.value = new PolarSimpleFileTransferProtocol(device)
                    await PSFTP.value.start()
                }
            } catch (error) {
                console.log('PolarConfiguration error', error)
            }

            // ask permissions
            // await BleClient.startNotifications(
            //     device.deviceId,
            //     POLAR_ELECTRO_OY_SERVICE,
            //     TBD5_1_CHARACTERISTIC,
            //     (value) => {
            //         console.log('TBD5_1_CHARACTERISTIC', value)
            //     }
            // )
            // ask permissions
            // await BleClient.startNotifications(
            //     device.deviceId,
            //     POLAR_ELECTRO_OY_SERVICE,
            //     TBD5_2_CHARACTERISTIC,
            //     (value) => {
            //         console.log('TBD5_2_CHARACTERISTIC', value)
            //     }
            // )
            if (isPolar.value) {
                await BleClient.startNotifications(
                    device.deviceId,
                    POLAR_PMD_SERVICE,
                    POLAR_PMD_DATA_CHARACTERISTIC,
                    (value) => {
                        // https://github.com/polarofficial/polar-ble-sdk/blob/master/technical_documentation/Polar_Measurement_Data_Specification.pdf
                        // console.log('### ECG', new Date().getSeconds())
                        // console.log(dataViewToHexString(value).split(' '))
                        //!!!!!!!!!!!!!!!!!!!!!!!!!
                        //!!!!!!!!!!!!!!!!!!!!!!!!!
                        //!!!!!!!!!!!!!!!!!!!!!!!!!
                        const flags = value.getUint8(0)
                        const rate16Bits = flags & 0x1

                        // the time of the sensor is set to default value when sensor resets or battery runs out.
                        // For the Polar H10 (v.3.1.1) the default time is Jan 01 2019 00:00:00 (GMT),
                        // which in nanosecond format is 599616000000000000
                        // 1000000000 nanoseconds === 1 second
                        // to convert to a human readable timestamp apply an Offset in nanoseconds is 946684800000000000
                        const timestamp = getUint64(value, 1, true)
                        const length = value.byteLength
                        if (flags === 0) {
                            // ECG
                            const step = 3 //getInt16
                            let offset = 10
                            let values = []
                            let max = -Infinity
                            while (offset < length) {
                                const ecg = value.getInt16(offset, true)
                                offset += step
                                values.push(ecg)
                                // ({ ecg, ts: timestamp })
                                // ({ ecg, ts: Date.now() })
                            }
                            properties.ecg = values
                            properties.ecgTimestamp = timestamp
                            // properties.ecgTimestamp = Date.now()
                            // console.log(flags, rate16Bits, Math.round(timestamp / 1000000000))
                        } else if (flags === 1) {
                            // PPG
                            console.log('PPG', flags, rate16Bits)
                        } else if (flags === 2) {
                            // ACC
                            console.log('ACC', flags, rate16Bits)
                        } else {
                            console.log('other', flags, rate16Bits)
                        }
                    }
                )
            }
            isConnected.value = true
        } catch (error) {
            console.log('connectDevice hr error', error)
            disconnect()
        }
        isBusy.value = false
    }

    const getExercise = async () => {
        const status = await PSFTP.value?.fetchExercise()
        if (!status) {
            return false
        } else {
            let arrData = Array.from(PSFTP.value?.properties?.exerciseContent)
            arrData = arrData.map((value, time) => {
                return { time: time + 1, value }
            })
            return arrData
        }
    }

    const saveToMemory = async () => {
        const arrData = await getExercise()
        if (!arrData) {
            alert('Error')
            return
        }
        const exercise = PSFTP.value?.properties?.exercise
        const filename = `bpm-${exercise}`

        exportAndSaveCsv(arrData, filename)
    }

    return {
        properties,
        PFC,
        PSFTP,
        driver,
        isBusy,
        isPolar,
        isRecording,
        hasRecord,
        isReadyToFetchRecord,
        isMultiConnectionEnabled,
        isConnected,
        connect,
        connectDevice,
        disconnect,
        lastConnectedId,
        getExercise,
        saveToMemory,
    }
}
