/**
 * RFC76 and RFC 60 related utils
 */

export const RFC77_PFTP_SERVICE = '0000FEEE-0000-1000-8000-00805f9b34fb'
export const PFTP_SERVICE_16BIT_UUID = 'FEEE'
export const RFC77_PFTP_MTU_CHARACTERISTIC = 'FB005C51-02E7-F387-1CAD-8ACD2D8DF0C8'
export const RFC77_PFTP_D2H_CHARACTERISTIC = 'FB005C52-02E7-F387-1CAD-8ACD2D8DF0C8'
export const RFC77_PFTP_H2D_CHARACTERISTIC = 'FB005C53-02E7-F387-1CAD-8ACD2D8DF0C8'

export const RFC76_HEADER_SIZE = 1
export const RFC76_STATUS_MORE = 0x03
export const RFC76_STATUS_LAST = 0x01
export const RFC76_STATUS_ERROR_OR_RESPONSE = 0x00
export const MessageType = {
    REQUEST: 1,
    QUERY: 2,
    NOTIFICATION: 3,
}

export class Rfc76SequenceNumber {
    seq = 0

    getSeq() {
        return this.seq
    }

    increment() {
        if (this.seq < 0x0f) {
            this.seq += 1
        } else {
            this.seq = 0
        }
    }
}

/**
 * PSFTP EXCEPTIONS
 */
export class PftpOperationTimeout extends Error {
    constructor(detailMessage) {
        super(detailMessage)
    }
}

/**
 * one of PbPftpError codes
 */
export class PftpResponseError extends Error {
    constructor(detailMessage, error) {
        super(`${detailMessage} Error: ${error}`)
        this.error = error
    }

    getError() {
        return this.error
    }
}

export class PftpNotificationMessage {
    /**
     * One of PbPftpDevToHostNotifications
     */
    id = 0
    byteArrayOutputStream = new ByteArrayOutputStream()
}

export class PftpRfc76ResponseHeader {
    constructor() {
        this.next = 0
        this.status = 0
        this.error = 0
        this.payload = null
        this.sequenceNumber = 0
    }

    toString() {
        return `first: ${this.next} length: ${this.status} error: ${this.error} payload: ${
            this.payload != null ? new TextDecoder().decode(this.payload) : 'null'
        } seq: ${this.sequenceNumber}`
    }
}

/**
 * Compines header(protobuf typically) and data(for write operation only, for other operations = null)
 *
 * @param header typically protocol buffer data
 * @param data   content to be transmitted
 * @param type   @see MessageType
 * @param id     for query or notification only
 * @return complete message stream
 * @throws IOException thrown by IOUtils.copy
 */
export const makeCompleteMessageStream = (header, data, type, id) => {
    const outputStream = new ByteArrayOutputStream()
    // for request and query add RFC60 header
    switch (type) {
        case 'REQUEST': {
            const headerSize = header.available()
            const request = new Uint8Array(2)
            // RFC60
            request[1] = (headerSize & 0x7f00) >> 8
            request[0] = headerSize & 0x00ff
            outputStream.write(request, 0, 2)
            IOUtils.copy(header, outputStream)
            if (data != null) {
                IOUtils.copy(data, outputStream)
            }
            break
        }
        case 'QUERY': {
            const request = new Uint8Array(2)
            // RFC60
            request[1] = ((id & 0x7f00) >> 8) | 0x80
            request[0] = id & 0x00ff
            outputStream.write(request, 0, 2)
            if (header != null) {
                IOUtils.copy(header, outputStream)
            }
            break
        }
        case 'NOTIFICATION': {
            const request = new Uint8Array(1)
            request[0] = id
            outputStream.write(request, 0, 1)
            if (header != null) {
                IOUtils.copy(header, outputStream)
            }
            break
        }
    }

    return new ByteArrayInputStream(outputStream.toByteArray())
}

/**
 * Generate single air packet from data content
 *
 * @param data           content to be transmitted
 * @param next           bit to indicate 0=first or 1=next air packet
 * @param mtuSize        att mtu size used
 * @param sequenceNumber RFC76 ring counter
 * @return air packet
 */
export const buildRfc76MessageFrame = (data, next, mtuSize, sequenceNumber) => {
    let offset = RFC76_HEADER_SIZE
    let packet
    if (data.available() > mtuSize - RFC76_HEADER_SIZE) {
        packet = new Uint8Array(mtuSize)
        packet[0] = packet[0] | next | 0x06 | (sequenceNumber.getSeq() << 4) // 0x06 == MORE
        data.read(packet, offset, mtuSize - offset)
    } else if (data.available() > 0) {
        packet = new Uint8Array(data.available() + RFC76_HEADER_SIZE)
        packet[0] = packet[0] | next | 0x02 | (sequenceNumber.getSeq() << 4) // 0x02 == LAST
        data.read(packet, offset, data.available())
    } else {
        packet = new Uint8Array(RFC76_HEADER_SIZE)
        packet[0] = packet[0] | next | 0x02 | (sequenceNumber.getSeq() << 4)
    }
    sequenceNumber.increment()
    return packet
}

/**
    Generate list of air packets from data stream
    @param data content to be split into air packets
    @param mtuSize att mtu size
    @param sequenceNumber RFC76 ring counter
    @return list of air packets
    */
export const buildRfc76MessageFrameAll = (data, mtuSize, sequenceNumber) => {
    let packets = []
    let next = 0
    do {
        let temp = next // workaround for stupid java translator idiotisim
        let packet = buildRfc76MessageFrame(data, temp, mtuSize, sequenceNumber)
        packets.push(packet)
        next = 1
    } while (data.available() > 0)
    return packets
}

/**
    Function to process RFC76 message header check rfc spec for more details
    @param packet air packet
    @return PftpRfc76ResponseHeader
    */
// export const processRfc76MessageFrameHeader = (packet) => {
//     let header = new PftpRfc76ResponseHeader()
//     processRfc76MessageFrameHeader(header, packet)
//     return header
// }

/**
    @param header RF76 header container
    @param packet air packet
    @return header with payload
    */
export const processRfc76MessageFrameHeader = (header, packet) => {
    header.next = packet[0] & 0x01
    header.status = (packet[0] >> 1) & 0x03
    header.sequenceNumber = (packet[0] >> 4) & 0x0f
    if (header.status == 0) {
        header.error = ((packet[RFC76_HEADER_SIZE] & 0xff) | ((packet[RFC76_HEADER_SIZE + 1] << 8) & 0xff)) & 0x0000ffff
    } else {
        header.payload = new Uint8Array(packet.length - RFC76_HEADER_SIZE)
        header.payload.set(packet.subarray(RFC76_HEADER_SIZE))
    }
    return header
}
