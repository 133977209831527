import routes from '@/router/routes'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { inject } from 'vue'
import axios from '@/lib/API/client'

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async (to, from, next) => {
    const isMobile = inject('isMobile')
    const isBuildForMobile = inject('isBuildForMobile')

    // All pages do have authentication by default.
    // If a page required no authentication, the
    // meta property `guest` can be used to bypass
    // this check.
    if (store.state.auth.initialCheckDone) {
        if (!to.meta?.guest && !store.getters['auth/loggedIn']) {
            // The page requires authentication
            // and the user is not logged in.
            // Must redirect to login page.
            if (to.meta?.redirect === false) {
                //block the redirection
                return next()
            }

            return next({ name: 'auth.login', query: { ...to.query, redirect: to.path } })
        } else if (to.meta?.guest && store.getters['auth/loggedIn']) {
            // This page required no authentication
            // and the user is logged in,
            // Must redirect to the index page.
            if (to.meta?.redirect === false) {
                //block the redirection
                return next()
            }
            return next({ name: 'index' })
        }
    }
    next()
})

export default router
