import longPress from './longPress'
// import { vAnotherDirective } from './anotherDirective';

export default {
    install(app) {
        // Register all directives globally
        app.directive('long-press', longPress)
        // app.directive('another-directive', vAnotherDirective);
    },
}
