<template>
    <Transition name="fade">
        <div
            v-if="showBanner"
            ref="banner"
            :style="`background-color: ${colors.background}`"
            :class="{
                'pt-2': topPadding === 'small',
                'pt-banner': topPadding === 'big',
            }"
        >
            <div class="mx-auto py-3 px-3">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="flex flex-1 items-center">
                        <span
                            class="flex p-2 rounded-lg filter brightness-90 whitespace-normal"
                            :style="`background-color: ${colors.background}`"
                        >
                            <MegaphoneIcon class="h-6 w-6" :style="`color: ${colors.text}`" />
                        </span>

                        <p class="ml-3 font-medium truncate whitespace-normal" :style="`color: ${colors.text}`">
                            <span class="md:hidden"> {{ shortText ? shortText : text }} </span>
                            <span class="hidden md:inline"> {{ text }} </span>
                        </p>
                    </div>

                    <div v-if="buttonLink && buttonText" class="order-3 mt-2 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                            @click="() => openLink(buttonLink)"
                            class="w-full h-full text-center hover:opacity-80 text-xs p-2 bg-button-dark-lightest rounded-lg text-text-base font-bold flex items-center justify-center relative cursor-pointer"
                        >
                            {{ buttonText }}
                        </button>
                    </div>
                    <div class="order-2 sm:order-3 sm:ml-3">
                        <div class="ml-3 p-2">
                            <XLightIcon
                                class="h-3 w-3 cursor-pointer hover:opacity-30"
                                :style="`color: ${colors.text}`"
                                @click="closeBanner"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import tailwind from '@/lib/tailwind'

import { MegaphoneIcon } from '@heroicons/vue/24/outline'
import XLightIcon from '@/components/icons/XLightIcon.vue'

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: null,
    },
    text: {
        type: String,
        required: true,
    },
    shortText: {
        type: String,
        default: null,
    },
    buttonText: {
        type: String,
        default: null,
    },
    buttonLink: {
        type: [String, Function],
        default: null,
    },
    level: {
        type: String,
        default: 'info',
        validator: (value) => tailwind.theme.colors.banner.hasOwnProperty(value),
    },
    topPadding: {
        type: String,
        default: 'small',
    },
})
// Todo (Gaby - 30/12/21):V2: Enregistrer sur le local storage le fait que la banner a déjà été fermée
// onMounted(async () => {
// const isBannerAlreadyClosed = await Memory.get(`banner-${name}`)
const banner = ref(null)
const showBanner = ref(false)
// })
onMounted(() => {
    showBanner.value = true
}) //this way will play the animation

const closeBanner = () => {
    showBanner.value = false
}

const colors = {
    text: tailwind.theme.colors.banner?.[props.level]?.text,
    background: tailwind.theme.colors.banner?.[props.level]?.background,
}

const openLink = (buttonLink) => {
    if (typeof buttonLink === 'function') {
        buttonLink()
    } else {
        window.open(buttonLink, '_blank')
    }
}
</script>
