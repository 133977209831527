<template>
    <div
        class="rounded-lg text-text-base font-bold flex items-center justify-center relative cursor-pointer hover:brightness-110 active:scale-105 transition ease-out duration-200"
        :class="{
            'opacity-50': !enabled || active,
            'bg-button-base': !secondary && !danger && !warning && !opacity,
            'bg-button-dark-lighter': secondary && !danger && !warning && !opacity,
            'bg-button-warning': warning,
            'bg-button-danger': danger,
            'bg-button-dark-lightest': opacity,
            'w-80': large,
            'py-3': !small,
            'py-2': small,
            'px-6': !small,
            'px-4': small,
            'text-lg': !small,
            'text-xs': small,
            'w-fit': fit,
        }"
        @click="() => (enabled ? buttonClicked() : undefined)"
    >
        <transition name="fade">
            <div
                v-if="active"
                class="absolute inset-0 flex items-center justify-center rounded-lg"
                :class="{
                    'opacity-50': active,
                    'bg-button-base': !secondary && !danger && !warning,
                    'bg-button-dark-lighter': secondary && !danger && !warning,
                    'bg-button-warning': danger,
                    'bg-button-danger': warning,
                    'bg-button-dark-lightest': opacity,
                    'w-80': large,
                    'py-3': !small,
                    'py-2': small,
                    'px-6': !small,
                    'px-4': small,
                    'text-lg': !small,
                    'text-xs': small,
                    'w-fit': fit,
                }"
            >
                <Loader :size="50" />
            </div>
        </transition>
        <div class="w-full h-full flex items-center justify-center text-center" :class="{ 'opacity-0': active }">
            <slot />
        </div>
    </div>
</template>

<script setup>
import useButton, { buttonProps } from '@/lib/hooks/button'
import Loader from '@/components/Loader.vue'

const props = defineProps({
    ...buttonProps,
    secondary: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    opacity: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
})
let { active, onClick } = useButton()
const buttonClicked = () => {
    onClick({ action: props.action, to: props.to })
}
</script>
