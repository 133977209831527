export const PAGE_SIZE = 2048
export const SESSION_PAGE_SIZE = 30
// Header1
export const HEADER_SYNC1 = '0xFA' // 250
export const HEADER_SYNC2 = '0x70' // 112
export const HEADER_BYTES = 6
export const TRAILER_BYTES = 2

const sumOfHexaBytes = (arr, count0x00 = false) =>
    arr.reduce((p, c) => {
        if (c === '') {
            return p
        }
        if (count0x00 && (c === '0x00' || c === 'x00')) {
            return p + 1
        }
        return p + parseInt(c, 16)
    }, 0)

export const encodeCommand = ({ command = '', payload = '', name = '' }) => {
    if (name && name !== '') {
        console.log(name)
    }
    const typeIDandID = command.replaceAll(' ', '').split(',')
    const payloadArray = payload.replaceAll(' ', '').split(',')
    // console.log(payloadArray)
    const lengthOfPayload = payloadArray.length
    const lengthByte1 = byteToHex(lengthOfPayload % 256)
    const lengthByte2 = byteToHex(parseInt(lengthOfPayload / 256))

    let hexData = [HEADER_SYNC1, HEADER_SYNC2, ...typeIDandID, lengthByte1, lengthByte2]
    if (lengthOfPayload > 0) {
        hexData = hexData.concat(...payloadArray)
    }
    // Trailer
    const bytesTotal = sumOfHexaBytes([...typeIDandID, lengthByte1, lengthByte2, ...payloadArray])
    // console.log('bytesTotal, lengthOfPayload', bytesTotal, lengthOfPayload)
    const checksumByte1 = byteToHex(bytesTotal % 256)
    const checksumByte2 = byteToHex(parseInt(bytesTotal / 256))
    hexData = hexData.concat([checksumByte1, checksumByte2])

    // console.log('Write hex', hexData)
    const data = new Uint8Array(hexData.map((hex) => parseInt(hex, 16)))
    // console.log('Write', data)
    return data
}

export const decodeRecord = (dataview, offset = 0) => {
    const index = dataview.getUint32(offset + 0, true) // uint32
    const startPage = dataview.getUint32(offset + 4, true) // uint32
    const size = dataview.getUint32(offset + 8, true) // uint32
    const weekNumber = dataview.getUint16(offset + 12, true) // uint16
    const integer_time_of_the_week = dataview.getUint32(offset + 14, true) // uint32
    const latitude = dataview.getFloat32(offset + 18, true) || null // float32
    const longitude = dataview.getFloat32(offset + 22, true) || null // float32
    // const latitude = new Float32Array(new Uint8Array(payload.slice(18, 22)).buffer)[0] // float32
    // const longitude = new Float32Array(new Uint8Array(payload.slice(22, 26)).buffer)[0] // float32
    const firmware = new TextDecoder('utf-8').decode(dataview.buffer.slice(offset + 26, offset + 26 + 4)) // uint8

    return {
        index: index,
        startPage: startPage,
        size: size,
        numberOfPages: size / PAGE_SIZE,
        weekNumber: weekNumber,
        iTOW: integer_time_of_the_week,
        latitude: latitude,
        longitude: longitude,
        firmware: firmware,
    }
}

export const getReadFileCommand = ({ startPage, numberOfPages }) => {
    console.log(`Asked ${numberOfPages} page(s) from ${startPage}`)
    const firstPageBytes = longToByteArray(startPage, 4).map((val) => byteToHex(val))
    const numberOfPagesBytes = longToByteArray(numberOfPages, 4).map((val) => byteToHex(val))
    const payload = [...firstPageBytes, ...numberOfPagesBytes].join(',')
    return { command: '0xC8, 0x98', payload: payload }
}

export const longToByteArray = (/*long*/ long, bytes) => {
    // we want to represent the input as a bytes array
    let byteArray = new Array(bytes).fill(0)

    for (let index = 0; index < byteArray.length; index++) {
        let byte = long & 0xff
        byteArray[index] = byte
        long = (long - byte) / 256
    }

    return byteArray
}

export const byteArrayToLong = (/*byte[]*/ byteArray) => {
    let value = 0
    for (let i = byteArray.length - 1; i >= 0; i--) {
        value = value * 256 + byteArray[i]
    }

    return value
}

export const decodeUUID = (payloadArray) => {
    const UUID = Array.from(payloadArray.slice(0, 12))
        .reverse()
        .map((val) => byteToHex(val, false))
        .join('')
        .toUpperCase()
    const firmware = new TextDecoder('utf-8').decode(payloadArray.slice(12, 16))
    return [UUID, firmware]
}

export const splitMessage = (message) => {
    const headersArray = message.slice(0, HEADER_BYTES)
    const numbersOfBytes = byteArrayToLong([headersArray[4], headersArray[5]])
    const payloadArray = message.slice(HEADER_BYTES, numbersOfBytes + HEADER_BYTES)
    const trailerArray = message.slice(numbersOfBytes + HEADER_BYTES, numbersOfBytes + HEADER_BYTES + TRAILER_BYTES)
    const nextMessage = new Uint8Array(message.slice(numbersOfBytes + HEADER_BYTES + TRAILER_BYTES, message.length))

    const trailerChecksumWanted = byteArrayToLong(trailerArray)
    let trailerChecksum = [...headersArray.slice(2, HEADER_BYTES), ...payloadArray].reduce((a, b) => a + b, 0)
    //Because the checksum is two bytes, it can't be more than 65536
    if (trailerChecksum > 65536) {
        trailerChecksum = Math.trunc(trailerChecksum % 65536)
    }
    //check if the checksum is valid
    // if (trailerChecksumWanted < trailerChecksum && trailerArray.length === TRAILER_BYTES) {
    //     console.warn('Checksum corrupted')
    // }
    const isValid = trailerChecksumWanted === trailerChecksum && message.length > HEADER_BYTES
    return { headersArray, payloadArray, trailerArray, nextMessage, isValid }
}

export const listOfAvailableCommands = [
    { name: 'GET_UNIQUE_ID', command: '0x55, 0x0F', payload: '', waitForReply: true },
    { name: 'BROADCAST', command: '0x55, 0xFF', payload: '' },
    { name: 'START_SESSION', command: '0x55, 0x02', payload: '' },
    { name: 'PAUSE_SESSION', command: '0x55, 0x03', payload: '' },
    { name: 'RESUME_SESSION', command: '0x55, 0x04', payload: '' },
    { name: 'STOP_SESSION', command: '0x55, 0x05', payload: '' },
    { name: 'SET_MODE_MEMORY', command: '0x55, 0xC8', payload: '' },
    { name: 'SET_MODE_CALIBRATION', command: '0x97, 0x4A', payload: '' },
    { name: 'SET_MODE_NAVIGATION', command: '0x55, 0x54', payload: '' },
    { name: 'SET_MODE_OFF', command: '0x97, 0x0B', payload: '' },
    { name: 'GET_SESSION_LIST_BY_MEMORY', command: '0xC8, 0x97', payload: '', waitForReply: true },
    { name: 'GET_SESSION_LIST', command: '0x55, 0x97', payload: '', waitForReply: true },
    { name: 'READ_SESSION', command: '0xC8, 0x98', payload: '', waitForReply: true },
    { name: 'ERASE_MEMORY', command: '0xC8, 0x89', payload: '' },
    { name: 'START_TELEMETRY', command: '0x97, 0x55', payload: '0x01' },
    { name: 'STOP_TELEMETRY', command: '0x97, 0x55', payload: '0x00' },
    { name: 'SAVE_CALIBRATION', command: '0x25, 0x57', payload: '' },
    { name: 'RESET_CALIBRATION', command: '0x25, 0x52', payload: '' },
    { name: 'RESET_SENSOR', command: '0x55, 0x0E', payload: '' },
]

export const byteToHex = (number, with0X = true) => {
    let hex = Number(number).toString(16)
    if (hex.length < 2) {
        hex = '0' + hex
    }
    return with0X ? '0x' + hex : hex
}

//todo exported from bluetooth must be merged
export const arrayBufferToBase64 = (buffer) => {
    let binary = ''
    let bytes = new Uint8Array(buffer)
    let len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return btoa(binary)
}

//todo exported from bluetooth must be merged
export const concatenateUint8Arrays = (...arrays) => {
    return arrays.reduce((previous, current) => {
        let uint8Array_Temporary = new Uint8Array(previous.length + current.length)
        uint8Array_Temporary.set(previous, 0)
        uint8Array_Temporary.set(current, previous.length)
        return new Uint8Array(uint8Array_Temporary.buffer)
    }, [])
}
