import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useSWRV from 'swrv'
import { mutate } from 'swrv'
import fetcher from '@/lib/API/fetcher'
import axios from '@/lib/API/client'
import { formatRange } from '@/lib/helpers/time'
import Time from '@/lib/helpers/time'

export default () => {
    const { locale } = useI18n()

    const emptyStep = {
        description: null,
        day_count: 1,
        walk_duration: 0,
        trot_duration: 0,
        canter_duration: 0,
        order: null,
        gaits: [],
    }
    const route = useRoute()
    // const stepId = computed(() => route.params.step)
    // const stepId = ref()
    const protocolId = computed(() => route.params.protocol)
    const protocolStepsUrl = computed(() => `/protocols/${protocolId.value}/steps`)
    // const protocolStepUrl = computed(() => `/protocols/${protocolId.value}/steps/${stepId.value}`)
    /**
     *
     * internal methods
     *
     **/

    const setStep = (newStep = {}) => {
        return { ...emptyStep, ...newStep }
    }

    const setSteps = (steps = [], protocolStartAt = null) => {
        steps.map((step, indexStep) => {
            let date = {
                start: '',
                end: '',
            }

            let previousSteps = steps.slice(0, indexStep)

            date.start = new Date(protocolStartAt)

            if (indexStep !== 0) {
                const dayCountStepsBefore = previousSteps?.reduce(function (previousStep, currenStep) {
                    return previousStep + currenStep.day_count
                }, 0)

                date.start = new Date(date.start)
                date.start.setDate(date.start.getDate() + dayCountStepsBefore)
            }

            date.end = new Date(date.start)
            date.end.setDate(date.end.getDate() + step.day_count - 1)

            const range = formatRange(locale, date.start, date.end)

            step.range = range
            step.startAt = date.start
            step.endAt = date.end
            step.isCurrent = new Time().time.isBetween(step.startAt, step.endAt, 'day', '[]')

            // Todo
            // step.status = indexStep === 0 ? 'done' : indexStep === 1 ? '30' : null
            step.status = null
        })

        return steps
    }

    /**
     *
     * fetch* methods
     *
     **/
    const fetchSteps = (url) => {
        return fetcher(url, (data) => data.map((p) => setStep(p)))
    }
    // const fetchStep = (url) => {
    //     return fetcher(url, setStep)
    // }

    /**
     *
     * swrv* methods
     *
     **/
    const {
        data: steps,
        error: stepsError,
        isValidating,
    } = useSWRV(() => protocolId.value && protocolStepsUrl.value, fetchSteps, {
        revalidateOnFocus: false,
    })

    // const { data: step, error: stepError } = useSWRV(
    //     () => protocolId.value && stepId.value && protocolStepUrl.value,
    //     fetchStep,
    //     {
    //         revalidateOnFocus: false,
    //     }
    // )

    /**
     *
     * mutate* methods
     *
     **/

    const mutateSteps = (data) => {
        mutate(protocolStepsUrl.value, data ? data : fetchSteps(protocolStepsUrl.value))
    }

    // const mutateStep = (stepId, data) => {
    //     mutate(protocolStepUrl.value, data ? data : fetchStep(protocolStepUrl.value))
    // }

    /**
     *
     * API methods
     *
     **/

    const updateStep = (stepId, data) => {
        return axios.put(`/protocols/${protocolId.value}/steps/${stepId}`, data).then((response) => {
            // mutateStep(stepId, response.data)
            mutateSteps()

            return response.data
        })
    }

    const removeStep = (stepId) => {
        return axios.delete(`/protocols/${protocolId.value}/steps/${stepId}`).then((response) => {
            // mutateStep(stepId, {})
            mutateSteps()

            return response.data
        })
    }

    const createStep = (data) => {
        return axios.post(`/protocols/${protocolId.value}/steps`, data).then((response) => {
            // mutateStep(response.data.id, response.data)
            mutateSteps()

            return response.data
        })
    }

    return {
        // Data
        emptyStep,
        steps,
        stepsError,
        isValidating,
        // step,
        // stepError,
        // Methods
        mutateSteps,
        setStep,
        setSteps,
        createStep,
        updateStep,
        removeStep,
    }
}
