<template>
    <Modal :title="$t('session.validation.explanation.title')" :is-open="isOpen" @update:is-open="setOpen($event)">
        <div v-if="isError" class="p-3 rounded-lg flex bg-brand-red"> {{ $t('error.failed-to-load') }} </div>

        <Loading v-else-if="(session === undefined || metas === undefined) && !isError" />

        <div v-else class="flex flex-col space-y-4">
            <Information>
                {{ $t('session.validation.explanation.text') }}
            </Information>

            <div class="rounded-md shadow-sm">
                <Select v-model.number="session.horse_id" name="Horse" :label="$t('horse.title')">
                    <option v-for="horse in horsesOptions" :key="horse.id" :value="horse.id">
                        {{ horse.name }}
                    </option>
                </Select>
            </div>

            <div class="rounded-md shadow-sm">
                <Select v-model.number="session.rider_id" name="Rider" :label="$t('user.rider')">
                    <option v-for="rider in ridersOptions" :key="rider.id" :value="rider.id">
                        {{ rider.first_name }} {{ rider.last_name }}
                    </option>
                </Select>
            </div>

            <div class="rounded-md shadow-sm">
                <Select v-model.number="session.discipline" name="Discipline" :label="$t('session.discipline.title')">
                    <option v-for="(item, index) in metas['session-discipline']" :key="index" :value="index">
                        {{ $t(`session.discipline.${item?.toLowerCase()?.replaceAll(' ', '-')}`) }}
                    </option>
                </Select>
            </div>

            <div class="rounded-md shadow-sm">
                <Button :action="updateAndClose"> {{ $t('list.next') }} </Button>
            </div>

            <div
                class="absolute -top-4 right-0 w-4 h-4 z-50"
                @click="
                    () => {
                        $router.push({
                            name: 'sessions.session',
                            params: { sessionId: session.id },
                            query: { ...$route.query },
                        })
                        setOpen(false)
                    }
                "
            ></div>
        </div>
    </Modal>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount } from 'vue'
import useHorses from '@/lib/hooks/use-horses'
import useSessions from '@/lib/hooks/use-sessions'
import useRiders from '@/lib/hooks/use-riders'
import useMetas from '@/lib/hooks/use-metas'

import Loading from '@/components/Loading.vue'
import Select from '@/components/form/Select.vue'
import Button from '@/components/buttons/Primary.vue'
import { Toast } from '@/components/ion/Toast.vue'
import Modal from '@/components/ion/Modal.vue'
import Information from '@/components/Information.vue'

const props = defineProps({
    isOpen: { type: Boolean, default: false },
    session: { type: Object },
})

const emit = defineEmits(['update:isOpen', 'update:session'])

const setOpen = (state) => {
    emit('update:isOpen', state)
}

const { horses } = useHorses()
const { riders } = useRiders()
const { updateSession, isError } = useSessions()
const { metas } = useMetas()

const horsesOptions = computed(() => {
    if (props.session?.horse && horses?.value && !horses.value.find((horse) => horse.id === props.session.horse.id)) {
        return [...horses.value, props.session.horse]
    }
    return horses?.value
})

const ridersOptions = computed(() => {
    if (
        props.session?.rider &&
        riders?.value &&
        !riders.value.find((rider) => rider.id === props.session.rider.rider_id)
    ) {
        return [...riders.value, props.session.rider]
    }
    return riders?.value
})

const updateAndClose = async () => {
    const sessionToUpdate = { ...props.session, validated: 1 } //will close the modal}
    return updateSession(props.session.id, sessionToUpdate)
        .then((newSession) => {
            emit('update:session', newSession)
            setOpen(false)
        })
        .catch((error) => {
            Toast.warning(error?.message)
        })
}
</script>
