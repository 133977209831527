import { ref, reactive, computed } from 'vue'
import { BleClient } from '@capacitor-community/bluetooth-le'
export const PFC_SERVICE = '6217ff4b-fb31-1140-ad5a-a45545d7ecf3'
export const CHARACTERISTIC_CONFIGURATION = '6217ff4d-91bb-91d0-7e2a-7cd3bda8a1f3' //PFC_CP_CHARACTERISTIC
export const CHARACTERISTIC_FEATURE = '6217ff4c-c8ec-b1fb-1380-3ad986708e2d' // a priori on lance en fesant un "read" et il envoi toutes les informations des features
//TBD4 PFC / Polar Features Configuration     // /* Polar Features Configuration Service (PFCS)*/
export default class PolarFeaturesConfiguration {
    // private properties
    promises = {}

    features = reactive({
        'BROADCAST': { request: 2, configure: 1, value: null },
        '5KHZ': { request: 4, configure: 3, value: null },
        'WHISPER_MODE': { request: 6, configure: 5, value: null },
        'BLE_MODE': { request: null, configure: 7, value: null },
        'MULTI_CONNECTION': { request: 9, configure: 8, value: null },
        'ANT_PLUS': { request: 11, configure: 10, value: null },
    })
    device = null
    // static UNKNOWN = 0

    constructor(device) {
        this.device = device
    }

    async start() {
        //multi mode https://github.com/polarofficial/polar-ble-sdk/blob/master/sources/Android/android-communications/library/src/main/java/com/polar/androidcommunications/api/ble/model/gatt/client/BlePfcClient.java

        await BleClient.startNotifications(this.device.deviceId, PFC_SERVICE, CHARACTERISTIC_CONFIGURATION, (value) => {
            console.log('PFC characteristicvaluechanged value', value)
            const status = value.getUint8(2)
            const feature = value.getUint8(1)
            if (status === 1) {
                this.promises.configuration.resolve(value.byteLength === 4 && value.getUint8(3))
            } else {
                this.promises.configuration.reject(new Error('Fail!'))
            }
        })

        // await this.read('BROADCAST')
        // await this.read('5KHZ')
        // await this.read('WHISPER_MODE')
        await this.read('MULTI_CONNECTION')
        // await this.read('ANT_PLUS')
        // console.log('OK READ')
    }

    async stop() {}

    async read(key) {
        const val = await this.getConfiguration(this.features[key].request)
        this.features[key].value = val
        return this.features[key].value
    }

    async toggle(key) {
        if (!this.features?.[key]) {
            throw new Error(`key ${key} does not exist`)
        }
        if (this.features[key].value === 0) {
            await this.setConfiguration(this.features[key].configure, 1)
        } else {
            await this.setConfiguration(this.features[key].configure, 0)
        }
        return await this.read(key)
    }

    async getConfiguration(id) {
        await BleClient.writeWithoutResponse(
            this.device.deviceId,
            PFC_SERVICE,
            CHARACTERISTIC_CONFIGURATION,
            Uint8Array.of(id)
        )
        const promise = new Promise((resolve, reject) => {
            this.promises.configuration = { resolve, reject }
        })
        return promise
    }

    async setConfiguration(id, value) {
        await BleClient.writeWithoutResponse(
            this.device.deviceId,
            PFC_SERVICE,
            CHARACTERISTIC_CONFIGURATION,
            Uint8Array.of(id, value)
        )
        const promise = new Promise((resolve, reject) => {
            this.promises.configuration = { resolve, reject }
        })
        return promise
    }
}
