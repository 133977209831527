// if decimals === false, return a number and not a string
export const asDecimal = (number, decimals = 2, removeZeros = false) => {
    if (typeof number !== 'number') number = parseFloat(number)

    let result = Math.round(number * 10 ** decimals) / 10 ** decimals // If 2 decimals, *100 and /100. If 3: 1000

    // * if -0.0, return 0
    if (isNullOrZero(result)) {
        result = 0
    } else if (decimals) {
        result = parseFloat(result.toFixed(decimals))

        // If removeZeros specified or if only 0 in decimals
        if (removeZeros || result.toString().endsWith('.' + '0'.repeat(decimals))) {
            result = parseFloat(result)
        }
    }

    return result
}

/**
 * Check if the value is equal to 0
 * works for 0 ; 0.0 ; 0° ; 00 ; 0G
 */
export const isNullOrZero = (value) => {
    if (typeof value === 'number') {
        return value === 0 || isNaN(value)
    } else {
        return value === null ? true : parseFloat(value.trim()) === 0
    }
}

/**
 * Returns a random number between a min value (included) and a max value (excluded)
 */
export const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min
}

export const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length
