import * as Sentry from '@sentry/vue'

const dsn = 'https://d5087e7d23fb4178bce6d062d36dc5aa@o923658.ingest.sentry.io/4504366037204992'
const sendSentryLogs = import.meta.env.PROD
const viteEnv = import.meta.env.VITE_APP_ENVIRONMENT

const initSentry = (app, router, isBuildForMobile) => {
    let integrations = []

    if (sendSentryLogs) {
        integrations = [
            Sentry.browserTracingIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ['warn', 'error'],
            }),
        ]
    }

    Sentry.init({
        app,
        dsn,
        integrations,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        beforeSend(event, hint) {
            if (
                /Loading chunk [\d]+ failed/.test(event.message) ||
                /Loading chunk [\d]+ failed/.test(hint.originalException)
            ) {
                window.location.reload()
                return null
            }
            if (
                /Failed to fetch dynamically imported module/.test(event.message) ||
                /Failed to fetch dynamically imported module/.test(hint.originalException)
            ) {
                window.location.reload()
                return null
            }

            if (!sendSentryLogs) {
                console.error(
                    ['local', 'development'].includes(viteEnv) ? (hint.originalException ?? event.message) : event
                )
                return null
            }
            return event
        },
        tracesSampleRate: 0.3,
        environment: viteEnv,
        trackComponents: true,
        autoSessionTracking: true,
        initialScope: {
            tags: { isBuildForMobile: isBuildForMobile, platform: Capacitor.getPlatform() },
        },
        release: APP_NAME + '@' + APP_VERSION,
    })
}

export { initSentry }
