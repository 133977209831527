<template>
    <div
        :class="{ 'bg-dark-lighter': hasFocus, 'p-3': !small, 'p-2': small, 'hidden': inputType === 'hidden' }"
        class="scrollableContent rounded-lg bg-dark-light flex items-center w-full"
        ref="element"
    >
        <div class="flex flex-col flex-grow">
            <div class="uppercase text-xxs tracking-widest font-bold" @mousedown.prevent="$refs.input.focus()">
                <span>{{ label }}</span>
                <span v-if="formatDisplayedValue.unit"> ({{ formatDisplayedValue.unit }})</span>
                <RequiredText v-if="required"> *</RequiredText>
            </div>

            <!-- @blur="editing = true" @keyup.enter="editing = true" -->
            <component
                :is="inputType === 'textarea' ? 'textarea' : 'input'"
                ref="input"
                v-bind="$attrs"
                class="text-white bg-transparent border-none focus:ring-0 focus:outline-none outline-none pl-0"
                :class="{
                    'has-value': modelValue !== emptyValue,
                    'w-12': small,
                    'h-6': inputType !== 'textarea',
                }"
                :type="inputType"
                :value="formatDisplayedValue.value"
                :required="required"
                @input="formatRealValue($event.target.value)"
                @change="formatRealValue($event.target.value)"
                @focus="setFocus(true)"
                @blur="
                    () => {
                        setFocus(false)
                        leaveInput()
                    }
                "
                @keyup.enter="
                    () => {
                        if (inputType !== 'textarea') leaveInput()
                    }
                "
                :rows="rows"
            />
        </div>

        <a
            @mousedown.prevent="showPassword()"
            class="right-10 text-text-gray cursor-pointer"
            v-if="type === 'password' && passwordHidden"
        >
            <EyeSlashIcon class="h-6 w-6" />
        </a>

        <a
            @mousedown.prevent="hidePassword()"
            class="right-10 text-text-gray cursor-pointer"
            v-if="type === 'password' && !passwordHidden"
        >
            <EyeIcon class="h-6 w-6" />
        </a>

        <XCircleIcon
            v-if="xcirclebutton"
            v-show="modelValue !== emptyValue"
            class="ml-3 h-4 w-4 text-gray-500 cursor-pointer"
            @mousedown.prevent="$emit('update:modelValue', emptyValue)"
        />
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { convertValueUnit, getUserUnit, toDatetimeLocal, fromDatetimeLocal } from '@/lib/helpers/convert'

import { XCircleIcon } from '@heroicons/vue/24/solid'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import RequiredText from '@/components/form/RequiredText.vue'

export default {
    components: { XCircleIcon, EyeIcon, EyeSlashIcon, RequiredText },
    inheritAttrs: false,
    props: {
        modelValue: { type: [String, Number, Date], default: '' },
        decimals: { type: Number, default: undefined },
        unit: { type: String, default: null },
        userUnit: { type: String, default: null },
        label: { type: String, default: '' },
        type: { type: String, required: true },
        small: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        keepfocus: { type: Boolean, default: undefined },
        autofocus: { type: Boolean, default: false },
        xcirclebutton: { type: Boolean, default: true },
        emptyValue: { type: [String, Number, Date], default: '' },
        rows: { type: Number, default: 3 },
    },
    emits: ['update:modelValue', 'input:leave'],
    setup(props, { emit }) {
        const hasFocus = ref(props.keepfocus === undefined ? false : props.keepfocus)
        const input = ref(undefined)
        const element = ref(undefined)

        const setFocus = (newState) => {
            if (props.keepfocus === undefined) {
                hasFocus.value = newState
            }
        }

        onMounted(() => {
            if (props.autofocus) input.value.focus()
        })

        const passwordHidden = ref(true)
        const inputType = ref(props.type)

        const userUnit = computed(() => (props.unit && props.userUnit ? getUserUnit(props.unit, props.userUnit) : null))

        const formatDisplayedValue = computed(() => {
            if (inputType.value === 'datetime-local') {
                return { value: toDatetimeLocal(props.modelValue) } //formatted to be same as convertValueUnit
            }
            return convertValueUnit(props.modelValue, props.unit, userUnit.value, props.decimals, true)
        })

        const formatRealValue = async (value) => {
            if (inputType.value === 'datetime-local') {
                return emit('update:modelValue', fromDatetimeLocal(value))
            }
            return emit('update:modelValue', convertValueUnit(value, userUnit.value, props.unit, null, true).value)
        }

        const showPassword = () => {
            // console.log(props.type)
            passwordHidden.value = false
            inputType.value = 'text'
        }

        const hidePassword = () => {
            passwordHidden.value = true
            inputType.value = 'password'
        }

        const leaveInput = () => {
            emit('input:leave')
        }

        return {
            hasFocus,
            setFocus,
            input,
            element,
            formatDisplayedValue,
            formatRealValue,
            showPassword,
            hidePassword,
            passwordHidden,
            inputType,
            leaveInput,
        }
    },
}
</script>
