<template>
    <div
        class="px-2"
        :class="{
            'bg-brand-base': primary,
            'bg-dark-lighter': !primary,
            'pt-4': $store.getters['device/topPadding'] === 'small' && addPadding,
            'pt-header': $store.getters['device/topPadding'] === 'big' && addPadding,
        }"
    >
        <div class="grid grid-cols-4 h-16">
            <div v-if="backEnable" class="flex justify-start text-sm text-text-darker col-start-1 col-end-2">
                <div class="flex items-center cursor-pointer" @click="handleBackAction">
                    <LeftIcon v-if="withIcons" class="w-5 h-5" />
                    <div>{{ backText === 'Back' ? $t('nav.back') : backText }}</div>
                </div>
            </div>
            <div
                class="flex items-center justify-center"
                :class="{
                    'col-start-2 col-end-4': actionEnable || backEnable,
                    'col-start-1 col-end-5': !actionEnable && !backEnable,
                }"
            >
                <slot />
            </div>
            <div v-if="actionEnable || loading" class="flex justify-end text-sm text-text-darker col-start-4 col-end-5">
                <div
                    v-if="actionEnable && (action !== undefined || actionAction !== undefined)"
                    class="flex items-center cursor-pointer"
                    @click="handleActionAction"
                >
                    <div>{{ actionText === 'Action' ? $t('form.action') : actionText }}</div>
                    <RightIcon v-if="withIcons" class="w-5 h-5" />
                </div>
                <Loading v-else-if="loading" class="w-10 h-10 -mt-2" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import LeftIcon from '@/components/icons/LeftIcon.vue'
import RightIcon from '@/components/icons/RightIcon.vue'
import Loading from '@/components/Loading.vue'

const props = defineProps({
    back: { type: Boolean, default: false },
    backAction: { type: Function, default: undefined },
    backText: { type: String, default: 'Back' },
    action: { type: Object, default: undefined },
    actionAction: { type: Function, default: undefined },
    actionText: { type: String, default: 'Action' },
    primary: { type: Boolean, default: false },
    withIcons: { type: Boolean, default: true },
    addPadding: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
})

const router = useRouter()

const handleBackAction = async () => {
    if (props.backAction) {
        await props.backAction()
    } else {
        return router.go(-1)
    }
}
const handleActionAction = async () => {
    if (props.actionAction) await props.actionAction()
    if (props.action) await router.push(props.action)
}

const backEnable = computed(() => props.back || props.backAction !== undefined)
const actionEnable = computed(() => props.action !== undefined || props.actionAction !== undefined)
</script>
